import { ComponentType } from "preact";
import { connect } from "react-redux";

import AssetSettings, { namingOptionVisible } from "./AssetSettings";

import { mapStateToProps, mapDispatchToProps } from "./AssetSettingsMapper";
import { OwnProps } from "./AssetSettingsProps";

export default connect(mapStateToProps, mapDispatchToProps)(AssetSettings) as ComponentType<OwnProps>;
export {
    namingOptionVisible
};
