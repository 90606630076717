/* eslint-disable class-methods-use-this */
import Dispatcher from "flux/lib/Dispatcher";
import ReduceStore from "flux/lib/FluxReduceStore";
import { generateNKeysBetween } from "fractional-indexing";

import { Tag, TagGroup } from "../../../foundation/api/model";
import BarrelDataStage from "../../../foundation/api/model/barrels/BarrelDataStage";
import ScreenSection from "../../../foundation/api/model/barrels/ScreenSection";
import ErrorResponse from "../../../foundation/api/model/common/ErrorResponse";
import ApiAsset from "../../../foundation/api/model/snapshots/Asset";
import ApiRemPreferences from "../../../foundation/api/model/spacings/RemPreferences";

import { TagGroupOrderBy, TagGroupTypes } from "../../../foundation/enums";
import Barrel from "../../../foundation/model/Barrel";
import BarrelType from "../../../foundation/model/BarrelType";
import FoundationRemPreferences from "../../../foundation/model/RemPreferences";

import { CompleteScreenVersion } from "../../../foundation/model/ScreenVersion";
import { arrayRemove } from "../../../foundation/utils/array";
import resourceTypeToBarrelKey from "../../../foundation/utils/barrel/resourceTypeToBarrelKey";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { getSortedTagsByName, getSortedTagsByOrder } from "../../../foundation/utils/tag";

import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import AssetsActionTypes from "../assets/AssetsActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import BarrelsReducer from "../barrelsReducer/BarrelsReducer";
import ComponentActionTypes from "../component/ComponentActionTypes";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import * as MoveResourcesActionPayloads from "../moveResources/MoveResourcesActionPayloads";
import MoveResourcesActionTypes from "../moveResources/MoveResourcesActionTypes";
import OrganizationActionTypes from "../organization/OrganizationActionTypes";
import * as ProjectActionPayloads from "../project/ProjectActionPayloads";
import ProjectActionTypes from "../project/ProjectActionTypes";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import StyleguideActionTypes from "../styleguide/StyleguideActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./ProjectsActionPayloads";
import ProjectsActionTypes from "./ProjectsActionTypes";

type State = {
    createProjectInProgress: boolean;
    joinProjectInProgress: boolean;
    loadingProjectId: string | null;
    loadingProjects: boolean;
    projects: BasicRecord<Barrel>;
    dotsRetrieved: BasicRecord<boolean>;
    error?: ErrorResponse;
    editingDescription?: boolean;
};

function toggleTagGroupShowOnThumbnails(tagGroups: TagGroup[], targetTagGroupId: string, newVal: boolean) : TagGroup[] {
    // new tag group is selected to show on thumbnails, set old tag group's show on attribute to false,
    return tagGroups.map((tagGroup: TagGroup) => {
        const newTagGroup = { ...tagGroup };
        if (newTagGroup.type === TagGroupTypes.status) {
            newTagGroup.showOnThumbnails = newTagGroup._id === targetTagGroupId && newVal;
        }

        return newTagGroup;
    });
}

class ProjectsDataStore extends ReduceStore<State, AllPayloads> {
    reducer: BarrelsReducer<State, "projects", "loadingProjectId", "loadingProjects">;

    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);

        this.reducer = new BarrelsReducer({
            barrelsPropertyName: "projects",
            loadingBarrelIdPropertyName: "loadingProjectId",
            loadingBarrelsPropertyName: "loadingProjects"
        }, {
            deleteBarrel: this.remove
        });
    }

    /**
     * @state {Object} Storing `projects` in normalized format to make CRUD operations easy;
     * projects: {
     *   "pid1": {
     *     "_id": "pid1"
     *     ..
     *   },
     *   "pid2": {
     *     "_id": "pid2"
     *     ..
     *   },
     * }
     */
    getInitialState(): State {
        return {
            createProjectInProgress: false,
            joinProjectInProgress: false,
            loadingProjectId: null,
            loadingProjects: true,
            projects: {},
            dotsRetrieved: {}
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    // #region Common Barrel Reducers
    add(state: State, {
        barrel,
        barrelType
    }: BarrelActionPayloads.Add): State {
        if (barrelType !== BarrelType.PROJECT) {
            return state;
        }

        return this.reducer.addBarrel(state, { barrel });
    }

    activate(state: State, {
        bid
    }: BarrelActionPayloads.Activate): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "status",
            value: "active"
        });
    }

    archive(state: State, {
        bid
    }: BarrelActionPayloads.Archive): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "status",
            value: "archived"
        });
    }

    remove(
        state: State,
        { bid, barrelType }: Pick<BarrelActionPayloads.Remove, "bid" | "barrelType">,
        preserveLinkRelation = false
    ): State {
        if (barrelType === BarrelType.STYLEGUIDE) {
            // Linked projects data should not be lost, when a restricted user is deleted from a project.
            if (preserveLinkRelation) {
                return state;
            }

            let unlinkedProjectsState = state;
            // If deleted styleguide is a linked styleguide of any projects, it should be unlinked from those projects
            const projectsToUnlink = Object.values(state.projects)
                .filter(project => project.styleguideLink?.styleguide?._id === bid);

            projectsToUnlink.forEach(({ _id }) => {
                unlinkedProjectsState = this.unlinkProjectFromStyleguide(unlinkedProjectsState, { pid: _id });
            });

            return unlinkedProjectsState;
        }

        const { [bid]: _deletedProject, ...remainingProjects } = state.projects;

        return {
            ...state,
            projects: {
                ...remainingProjects
            }
        };
    }

    linkProjectToStyleguide(state: State, {
        project: {
            _id: pid,
            remPreferences
        },
        styleguide
    }: ProjectActionPayloads.LinkProjectToStyleguide | ProjectActionPayloads.LinkProjectToStyleguideSuccess): State {
        return this.reducer.updateBarrel(state, {
            bid: pid,
            data: {
                styleguideLink: {
                    styleguide,
                    status: "active"
                },
                remPreferences: new FoundationRemPreferences({
                    ...remPreferences,
                    status: "linked"
                } as ApiRemPreferences)
            }
        });
    }

    unlinkProjectFromStyleguide(
        state: State,
        { pid }: Omit<ProjectActionPayloads.UnlinkProjectFromStyleguide, "type" | "styleguide">
    ): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "styleguideLink",
            value: null
        });
    }

    updateUserData(state: State, {
        bid,
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        const targetProject = state.projects[bid];

        if (!targetProject) {
            return state;
        }

        const users = targetProject.users.map(({ role, user }) => {
            if (user._id !== uid) {
                return { role, user };
            }

            return { role, user: { ...user, ...userData } };
        });

        let { screens } = targetProject;

        if (screens) {
            screens = targetProject.screens!.map(screen => {
                const versions = screen.versions!.map(({ creator, commit, ...version }) => {
                    const updatedCreator = creator?._id === uid
                        ? { ...creator, ...userData }
                        : creator;

                    const updatedCommit = commit?.author._id === uid
                        ? { ...commit, author: { ...commit.author, ...userData } }
                        : commit;

                    return {
                        ...version,
                        creator: updatedCreator,
                        commit: updatedCommit
                    } as CompleteScreenVersion;
                });

                return { ...screen, versions };
            });
        }

        // TODO: update component authors too
        return this.reducer.updateBarrel(state, {
            bid,
            data: new Barrel({
                ...targetProject,
                screens,
                users
            })
        });
    }

    transferOwnership(state: State, {
        bid,
        fromUserId,
        targetId,
        toOrganization,
        users,
        oid
    }: Payloads.TransferOwnership | (BarrelActionPayloads.OwnershipTransferred & { users?: undefined; }))
        : State {
        const targetProject = state.projects[bid];

        if (!targetProject) {
            return state;
        }

        // Transfer project from/to organization
        if (toOrganization || oid !== "user") {
            const projectMarkedBareState = targetProject.dataStage === BarrelDataStage.Complete
                ? this.markProjectAsBare(state, { pid: bid })
                : state;
            const deletedWorkflowStatusState = this.reducer.deleteWorkflowStatus(projectMarkedBareState, { bid });

            if (users) {
                return this.reducer.updateBarrelValue(deletedWorkflowStatusState, {
                    bid,
                    key: "users",
                    value: (project: Barrel) => project.users.map(userObj => {
                        if (userObj.user._id === fromUserId || userObj.user._id === targetId) {
                            const foundUser = users.find(userData => userObj.user._id === userData.user._id)!;
                            userObj.role = foundUser.role;
                            userObj.user.paymentPlan = foundUser.user.paymentPlan;
                        }

                        return userObj;
                    })
                });
            }

            return deletedWorkflowStatusState;
        }

        // Swap roles if transferred to user
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "users",
            value: (project: Barrel) => project.users.map(userObj => {
                if (users) {
                    if (userObj.user._id === fromUserId || userObj.user._id === targetId) {
                        const foundUser = users.find(userData => userObj.user._id === userData.user._id)!;
                        userObj.role = foundUser.role;
                        userObj.user.paymentPlan = foundUser.user.paymentPlan;
                    }

                    return userObj;
                }

                if (userObj.user._id === targetId) {
                    userObj.role = "owner";
                }

                if (userObj.user._id === fromUserId) {
                    userObj.role = "admin";
                }

                return userObj;
            })
        });
    }

    load(state: State, {
        data: {
            project,
            organizationProjects
        }
    }: BarrelActionPayloads.Load): State {
        let newState = state;

        if (organizationProjects) {
            newState = this.reducer.getBarrelsSuccess(newState, {
                projects: organizationProjects
            });
        }

        if (project) {
            newState = this.reducer.getBarrelSuccess(newState, {
                barrel: project
            });
        }

        return newState;
    }

    loadIntermediate(state: State, {
        barrelData: {
            data: {
                project
            }
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        let newState = state;

        if (project) {
            newState = this.reducer.getBarrelSuccess(newState, {
                barrel: project
            });
        }

        return newState;
    }

    preloadProjectDashboard(state: State, {
        data: {
            project
        }
    }: BarrelActionPayloads.PreloadDashboard): State {
        return this.reducer.getBarrelSuccess(state, {
            barrel: project!
        });
    }

    moveResourcesSuccess(state: State, {
        resourceType,
        resources,
        bid,
        barrelType
    }: BarrelActionPayloads.MoveResourcesSuccess): State {
        if (barrelType !== BarrelType.PROJECT) {
            return state;
        }

        const keyInBarrel = resourceTypeToBarrelKey(resourceType);
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: keyInBarrel,
            value(project: Barrel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return (project[keyInBarrel] as any[]).filter(resource => !resources.includes(resource));
            }
        });
    }

    moveDashboardResourcesSuccess(state: State, {
        barrelId,
        targetBarrelId
    }: MoveResourcesActionPayloads.MoveSuccess) {
        const updatedState = this.markProjectAsBare(state, { pid: barrelId });
        return this.markProjectAsBare(updatedState, { pid: targetBarrelId });
    }

    changeAssetName(state: State, action: BarrelActionPayloads.ChangeAssetName): State {
        const { component } = action;
        if (component) {
            return this.reducer.changeComponentAssetName(state, action);
        }

        // If component is not provided, screen is provided with action
        const selectedScreenId = action.screenId!;

        const { bid, versionId, assetId, name } = action;

        // Find asset and change localName
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id !== selectedScreenId) {
                        return screen;
                    }

                    return {
                        ...screen,
                        versions: screen.versions?.map(version => {
                            if (version._id !== versionId || !version.snapshot.assets) {
                                return version;
                            }

                            const {
                                snapshot: {
                                    assets
                                },
                                snapshot
                            } = version;

                            return {
                                ...version,
                                snapshot: {
                                    ...snapshot,
                                    assets: assets.map(asset => {
                                        if (asset._id !== assetId) {
                                            return asset;
                                        }

                                        return {
                                            ...asset,
                                            localName: name
                                        };
                                    })
                                }
                            };
                        })
                    };
                });
            }
        });
    }

    joinProjectRequest(state: State, { barrelType }: BarrelActionPayloads.Join): State {
        if (barrelType !== BarrelType.PROJECT) {
            return state;
        }

        return {
            ...state,
            joinProjectInProgress: true
        };
    }

    joinProjectFinalize(state: State, { barrelType }: BarrelActionPayloads.StopLoadingJoin): State {
        if (barrelType !== BarrelType.PROJECT) {
            return state;
        }

        return {
            ...state,
            joinProjectInProgress: false
        };
    }

    // #endregion

    // MARK: Dashboard reducers
    addSection(state: State, {
        pid,
        section,
        index
    }: DashboardActionPayloads.AddSection): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections || index === 0) {
                    return sections;
                }

                sections.forEach(sourceSection => {
                    sourceSection.screens = sourceSection.screens.filter(sid => !section.screens.includes(sid));
                });

                sections.splice(index, 0, section);
                return sections;
            }
        });
    }

    createSection(state: State, {
        pid,
        section
    }: DashboardActionPayloads.CreateSection): State {
        const { _id: seid, name } = section;

        return this.renameSection(state, { pid, seid, name });
    }

    updateSection(state: State, {
        pid,
        seid,
        sectionData
    }: DashboardActionPayloads.UpdateSection): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                return sections.map(section => {
                    if (section._id !== seid) {
                        return section;
                    }

                    return { ...section, ...sectionData };
                });
            }
        });
    }

    renameSection(state: State, {
        pid,
        seid,
        name
    }: Pick<DashboardActionPayloads.RenameSection, "pid" | "seid" | "name">): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                return sections.map(section => {
                    if (section._id !== seid) {
                        return section;
                    }

                    return { ...section, name };
                });
            }
        });
    }

    updateSectionDescription(state: State, {
        pid,
        seid,
        description
    }: DashboardActionPayloads.UpdateSectionDescription): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                return sections.map(section => {
                    if (section._id !== seid) {
                        return section;
                    }

                    return { ...section, description };
                });
            }
        });
    }

    updateSectionOrder(state: State, {
        pid,
        seid,
        index,
        parent
    }: DashboardActionPayloads.UpdateSectionOrder): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                const oldIndex = sections.findIndex(({ _id }) => _id === seid);
                const subSections = sections.filter(({ path }) => path?.includes(seid));
                const sectionsToMove = sections.splice(oldIndex, subSections.length + 1);

                if (parent) {
                    const parentSection = sections.find(({ _id }) => _id === parent)!;

                    sectionsToMove[0].parent = parent;
                    sectionsToMove[0].path = [...(parentSection.path || []), parent];
                } else {
                    delete sectionsToMove[0].parent;
                    delete sectionsToMove[0].path;
                }

                for (let i = 1; i < sectionsToMove.length; i++) {
                    const parentId = sectionsToMove[i].parent!;
                    const parentSection = sectionsToMove.find(({ _id }) => _id === parentId)!;

                    sectionsToMove[i].path = [...(parentSection.path || []), parentId];
                }

                sections.splice(index - (index > oldIndex ? subSections.length : 0), 0, ...sectionsToMove);

                // Just to keep the ordering consistent, might not be same with the backend
                const newKeys = generateNKeysBetween(null, null, sections.length);
                sections.forEach((section, i) => {
                    section.order = newKeys[i];
                });

                return sections;
            }
        });
    }

    removeSection(state: State, {
        pid,
        seid,
        deleteContents
    }: DashboardActionPayloads.RemoveSection): State {
        const targetProject = state.projects[pid];
        const projectSections = targetProject.sections;

        if (!targetProject || !projectSections) {
            return state;
        }

        const index = projectSections.findIndex(se => se._id === seid);

        if (index < 1) {
            return state;
        }

        const sourceSection = projectSections[index];
        const subSections = projectSections.filter(({ path }) => path?.includes(seid));
        const sectionsToRemove = [sourceSection, ...subSections];
        const sectionIdsToRemove = sectionsToRemove.map(({ _id }) => _id);
        const screensToMoveOrDelete = sectionsToRemove.flatMap(({ screens }) => screens);

        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                if (!deleteContents) {
                    const targetSection = sections[index - 1];

                    targetSection.screens.push(...screensToMoveOrDelete);
                }

                return sections.filter(s => !sectionIdsToRemove.includes(s._id));
            }
        });

        if (deleteContents) {
            return this.reducer.updateBarrelValue(updatedState, {
                bid: pid,
                key: "screens",
                value({ screens }: Barrel) {
                    if (!screens) {
                        return screens;
                    }

                    return screens.filter(screen => !screensToMoveOrDelete.includes(screen._id));
                }
            });
        }

        return updatedState;
    }

    updateScreenOrder(state: State, {
        pid,
        screens,
        targetSectionId,
        targetIndex
    }: Pick<DashboardActionPayloads.UpdateScreenOrder, "pid" | "screens" | "targetSectionId" | "targetIndex">): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                if (!sections) {
                    return sections;
                }

                screens.forEach(sid => {
                    const sourceSection = sections.find(section => section.screens.includes(sid));

                    if (!sourceSection) {
                        return;
                    }

                    arrayRemove(sourceSection.screens, id => id === sid);
                });

                const targetSection = sections.find(se => se._id === targetSectionId);

                if (!targetSection) {
                    return;
                }

                targetSection.screens.splice(targetIndex, 0, ...screens);

                return sections;
            }
        });
    }

    updateScreenOrderV2(state: State, {
        pid,
        items,
        targetSectionId,
        targetIndex
    }: Pick<DashboardActionPayloads.UpdateScreenOrderV2, "pid" | "items" | "targetSectionId" | "targetIndex">): State {
        const targetProject = state.projects[pid];

        if (!targetProject) {
            return state;
        }

        const { variantGroups } = targetProject;

        const screenIds: string[] = [];

        items.forEach(({ _id, type }) => {
            if (type === "screen") {
                screenIds.push(_id);
            } else if (type === "variantGroup") {
                const variantGroup = variantGroups!.find(({ _id: variantGroupId }) => variantGroupId === _id);
                screenIds.push(...variantGroup!.screens.map(({ id }) => id));
            }
        });

        return this.updateScreenOrder(state, { pid, screens: screenIds, targetSectionId, targetIndex });
    }

    createVariantGroup(state: State, {
        pid,
        vgid,
        screens,
        variantGroupName,
        shouldOrder
    }: Pick<
        DashboardActionPayloads.CreateVariantGroup,
        "pid" | "vgid" | "screens" | "variantGroupName" | "shouldOrder"
    >): State {
        const targetProject = state.projects[pid];

        if (!targetProject) {
            return state;
        }

        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                const newVariantGroup = {
                    _id: vgid,
                    name: variantGroupName,
                    screens
                };

                if (!variantGroups) {
                    return [newVariantGroup];
                }

                return [...variantGroups, newVariantGroup];
            }
        });

        if (shouldOrder) {
            return this.orderVariantGroupScreens(updatedState, {
                pid,
                vgid
            });
        }

        return updatedState;
    }

    addScreensToVariantGroup(state: State, {
        pid,
        vgid,
        screens,
        shouldOrder
    }: Pick<DashboardActionPayloads.AddScreensToVariantGroup, "pid" | "vgid" | "screens" | "shouldOrder">): State {
        const targetProject = state.projects[pid];

        if (!targetProject) {
            return state;
        }

        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                let newVariantGroups = variantGroups.map(variantGroup => {
                    const { _id, screens: variantScreens } = variantGroup;
                    if (_id !== vgid) {
                        return variantGroup;
                    }

                    return {
                        ...variantGroup,
                        screens: variantScreens.concat(screens)
                    };
                });

                newVariantGroups = newVariantGroups.map(variantGroup => {
                    const { _id, screens: variantScreens } = variantGroup;
                    if (_id !== vgid) {
                        return variantGroup;
                    }

                    const [{ requestId }] = screens;

                    const variantScreensCreatedInSameBatch = variantScreens.filter(variantScreen => (
                        requestId && variantScreen.requestId === requestId
                    ));
                    const others = variantScreens.filter(
                        variantScreen =>
                            !variantScreensCreatedInSameBatch.includes(variantScreen)
                    );

                    variantScreensCreatedInSameBatch.sort((a, b) => a.index! - b.index!);
                    variantGroup.screens = others.concat(variantScreensCreatedInSameBatch);

                    return variantGroup;
                });

                return newVariantGroups;
            }
        });

        if (shouldOrder) {
            return this.orderVariantGroupScreens(updatedState, {
                pid,
                vgid
            });
        }

        return updatedState;
    }

    orderVariantGroupScreens(state: State, { pid, vgid }: {
        pid: string;
        vgid: string;
    }): State {
        const targetProject = state.projects[pid];
        if (!targetProject || !targetProject.sections || !targetProject.variantGroups) {
            return state;
        }

        const variantGroup = targetProject.variantGroups.find(({ _id }) => _id === vgid);
        if (!variantGroup) {
            return state;
        }

        const variantGroupScreenIds = variantGroup.screens.map(({ id }) => id);
        const [defaultVariantScreenId] = variantGroupScreenIds;

        const variantGroupSection = targetProject.sections.find(
            section => section.screens.includes(defaultVariantScreenId)
        );
        if (!variantGroupSection) {
            return state;
        }

        let defaultScreenIndex = null;
        let numberOfScreensComesBeforeDefaultScreen = 0;

        for (let i = 0; i < variantGroupSection.screens.length; i++) {
            const sectionScreenId = variantGroupSection.screens[i];

            if (defaultVariantScreenId === sectionScreenId) {
                defaultScreenIndex = i;
                break;
            }

            if (variantGroupScreenIds.includes(sectionScreenId)) {
                numberOfScreensComesBeforeDefaultScreen += 1;
            }
        }

        if (defaultScreenIndex === null) {
            return state;
        }

        return this.updateScreenOrderV2(state, {
            pid,
            items: [{ _id: vgid, type: "variantGroup" }],
            targetSectionId: variantGroupSection._id,
            targetIndex: defaultScreenIndex - numberOfScreensComesBeforeDefaultScreen
        });
    }

    updateVariantGroup(state: State, {
        pid,
        vgid,
        variantGroupData
    }: DashboardActionPayloads.UpdateVariantGroup): State {
        const targetProject = state.projects[pid];

        if (!targetProject) {
            return state;
        }

        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                return variantGroups.map(variantGroup => {
                    const { _id } = variantGroup;
                    if (_id !== vgid) {
                        return variantGroup;
                    }

                    return {
                        ...variantGroup,
                        ...variantGroupData
                    };
                });
            }
        });

        return this.orderVariantGroupScreens(updatedState, {
            pid,
            vgid: variantGroupData._id
        });
    }

    renameVariantGroup(state: State, {
        pid,
        vgid,
        name
    }: DashboardActionPayloads.RenameVariantGroup): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                return variantGroups.map(variantGroup => {
                    if (variantGroup._id !== vgid) {
                        return variantGroup;
                    }

                    return {
                        ...variantGroup,
                        name
                    };
                });
            }
        });
    }

    deleteVariantGroup(state: State, {
        pid,
        vgid,
        screens,
        syncWithApi
    }: Pick<DashboardActionPayloads.DeleteVariantGroup, "pid" | "vgid" | "screens" | "syncWithApi">): State {
        const targetProject = state.projects[pid];

        if (!targetProject || !targetProject.variantGroups) {
            return state;
        }

        const targetVariantGroup = targetProject.variantGroups.find(({ _id }) => _id === vgid);
        if (!targetVariantGroup) {
            return state;
        }

        let variantScreenIds;
        if (screens) {
            variantScreenIds = screens.map(({ id }) => id);
        } else if (syncWithApi) {
            variantScreenIds = targetVariantGroup.screens.map(({ id }) => id);
        }

        const updatedState = this.detachVariantGroup(state, { pid, vgid });

        if (!variantScreenIds) {
            return updatedState;
        }

        return this.removeScreens(updatedState, { pid, screenIds: variantScreenIds });
    }

    detachVariantGroup(state: State, {
        pid,
        vgid
    }: Pick<DashboardActionPayloads.DetachVariantGroup, "pid" | "vgid">): State {
        const targetProject = state.projects[pid];

        if (!targetProject) {
            return state;
        }

        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                return variantGroups.filter(variantGroup => variantGroup._id !== vgid);
            }
        });
    }

    // #region Projects Specific Reducers
    addScreen(state: State, {
        pid,
        seid,
        index,
        screen
    }: ScreenActionPayloads.AddScreen): State {
        const targetProject = state.projects[pid];

        // Incomplete projects don't have any screens
        if (!targetProject || !targetProject.screens) {
            return state;
        }

        const foundScreen = targetProject.screens.find(s => s._id === screen._id);

        if (foundScreen) {
            return state;
        }

        let newState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value: ({ screens }: Barrel) => [...screens!, screen]
        });

        if (screen.variantGroup) {
            const foundVariantGroup = targetProject.variantGroups!.find(({ _id }) => _id === screen.variantGroup!._id);
            if (foundVariantGroup) {
                newState = this.addScreensToVariantGroup(newState, {
                    pid,
                    vgid: foundVariantGroup._id,
                    screens: screen.variantGroup.screens,
                    shouldOrder: false
                });
            } else {
                newState = this.createVariantGroup(newState, {
                    pid,
                    vgid: screen.variantGroup._id,
                    screens: screen.variantGroup.screens,
                    variantGroupName: screen.variantGroup.name,
                    shouldOrder: false
                });
            }
        }

        return this.reducer.updateBarrelValue(newState, {
            bid: pid,
            key: "sections",
            value({ sections, screens }: Barrel) {
                // Partial projects don't have any sections
                if (!sections) {
                    return sections;
                }

                const targetSection = ((seid && sections.find(se => se._id === seid)) ?? sections[0]) as ScreenSection;

                const targetIndex = typeof index === "undefined"
                    ? targetSection.screens.length
                    : index;

                targetSection.screens.splice(targetIndex, 0, screen._id);

                if (screen.requestId) {
                    const screensCreatedInSameBatch = screens!.filter(
                        projectScreen =>
                            projectScreen.requestId === screen.requestId &&
                            targetSection.screens.includes(projectScreen._id)
                    );
                    screensCreatedInSameBatch.forEach(createdScreen => {
                        const indexOfProjectScreen = targetSection.screens.indexOf(createdScreen._id);
                        targetSection.screens.splice(indexOfProjectScreen, 1);
                    });

                    screensCreatedInSameBatch.sort((a, b) => a.index! - b.index!);

                    targetSection.screens = targetSection.screens.concat(
                        screensCreatedInSameBatch.map(createdScreen => createdScreen._id)
                    );
                }

                return [...sections];
            }
        });
    }

    addScreenTiles(state: State, {
        pid,
        sid,
        vid,
        tileInfo
    }: ScreenActionPayloads.AddScreenTiles): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id === sid) {
                        const newVersions = screen.versions!.map(version => {
                            if (version._id === vid) {
                                return {
                                    ...version,
                                    snapshot: { ...version.snapshot, tileInfo }
                                } as CompleteScreenVersion;
                            }

                            return version;
                        });

                        return { ...screen, versions: newVersions };
                    }

                    return screen;
                });
            }
        });
    }

    handleDeleteScreens(state: State, {
        pid,
        items,
        syncWithApi
    }: DashboardActionPayloads.DeleteScreens) {
        const targetProject = state.projects[pid];
        const deletedScreenIds = items.filter(item => item.type === "screen").map(item => item._id);
        const deletedVariantGroupIds = items.filter(item => item.type === "variantGroup").map(item => item._id);

        let newState = state;
        deletedScreenIds.forEach(sid => {
            const vgid = targetProject?.variantGroups?.find(
                ({ screens }) => screens.some(({ id }) => id === sid)
                )?._id || null;
            newState = this.removeScreen(newState, { pid, sid, vgid });
        });
        deletedVariantGroupIds.forEach(vgid => {
            newState = this.deleteVariantGroup(newState, { pid, vgid, screens: null, syncWithApi });
        });

        return newState;
    }

    removeScreen(state: State, {
        pid,
        sid,
        vgid
    }: Pick<ScreenActionPayloads.RemoveScreen, "pid" | "sid" | "vgid">): State {
        let newState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.filter(screen => screen._id !== sid);
            }
        });

        newState = this.reducer.updateBarrelValue(newState, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                // Partial projects don't have any sections
                if (!sections) {
                    return sections;
                }

                return sections.map(section => {
                    if (section.screens.includes(sid)) {
                        section.screens = section.screens.filter(screenId => screenId !== sid);
                    }

                    return section;
                });
            }
        });

        if (vgid) {
            newState = this.removeScreenVariant(newState, { pid, vgid, svid: sid });
        }

        return newState;
    }

    removeScreens(state: State, { pid, screenIds }: {
        pid: string;
        screenIds: string[];
    }): State {
        const newState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.filter(screen => !screenIds.includes(screen._id));
            }
        });

        return this.reducer.updateBarrelValue(newState, {
            bid: pid,
            key: "sections",
            value({ sections }: Barrel) {
                // Partial projects don't have any sections
                if (!sections) {
                    return sections;
                }

                return sections.map(section => {
                    screenIds.forEach(sid => {
                        if (section.screens.includes(sid)) {
                            section.screens = section.screens.filter(screenId => screenId !== sid);
                        }
                    });

                    return section;
                });
            }
        });
    }

    updateScreenName(state: State, {
        pid,
        sid,
        name
    }: ScreenActionPayloads.UpdateName): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id === sid) {
                        return { ...screen, name };
                    }

                    return screen;
                });
            }
        });
    }

    updateThumbnailStatus(state: State, {
        pid,
        sid,
        vid,
        thumbnails
    }: ScreenActionPayloads.UpdateThumbnailStatus): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                const screen = screens.find(s => s._id === sid);

                if (!screen) {
                    return screens;
                }

                const version = screen.versions?.find(v => v._id === vid);

                if (version) {
                    version.snapshot = {
                        ...version.snapshot,
                        thumbnails: {
                            ...version.snapshot.thumbnails,
                            ...thumbnails
                        }
                    };
                }

                return screens;
            }
        });
    }
    // #endregion

    setVersions(state: State, {
        pid,
        sid,
        versions,
        totalVersionCount,
        allowedVersionCount
    }: ScreenActionPayloads.SetVersions): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id === sid) {
                        screen.versions = versions;
                        screen.totalVersionCount = totalVersionCount;
                        screen.allowedVersionCount = allowedVersionCount;
                    }

                    return screen;
                });
            }
        });
    }

    setSnapshot(state: State, {
        pid,
        sid,
        vid,
        snapshot
    }: Omit<ScreenActionPayloads.SetSnapshot, "type">): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                const screen = screens.find(s => s._id === sid);

                if (!screen) {
                    return screens;
                }

                const version = screen.versions!.find(v => v._id === vid);

                if (version) {
                    Object.assign(version.snapshot, snapshot);
                }

                return screens;
            }
        });
    }

    addVersion(state: State, {
        pid,
        sid,
        version,
        updatedScreenName
    }: ScreenActionPayloads.AddVersion): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                const screen = screens.find(s => s._id === sid);

                if (!screen) {
                    return screens;
                }

                const existingVersion = screen.versions!.some(v => v._id === version._id);

                if (existingVersion) {
                    return screens;
                }

                screen.updated = new Date().toISOString();
                screen.versions!.unshift(version);

                if (screen.totalVersionCount) {
                    screen.totalVersionCount++;
                }

                if (screen.allowedVersionCount) { // for user to be able to see the version he was able to see before
                    screen.allowedVersionCount++;
                }

                if (screen.versions!.length > screen.allowedVersionCount!) {
                    screen.versions!.pop();
                }

                if (updatedScreenName) {
                    screen.name = updatedScreenName;
                }

                return screens;
            }
        });
    }

    updateCommitMessage(state: State, {
        pid,
        sid,
        vid,
        message
    }: ScreenActionPayloads.UpdateCommitMessage): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id !== sid) {
                        return screen;
                    }

                    screen.versions = screen.versions!.map(version => {
                        if (version._id !== vid || !version.commit) {
                            return version;
                        }

                        return {
                            ...version,
                            commit: {
                                ...version.commit,
                                message
                            }
                        };
                    });

                    return screen;
                });
            }
        });
    }

    removeVersion(state: State, {
        pid,
        sid,
        vid
    }: ScreenActionPayloads.RemoveVersion): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id !== sid) {
                        return screen;
                    }

                    screen.versions = screen.versions!.filter(({ _id }) => _id !== vid);
                    if (screen.totalVersionCount) {
                        screen.totalVersionCount--;
                    }

                    return screen;
                });
            }
        });
    }

    removeScreenVariant(state: State, {
        pid,
        vgid,
        svid
    }: Pick<ScreenActionPayloads.RemoveScreenVariant, "pid" | "vgid" | "svid">): State {
        const targetProject = state.projects[pid];

        if (!targetProject || !targetProject.sections) {
            return state;
        }

        let defaultScreenVariantId: string | undefined;
        let numberOfScreenVariantsAfterRemoval: number | undefined;

        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                const foundVariantGroup = variantGroups.find(({ _id }) => vgid === _id);
                if (!foundVariantGroup) {
                    return variantGroups;
                }

                if (foundVariantGroup.screens.length === 2) {
                    return variantGroups.filter(variantGroup => variantGroup._id !== vgid);
                }

                defaultScreenVariantId = foundVariantGroup.screens[0].id;
                numberOfScreenVariantsAfterRemoval = foundVariantGroup.screens.length - 1;

                foundVariantGroup.screens = foundVariantGroup.screens.filter(({ id }) => svid !== id);

                return variantGroups;
            }
        });

        if (!defaultScreenVariantId) {
            return updatedState;
        }

        const defaultScreenSection = targetProject.sections.find(
            section => section.screens.includes(defaultScreenVariantId!)
        );

        if (!defaultScreenSection) {
            return updatedState;
        }

        const defaultScreenIndex = defaultScreenSection.screens.findIndex(
            screenId => screenId === defaultScreenVariantId
        );

        return this.updateScreenOrder(updatedState, {
            pid,
            screens: [svid],
            targetSectionId: defaultScreenSection._id,
            targetIndex: defaultScreenIndex + numberOfScreenVariantsAfterRemoval!
        });
    }

    addScreenVariant(state: State, {
        pid,
        vgid,
        screenVariant,
        index
    }: ScreenActionPayloads.AddScreenVariant): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                const foundVariantGroup = variantGroups.find(({ _id }) => vgid === _id);
                if (!foundVariantGroup) {
                    return variantGroups;
                }

                foundVariantGroup.screens.splice(index, 0, screenVariant);

                return variantGroups;
            }
        });
    }

    renameScreenVariant(state: State, {
        pid,
        vgid,
        svid,
        name
    }: ScreenActionPayloads.RenameScreenVariant): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                const foundVariantGroup = variantGroups.find(({ _id }) => _id === vgid);
                if (!foundVariantGroup) {
                    return variantGroups;
                }

                foundVariantGroup.screens = foundVariantGroup.screens.map(screen => {
                    if (screen.id === svid) {
                        screen.variantName = name;
                    }

                    return screen;
                });

                return variantGroups;
            }
        });
    }

    reorderScreenVariant(state: State, {
        pid,
        vgid,
        svid,
        index
    }: ScreenActionPayloads.ReorderScreenVariant): State {
        const updatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "variantGroups",
            value({ variantGroups }: Barrel) {
                if (!variantGroups) {
                    return variantGroups;
                }

                const foundVariantGroup = variantGroups.find(({ _id }) => _id === vgid);
                if (!foundVariantGroup) {
                    return variantGroups;
                }

                const screenVariant = arrayRemove(foundVariantGroup.screens, s => s.id === svid);
                if (!screenVariant) {
                    return variantGroups;
                }

                foundVariantGroup.screens.splice(index, 0, screenVariant);
                foundVariantGroup.screens = [...foundVariantGroup.screens];

                return [...variantGroups];
            }
        });

        return this.orderVariantGroupScreens(updatedState, {
            pid,
            vgid
        });
    }

    addComponentLinkedAnnotation(state: State, {
        pid: bid,
        annotation
    }: ScreenActionPayloads.AddAnnotation): State {
        const { componentSourceId } = annotation;
        if (!componentSourceId) {
            return state;
        }

        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }) {
                if (!componentAnnotations || !componentAnnotations.length) {
                    return [annotation];
                }

                return [...componentAnnotations, annotation];
            }
        });
    }

    updateComponentLinkedAnnotation(state: State, {
        pid: bid,
        atid,
        annotationData
    }: ScreenActionPayloads.UpdateAnnotation): State {
        const { componentSourceId } = annotationData;
        if (!componentSourceId) {
            return state;
        }

        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }) {
                if (!componentAnnotations) {
                    return null;
                }

                return componentAnnotations.map(annotation => {
                    if (annotation._id !== atid) {
                        return annotation;
                    }

                    return { ...annotation, ...annotationData };
                });
            }
        });
    }

    updateComponentLinkedAnnotationType(state: State, {
        pid: bid,
        atid,
        newType,
        componentSourceId
    }: ScreenActionPayloads.UpdateAnnotationType): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations || !componentSourceId) {
                    return componentAnnotations;
                }

                return componentAnnotations.map(annotation => {
                    if (annotation._id !== atid) {
                        return annotation;
                    }

                    return { ...annotation, type: newType };
                });
            }
        });
    }

    deleteComponentLinkedAnnotationType(state: State, {
        pid: bid,
        atid,
        componentSourceId
    }: ScreenActionPayloads.DeleteAnnotationType): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations || !componentSourceId) {
                    return componentAnnotations;
                }

                return componentAnnotations.map(annotation => {
                    if (annotation._id !== atid) {
                        return annotation;
                    }

                    return { ...annotation, type: undefined };
                });
            }
        });
    }

    updateComponentLinkedAnnotationText(state: State, {
        pid: bid,
        atid,
        componentSourceId,
        annotationData
    }: ScreenActionPayloads.UpdateAnnotationText): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations || !componentSourceId) {
                    return componentAnnotations;
                }

                return componentAnnotations.map(annotation => {
                    if (annotation._id !== atid) {
                        return annotation;
                    }

                    return { ...annotation, ...annotationData };
                });
            }
        });
    }

    updateComponentLinkedAnnotationPosition(state: State, {
        pid: bid,
        atid,
        x, y,
        originalX, originalY
    }: ScreenActionPayloads.UpdateAnnotationPosition): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations) {
                    return componentAnnotations;
                }

                return componentAnnotations.map(annotation => {
                    if (annotation._id !== atid) {
                        return annotation;
                    }

                    if ("originalCoordinates" in annotation && annotation.originalCoordinates) {
                        const { originalCoordinates: _, ...rest } = annotation;

                        return typeof originalX === "number" ? {
                            ...annotation,
                            originalCoordinates: { ...annotation.originalCoordinates, x: originalX, y: originalY! },
                            x,
                            y
                        } : {
                            ...rest,
                            x,
                            y
                        };
                    }

                    return { ...annotation, x, y };
                });
            }
        });
    }

    removeComponentLinkedAnnotation(state: State, {
        pid: bid,
        atid,
        componentSourceId
    }: ScreenActionPayloads.RemoveAnnotation): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations || !componentSourceId) {
                    return componentAnnotations;
                }

                return componentAnnotations.filter(({ _id }) => _id !== atid);
            }
        });
    }

    unlinkComponentLinkedAnnotation(state: State, {
        pid: bid,
        atid
    }: ScreenActionPayloads.UnlinkAnnotation): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "componentAnnotations",
            value({ componentAnnotations }: Barrel) {
                if (!componentAnnotations) {
                    return componentAnnotations;
                }

                return componentAnnotations.filter(({ _id }) => _id !== atid);
            }
        });
    }

    setVersionDiffs(state: State, {
        pid, sid, vid, prevVid, versionDiffs
    }: ScreenActionPayloads.SetVersionDiffs) {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id === sid) {
                        const currentVersionDiffs = screen.versionsDiffs ?? {};
                        currentVersionDiffs[`${vid}-${prevVid}`] = versionDiffs;
                        screen.versionsDiffs = currentVersionDiffs;
                    }

                    return screen;
                });
            }
        });
    }

    hideDiffPopup(state: State, {
        pid, sid, vid, prevVid
    }: ScreenActionPayloads.HideDiffPopup) {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "screens",
            value({ screens }: Barrel) {
                if (!screens) {
                    return screens;
                }

                return screens.map(screen => {
                    if (screen._id === sid) {
                        const hiddenDiffPopups = screen.hiddenDiffPopups ?? {};
                        hiddenDiffPopups[`${vid}-${prevVid}`] = true;
                        screen.hiddenDiffPopups = hiddenDiffPopups;
                    }

                    return screen;
                });
            }
        });
    }

    hydrateEmbeddedData(state: State, {
        project,
        projects
    }: AppActionPayloads.HydrateEmbeddedData): State {
        let newState = state;

        if (projects) {
            newState = this.reducer.getBarrelsSuccess(newState, {
                projects
            });
        }

        if (project) {
            newState = this.reducer.getBarrelSuccess(newState, {
                barrel: project
            });
        }

        return newState;
    }

    deleteWorkflowStatuses(state: State, {
        statusDeletedBarrels
    }: BarrelActionPayloads.DeleteWorkflowStatuses): State {
        const currentState = { ...state };
        return statusDeletedBarrels.reduce((updatedState, { bid }) => (
            this.reducer.deleteWorkflowStatus(updatedState, { bid })
        ), currentState);
    }

    updateWorkflowStatuses(state: State, {
        statusUpdatedBarrels,
        workflowStatus
    }: BarrelActionPayloads.UpdateWorkflowStatuses): State {
        const currentState = { ...state };
        return statusUpdatedBarrels.reduce((updatedState, { bid }) => (
            this.reducer.updateWorkflowStatus(updatedState, { bid, workflowStatus })
        ), currentState);
    }

    createProjectRequest(state: State): State {
        return {
            ...state,
            createProjectInProgress: true
        };
    }

    createProjectFinalize(state: State): State {
        return {
            ...state,
            createProjectInProgress: false
        };
    }

    updateProjectStyleguideLink(state: State, { pid, data }: {
        pid: string;
        data: Partial<Barrel>;
    }): State {
        const { styleguideLink: styleguideLinkData } = data;
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "styleguideLink",
            value: ({ styleguideLink }: Barrel) => {
                if (styleguideLink) {
                    return { ...styleguideLink, ...styleguideLinkData };
                }

                return styleguideLinkData;
            }
        });
    }

    markProjectAsBare(state: State, {
        pid
    }: {
        pid: string;
    }): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "dataStage",
            value: BarrelDataStage.Bare
        });
    }

    createTag(state: State, {
        pid: bid,
        tag,
        tagGroup: { _id: tgid },
        sids
    }: ProjectActionPayloads.CreateTag): State {
        const screensUpdatedState = this.addTagToScreens(state, { pid: bid, tid: tag._id!, sids });

        const tagGroupsUpdatedState = this.reducer.updateBarrelValue(screensUpdatedState, {
            bid,
            key: "tagGroups",
            value: ({ tags, tagGroups }: Barrel) => tagGroups!.map(group => {
                if (tgid === group._id) {
                    if (group.tagsOrderBy === TagGroupOrderBy.NAME) {
                        const newTags = getSortedTagsByOrder([...tags!, (tag as Tag)], group.tags!.concat(tag._id!));
                        return { ...group, tags: newTags };
                    }

                    return { ...group, tags: [...(group.tags ?? []), tag._id!] };
                }

                return group;
            })
        });

        return this.reducer.updateBarrelValue(tagGroupsUpdatedState, {
            bid,
            key: "tags",
            value: ({ tags }: Barrel) => [...tags!, tag as Tag]
        });
    }

    updateTag(state: State, {
        pid: bid,
        tid,
        tag
    }: ProjectActionPayloads.UpdateTag): State {
        const screensUpdatedState = tag._id === tid ? state : this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens }: Barrel) => screens?.map(screen => (
                screen.tags?.includes(tid)
                    ? { ...screen, tags: [...screen.tags.filter(tagId => tagId !== tid), tag._id] }
                    : screen
            ))
        });

        const tagsUpdatedState = this.reducer.updateBarrelValue(screensUpdatedState, {
            bid,
            key: "tags",
            value: ({ tags }: Barrel) => tags!.map(barrelTag => (barrelTag._id === tid ? tag : barrelTag)) ?? []
        });

        return this.reducer.updateBarrelValue(
            tagsUpdatedState, {
                bid,
                key: "tagGroups",
                value: ({ tagGroups, tags: barrelTags }: Barrel) => tagGroups!.map(group => {
                    if (group.tags?.includes(tid)) {
                        let { tags } = group;
                        if (tid !== tag._id) {
                            tags = tags.filter(tagId => tagId !== tid);
                            const index = group.tags.indexOf(tid);
                            tags.splice(index, 0, tag._id);
                        }

                        if (group.tagsOrderBy === TagGroupOrderBy.NAME) {
                            const newTags = getSortedTagsByOrder(barrelTags!, tags);
                            return { ...group, tags: newTags };
                        }

                        return { ...group, tags };
                    }

                    return group;
                })
            }
        );
    }

    updateTagOrder(state: State, {
        pid,
        tag: { _id: tid },
        tagGroup: { _id: tgid },
        index,
        order,
        oldTagGroup: { _id: oldTgid }
    }: ProjectActionPayloads.UpdateTagOrder): State {
        const tagGroupsUpdatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "tagGroups",
            value: ({ tagGroups, tags: barrelTags }: Barrel) => tagGroups!.map(tagGroup => {
                let newTagGroup = tagGroup;

                if (tagGroup._id === oldTgid) {
                    newTagGroup = { ...newTagGroup, tags: newTagGroup.tags!.filter(t => t !== tid) };
                }

                if (tagGroup._id === tgid) {
                    const tags = [...(newTagGroup.tags ?? [])];
                    tags.splice(index, 0, tid);
                    const tagsMapped: BasicRecord<Tag> = (barrelTags || []).reduce(
                        (acc, tag) => ({ ...acc, [tag._id]: tag }),
                        {}
                    );
                    let newTagsOrderBy = TagGroupOrderBy.NONE;
                    const prevTag = tagsMapped[tags[index - 1]];
                    const movedTag = tagsMapped[tid];
                    const nextTag = tagsMapped[tags[index + 1]];
                    if (
                        (!prevTag || prevTag.name.toLowerCase() <= movedTag.name.toLowerCase()) &&
                        (!nextTag || movedTag.name.toLowerCase() <= nextTag.name.toLowerCase()) &&
                        newTagGroup.tagsOrderBy === TagGroupOrderBy.NAME
                    ) {
                        newTagsOrderBy = TagGroupOrderBy.NAME;
                    }

                    newTagGroup = { ...newTagGroup, tags, tagsOrderBy: newTagsOrderBy };
                }

                return newTagGroup;
            })
        });

        return this.reducer.updateBarrelValue(tagGroupsUpdatedState, {
            bid: pid,
            key: "tags",
            value: ({ tags }: Barrel) => tags!.map(tag => (tag._id === tid ? { ...tag, order } : tag))
        });
    }

    removeTag(state: State, {
        pid: bid,
        tid,
        tgid
    }: ProjectActionPayloads.RemoveTag): State {
        const screensUpdatedState = this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens }: Barrel) => screens?.map(screen => (
                screen.tags?.includes(tid)
                    ? { ...screen, tags: screen.tags!.filter(tag => tag !== tid) }
                    : screen
            ))
        });

        const tagGroupsUpdatedState = this.reducer.updateBarrelValue(screensUpdatedState, {
            bid,
            key: "tagGroups",
            value: ({ tagGroups }: Barrel) => tagGroups!.map(group => (
                tgid === group._id ? { ...group, tags: group.tags!.filter(tagId => tid !== tagId) } : group
            ))
        });

        return this.reducer.updateBarrelValue(tagGroupsUpdatedState, {
            bid,
            key: "tags",
            value: ({ tags }: Barrel) => tags!.filter(tag => tag._id !== tid)
        });
    }

    addTagToScreens(state: State, {
        pid: bid,
        tid,
        sids
    }: Pick<ProjectActionPayloads.AddTagToScreens, "pid" | "tid" | "sids">): State {
        return !sids ? state : this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens, tagGroups, tags }: Barrel) => screens?.map(screen => {
                if (!sids.includes(screen._id)) {
                    return screen;
                }

                const barrelTags = tags || [];
                const barrelTagGroups = tagGroups || [];
                const newTag = barrelTags.find(tag => tag._id === tid);
                const newTagGroup = barrelTagGroups.find(group => group.tags?.includes(newTag?._id ?? ""));
                if (newTagGroup?.type !== TagGroupTypes.status) {
                    return { ...screen, tags: [...screen.tags!, tid] };
                }

                const newScreenTagIds: Array<string> = [];
                screen.tags?.forEach(tagId => {
                    const tagGroup = barrelTagGroups.find(group => group.tags?.includes(tagId));
                    if (tagGroup?._id !== newTagGroup._id) {
                        newScreenTagIds.push(tagId);
                    }
                });

                return { ...screen, tags: [...newScreenTagIds, tid] };
            })
        });
    }

    recoverOldStatusTags(state: State, {
        pid: bid,
        oldStatusTagsOfScreens
    }: ProjectActionPayloads.RecoverOldStatusTags): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens }: Barrel) => screens?.map(screen => {
                const tagToRecover = oldStatusTagsOfScreens[screen._id];
                if (!tagToRecover) {
                    return screen;
                }

                const currentTags = screen.tags!;
                return { ...screen, tags: [...currentTags, tagToRecover] };
            })
        });
    }

    removeTagFromScreens(state: State, {
        pid: bid,
        tid,
        sids
    }: Pick<ProjectActionPayloads.RemoveTagFromScreens, "pid" | "tid" | "sids">): State {
        return !sids ? state : this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens }: Barrel) => screens?.map(screen => (
                sids.includes(screen._id)
                    ? { ...screen, tags: screen.tags!.filter(t => t !== tid) }
                    : screen
            ))
        });
    }

    recoverDeletedTagGroupTags(state: State, {
        pid: bid,
        screenTagsOfDeletedTagGroup
    }: ProjectActionPayloads.RecoverDeletedTagGroupTags): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "screens",
            value: ({ screens }: Barrel) => screens?.map(screen => {
                const tagsToRecover = screenTagsOfDeletedTagGroup[screen._id];
                if (!tagsToRecover) {
                    return screen;
                }

                const currentTags = screen.tags!;
                return { ...screen, tags: [...currentTags, ...tagsToRecover] };
            })
        });
    }

    createTagGroup(state: State, {
        pid: bid,
        tagGroup
    }: ProjectActionPayloads.CreateTagGroup): State {
        const newTagGroupsCallback = ({ tagGroups }: Barrel) => {
            const newTagGroups = [...tagGroups!, tagGroup];
            const { _id: tgid, showOnThumbnails } = tagGroup;

            if (showOnThumbnails) {
                return toggleTagGroupShowOnThumbnails(newTagGroups, tgid, showOnThumbnails);
            }

            return newTagGroups;
        };

        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "tagGroups",
            value: newTagGroupsCallback
        });
    }

    createTagGroupWithTags(state: State, {
        pid: bid,
        tagGroup
    }: ProjectActionPayloads.CreateTagGroupWithTags): State {
        const newTagGroupsCallback = ({ tagGroups }: Barrel) => {
            const newTagGroups = [...tagGroups!, {
                ...tagGroup,
                tags: tagGroup.tags?.map(tagElement => tagElement._id) || []
            }];
            const { _id: tgid, showOnThumbnails } = tagGroup;

            if (showOnThumbnails) {
                return toggleTagGroupShowOnThumbnails(newTagGroups, tgid, showOnThumbnails);
            }

            return newTagGroups;
        };

        let updatedState = this.reducer.updateBarrelValue(state, {
            bid,
            key: "tagGroups",
            value: newTagGroupsCallback
        });

        updatedState = tagGroup.tags.reduce((updatedTagGroupState, tagInfo) => {
            const { screens, ...tag } = tagInfo;

            const screensUpdatedState = this.addTagToScreens(
                updatedTagGroupState,
                { pid: bid, tid: tagInfo._id!, sids: screens }
            );

            return (
                this.reducer.updateBarrelValue(screensUpdatedState, {
                    bid,
                    key: "tags",
                    value: ({ tags }: Barrel) => [...tags!, tag as Tag]
                })
            );
        }, updatedState);

        return updatedState;
    }

    updateTagGroup(state: State, {
        pid: bid,
        tgid,
        tagGroup,
        oldTagGroup,
        oldTagGroupShownOnThumbnails,
        revertOperation
    }: ProjectActionPayloads.UpdateTagGroup): State {
        const newGroupsCallback = ({ tagGroups }: Barrel) => {
            const newTagGroups = tagGroups!.map(group => (
                group._id === tgid ? tagGroup : group
            ));
            if (oldTagGroupShownOnThumbnails && revertOperation) {
                const { _id: oldTgid } = oldTagGroupShownOnThumbnails;
                return toggleTagGroupShowOnThumbnails(newTagGroups, oldTgid, true);
            } else if (tagGroup.showOnThumbnails !== oldTagGroup.showOnThumbnails) {
                const { _id: newTgid, showOnThumbnails } = tagGroup;
                return toggleTagGroupShowOnThumbnails(newTagGroups, newTgid, !!showOnThumbnails);
            }

            return newTagGroups;
        };

        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "tagGroups",
            value: newGroupsCallback
        });
    }

    updateTagGroupOrder(state: State, {
        pid,
        tagGroup: { _id: tgid },
        index,
        order
    }: ProjectActionPayloads.UpdateTagGroupOrder): State {
        return this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "tagGroups",
            value: ({ tagGroups }: Barrel) => {
                const tagGroup = arrayRemove(tagGroups!, t => t._id === tgid);
                if (!tagGroup) {
                    return tagGroups;
                }

                tagGroup.order = order;

                tagGroups!.splice(index, 0, tagGroup);

                return tagGroups;
            }
        });
    }

    updateTagGroupOrderBy(state: State, {
        pid,
        tgid,
        orderBy
    }: ProjectActionPayloads.UpdateTagGroupOrderBy) {
        let sortedTags: Tag[] = [];
        const tagGroupsUpdatedState = this.reducer.updateBarrelValue(state, {
            bid: pid,
            key: "tagGroups",
            value: ({ tagGroups, tags }: Barrel) => tagGroups!.map(tagGroup => {
                let newTagGroup = tagGroup;

                if (tagGroup._id === tgid) {
                    if (orderBy === TagGroupOrderBy.NAME) {
                        const barrelTags = tags || [];
                        sortedTags = getSortedTagsByName(barrelTags, tagGroup);
                        newTagGroup = {
                            ...newTagGroup,
                            tagsOrderBy: orderBy,
                            tags: sortedTags.map(({ _id }) => _id)
                        };
                    } else {
                        newTagGroup = {
                            ...newTagGroup,
                            tagsOrderBy: orderBy
                        };
                    }
                }

                return newTagGroup;
            })
        });
        return this.reducer.updateBarrelValue(tagGroupsUpdatedState, {
            bid: pid,
            key: "tags",
            value: ({ tags: barrelTags }: Barrel) => {
                const orders = generateNKeysBetween(null, null, sortedTags.length);
                sortedTags = sortedTags.map((tag, index) => ({
                    ...tag,
                    order: orders[index]
                }));
                return sortedTags.concat(
                    barrelTags!.filter(ptag =>
                        !sortedTags!.find(t => t._id === ptag._id)
                    )
                );
            }
        });
    }

    removeTagGroup(state: State, {
        pid: bid,
        tagGroup,
        oldTagGroupShownOnThumbnails
    }: ProjectActionPayloads.RemoveTagGroup): State {
        const tagGroupTagsMapped: Record<string, boolean> = tagGroup.tags?.reduce<Record<string, boolean>>(
            (acc, tid) => {
                acc[tid] = true;
                return acc;
            }, {}
        ) ?? {};

        const tagsUpdatedState = tagGroup.tags?.length === 0 ? state : this.reducer.updateBarrelValue(state, {
            bid,
            key: "tags",
            value: ({ tags }: Barrel) => tags!.filter(tag => !tagGroupTagsMapped[tag._id])
        });

        const screensUpdatedState = tagGroup.tags?.length === 0 ? tagsUpdatedState
            : this.reducer.updateBarrelValue(tagsUpdatedState, {
                bid,
                key: "screens",
                value: ({ screens }: Barrel) => screens?.map(screen => {
                    const newTags: Array<string> = [];
                screen.tags?.forEach(screenTag => {
                    if (!tagGroup.tags?.includes(screenTag)) {
                        newTags.push(screenTag);
                    }
                });
                return { ...screen, tags: newTags };
                })
            });

        const newTagGroupsCallback = ({ tagGroups }: Barrel) => {
            const newTagGroups = tagGroups!.filter(group => group._id !== tagGroup._id);
            if (oldTagGroupShownOnThumbnails) {
                const { _id: tgid } = oldTagGroupShownOnThumbnails;
                return toggleTagGroupShowOnThumbnails(newTagGroups, tgid, true);
            }

            return newTagGroups;
        };

        return this.reducer.updateBarrelValue(screensUpdatedState, {
            bid,
            key: "tagGroups",
            value: newTagGroupsCallback
        });
    }

    setProjectDotsRetrieved(state: State, {
        pid
    }: ProjectActionPayloads.SetProjectDots): State {
        return {
            ...state,
            dotsRetrieved: {
                ...state.dotsRetrieved,
                [pid]: true
            }
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // BarrelsReducer actions
            case ProjectsActionTypes.GET_PROJECTS_SUCCESS:
                return this.reducer.getBarrelsSuccess(state, action);
            case ProjectsActionTypes.GET_PROJECT_REQUESTED:
                return this.reducer.getBarrelRequested(state, {
                    bid: action.pid
                });
            case ProjectsActionTypes.GET_PROJECT_SUCCESS:
                return this.reducer.getBarrelSuccess(state, {
                    barrel: action.project
                });
            case ProjectsActionTypes.GET_PROJECT_FAILED:
                return this.reducer.getBarrelFailed(state, action);
            case ProjectsActionTypes.UPDATE_PROJECT:
                return this.reducer.updateBarrel(state, { bid: action.pid, data: action.data });
            case ProjectActionTypes.UPDATE_PROJECT_STYLEGUIDE_LINK:
                return this.updateProjectStyleguideLink(state, { pid: action.pid, data: action.data });
            case ProjectActionTypes.SET_PROJECT_DOTS:
                return this.setProjectDotsRetrieved(state, action);

            case BarrelActionTypes.UPDATE_NAME:
                return this.reducer.updateName(state, action);
            case BarrelActionTypes.UPDATE_DESCRIPTION:
                return this.reducer.updateDescription(state, action);
            case BarrelActionTypes.SET_EDITING_DESCRIPTION:
                return this.reducer.setEditingDescription(state, action);
            case BarrelActionTypes.UPDATE_DENSITY_SCALE:
                return this.reducer.updateDensityScale(state, action);
            case BarrelActionTypes.UPDATE_THUMBNAIL:
                return this.reducer.updateThumbnail(state, action);
            case BarrelActionTypes.ADD_INTEGRATION:
                return this.reducer.addIntegration(state, action);
            case BarrelActionTypes.AUTHENTICATED_SLACK_INTEGRATION:
                return this.reducer.addIntegration(state, {
                    ...action,
                    intype: "slacks"
                });
            case BarrelActionTypes.TOGGLE_SCENE:
                return this.reducer.toggleScene(state, action);
            case BarrelActionTypes.ADD_MEMBERS:
                return this.reducer.addMembers(state, action);
            case BarrelActionTypes.ADD_COLOR:
                return this.reducer.addColor(state, action);
            case BarrelActionTypes.ADD_MULTIPLE_COLORS:
                return this.reducer.addMultipleColors(state, action);
            case BarrelActionTypes.UPDATE_COLOR:
                return this.reducer.updateColor(state, action);
            case BarrelActionTypes.UPDATE_COLORS_NAME:
                return this.reducer.updateColorsName(state, action);
            case BarrelActionTypes.REMOVE_COLOR:
                return this.reducer.removeColor(state, action);
            case BarrelActionTypes.REMOVE_COLORS:
                return this.reducer.removeColors(state, action);
            case BarrelActionTypes.MIGRATE_COLORS:
                return this.reducer.migrateColors(state, action);
            case BarrelActionTypes.UPDATE_COLORS_ORDER:
                return this.reducer.updateColorsOrder(state, action);
            case BarrelActionTypes.ADD_TEXT_STYLE:
                return this.reducer.addTextStyle(state, action);
            case BarrelActionTypes.ADD_MULTIPLE_TEXT_STYLES:
                return this.reducer.addMultipleTextStyles(state, action);
            case BarrelActionTypes.UPDATE_TEXT_STYLE:
                return this.reducer.updateTextStyle(state, action);
            case BarrelActionTypes.REMOVE_TEXT_STYLE:
                return this.reducer.removeTextStyle(state, action);
            case BarrelActionTypes.REMOVE_ALL_TEXT_STYLES:
                return this.reducer.removeAllTextStyles(state, action);
            case BarrelActionTypes.ADD_SPACING_SECTION:
                return this.reducer.addSpacingSection(state, action);
            case BarrelActionTypes.UPDATE_SPACING_SECTION:
                return this.reducer.updateSpacingSection(state, action);
            case BarrelActionTypes.REMOVE_SPACING_SECTION:
                return this.reducer.removeSpacingSection(state, action);
            case BarrelActionTypes.ADD_SPACING_TOKEN:
                return this.reducer.addSpacingToken(state, action);
            case BarrelActionTypes.UPDATE_SPACING_TOKEN:
                return this.reducer.updateSpacingToken(state, action);
            case BarrelActionTypes.REMOVE_SPACING_TOKEN:
                return this.reducer.removeSpacingToken(state, action);
            case BarrelActionTypes.UPDATE_REM_PREFERENCES:
                return this.reducer.updateRemPreferences(state, action);
            case BarrelActionTypes.ADD_COMPONENT:
                return this.reducer.addComponent(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT:
                return this.reducer.updateComponent(state, action);
            case BarrelActionTypes.SET_COMPONENT_SNAPSHOT:
                return this.reducer.setComponentSnapshot(state, action);
            case BarrelActionTypes.SET_MULTIPLE_COMPONENT_SNAPSHOTS:
                return this.reducer.setMultipleComponentSnapshots(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.reducer.removeComponent(state, action);
            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.reducer.removeComponents(state, action);
            case BarrelActionTypes.ADD_COMPONENT_VERSION:
                return this.reducer.addComponentVersion(state, action);
            case BarrelActionTypes.ADD_COMPONENT_SECTION:
                return this.reducer.addComponentSection(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT_SECTION:
                return this.reducer.updateComponentSection(state, action);
            case BarrelActionTypes.MERGE_COMPONENT_SECTION:
                return this.reducer.mergeComponentSection(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT_SECTION:
                return this.reducer.removeComponentSection(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT_SECTIONS:
                return this.reducer.removeComponentSections(state, action);
            case BarrelActionTypes.UPDATE_COMPONENTS_ORDER:
                return this.reducer.updateComponentsOrder(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT_SECTIONS_ORDER:
                return this.reducer.updateComponentSectionsOrder(state, action);
            case BarrelActionTypes.ATTACH_JIRA_ISSUE:
                return this.reducer.attachJiraIssue(state, action);
            case BarrelActionTypes.REMOVE_JIRA_ISSUE_ATTACHMENT:
                return this.reducer.removeJiraIssueAttachment(state, action);
            case BarrelActionTypes.UPDATE_JIRA_ISSUE:
                return this.reducer.updateJiraIssue(state, action);
            case BarrelActionTypes.SET_LOCAL_CONNECTED_COMPONENTS:
                return this.reducer.setLocalConnectedComponents(state, action);
            case BarrelActionTypes.UPDATE_CONNECTED_COMPONENTS:
                return this.reducer.updateConnectedComponents(state, action);
            case BarrelActionTypes.CREATE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.createConnectedComponentItem(state, action);
            case BarrelActionTypes.UPDATE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.updateConnectedComponentItem(state, action);
            case BarrelActionTypes.DELETE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.deleteConnectedComponentItem(state, action);
            case BarrelActionTypes.UPDATE_WORKFLOW_STATUS:
                return this.reducer.updateWorkflowStatus(state, action);
            case BarrelActionTypes.UPDATE_WORKFLOW_STATUSES:
                return this.updateWorkflowStatuses(state, action);
            case BarrelActionTypes.DELETE_WORKFLOW_STATUS:
                return this.reducer.deleteWorkflowStatus(state, action);
            case BarrelActionTypes.DELETE_WORKFLOW_STATUSES:
                return this.deleteWorkflowStatuses(state, action);

            case BarrelActionTypes.UPDATE_USER_ROLE:
                return this.reducer.updateUserRole(state, action);
            case BarrelActionTypes.REMOVE_MEMBER:
                return this.reducer.removeMember(state, action);
            case BarrelActionTypes.REMOVE_INTEGRATION:
                return this.reducer.removeIntegration(state, action);

            case BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_DESCRIPTION:
                return this.reducer.updateVariableCollectionDescription(state, action);
            case BarrelActionTypes.ADD_VARIABLE_COLLECTION:
                return this.reducer.addVariableCollection(state, action);
            case BarrelActionTypes.DELETE_VARIABLE_COLLECTION:
                return this.reducer.deleteVariableCollection(state, action);
            case BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_GROUP_DESCRIPTION:
                return this.reducer.updateVariableCollectionGroupDescription(state, action);
            case BarrelActionTypes.UPDATE_VARIABLE_ORDER:
                return this.reducer.updateVariableOrder(state, action);

            // Tag Actions
            case ProjectActionTypes.CREATE_TAG:
                return this.createTag(state, action);
            case ProjectActionTypes.UPDATE_TAG:
                return this.updateTag(state, action);
            case ProjectActionTypes.UPDATE_TAG_ORDER:
                return this.updateTagOrder(state, action);
            case ProjectActionTypes.REMOVE_TAG:
                return this.removeTag(state, action);
            case ProjectActionTypes.ADD_TAG_TO_SCREENS:
                return this.addTagToScreens(state, action);
            case ProjectActionTypes.RECOVER_OLD_STATUS_TAGS:
                return this.recoverOldStatusTags(state, action);
            case ProjectActionTypes.RECOVER_DELETED_TAG_GROUP_TAGS:
                return this.recoverDeletedTagGroupTags(state, action);
            case ProjectActionTypes.REMOVE_TAG_FROM_SCREENS:
                return this.removeTagFromScreens(state, action);
            case ProjectActionTypes.CREATE_TAG_GROUP:
                return this.createTagGroup(state, action);
            case ProjectActionTypes.CREATE_TAG_GROUP_WITH_TAGS:
                return this.createTagGroupWithTags(state, action);
            case ProjectActionTypes.UPDATE_TAG_GROUP:
                return this.updateTagGroup(state, action);
            case ProjectActionTypes.UPDATE_TAG_GROUP_ORDER:
                return this.updateTagGroupOrder(state, action);
            case ProjectActionTypes.UPDATE_TAG_GROUP_ORDER_BY:
                return this.updateTagGroupOrderBy(state, action);
            case ProjectActionTypes.REMOVE_TAG_GROUP:
                return this.removeTagGroup(state, action);

            // Common Barrel Actions
            case BarrelActionTypes.ADD:
                return this.add(state, action);
            case BarrelActionTypes.ACTIVATE:
                return this.activate(state, action);
            case BarrelActionTypes.ARCHIVE:
                return this.archive(state, action);
            case BarrelActionTypes.REMOVE:
                return this.remove(state, action);
            case ProjectActionTypes.LINK_PROJECT_SUCCESS:
            case ProjectActionTypes.LINK_PROJECT:
                return this.linkProjectToStyleguide(state, action);
            case ProjectActionTypes.UNLINK_PROJECT:
                return this.unlinkProjectFromStyleguide(state, action);
            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateUserData(state, action);
            case ProjectsActionTypes.TRANSFER_OWNERSHIP:
            case BarrelActionTypes.OWNERSHIP_TRANSFERRED:
                return this.transferOwnership(state, action);
            case BarrelActionTypes.LOAD:
                return this.load(state, action);
            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.loadIntermediate(state, action);
            case BarrelActionTypes.PRELOAD_PROJECT_DASHBOARD:
                return this.preloadProjectDashboard(state, action);
            case BarrelActionTypes.MOVE_RESOURCES_SUCCESS:
                return this.moveResourcesSuccess(state, action);
            case BarrelActionTypes.CHANGE_ASSET_NAME:
                return this.changeAssetName(state, action);
            case BarrelActionTypes.JOIN:
                return this.joinProjectRequest(state, action);
            case BarrelActionTypes.STOP_JOIN_LOADING:
                return this.joinProjectFinalize(state, action);

            // Organization Specific Actions
            case OrganizationActionTypes.DELETE_ORGANIZATION_WORKFLOW_STATUS:
                return this.reducer.onOrganizationWorkflowStatusDelete(state, action);
            case OrganizationActionTypes.REMOVE_UNJOINED_BARRELS:
                return this.reducer.removeUnjoinedBarrels(state, action);

            // Projects Specific Actions
            case ProjectsActionTypes.CREATE_PROJECT_REQUEST:
                return this.createProjectRequest(state);
            case ProjectsActionTypes.CREATE_PROJECT_FINALIZE:
                return this.createProjectFinalize(state);

            // Dashboard actions
            case DashboardActionTypes.ADD_SECTION:
                return this.addSection(state, action);
            case DashboardActionTypes.UPDATE_SECTION:
                return this.updateSection(state, action);
            case DashboardActionTypes.CREATE_SECTION:
                return this.createSection(state, action);
            case DashboardActionTypes.RENAME_SECTION:
                return this.renameSection(state, action);
            case DashboardActionTypes.UPDATE_SECTION_DESCRIPTION:
                return this.updateSectionDescription(state, action);
            case DashboardActionTypes.UPDATE_SECTION_ORDER:
                return this.updateSectionOrder(state, action);
            case DashboardActionTypes.REMOVE_SECTION:
                return this.removeSection(state, action);
            case DashboardActionTypes.UPDATE_SCREEN_ORDER:
                return this.updateScreenOrder(state, action);
            case DashboardActionTypes.UPDATE_SCREEN_ORDER_V2:
                return this.updateScreenOrderV2(state, action);
            case DashboardActionTypes.CREATE_VARIANT_GROUP:
                return this.createVariantGroup(state, action);
            case DashboardActionTypes.ADD_SCREENS_TO_VARIANT_GROUP:
                return this.addScreensToVariantGroup(state, action);
            case DashboardActionTypes.UPDATE_VARIANT_GROUP:
                return this.updateVariantGroup(state, action);
            case DashboardActionTypes.RENAME_VARIANT_GROUP:
                return this.renameVariantGroup(state, action);
            case DashboardActionTypes.DELETE_VARIANT_GROUP:
                return this.deleteVariantGroup(state, action);
            case DashboardActionTypes.DETACH_VARIANT_GROUP:
                return this.detachVariantGroup(state, action);
            case DashboardActionTypes.DELETE_SCREENS:
                return this.handleDeleteScreens(state, action);
            case MoveResourcesActionTypes.MOVE_SUCCESS:
                return this.moveDashboardResourcesSuccess(state, action);

            // Screen actions
            case ScreenActionTypes.ADD_SCREEN:
                return this.addScreen(state, action);
            case ScreenActionTypes.ADD_SCREEN_TILES:
                return this.addScreenTiles(state, action);
            case ScreenActionTypes.UPDATE_NAME:
                return this.updateScreenName(state, action);
            case ScreenActionTypes.UPDATE_THUMBNAIL_STATUS:
                return this.updateThumbnailStatus(state, action);
            case ScreenActionTypes.REMOVE_SCREEN:
                return this.removeScreen(state, action);

            case ScreenActionTypes.SET_VERSIONS:
                return this.setVersions(state, action);
            case ScreenActionTypes.SET_SNAPSHOT:
                return this.setSnapshot(state, action);
            case ScreenActionTypes.ADD_VERSION:
                return this.addVersion(state, action);
            case ScreenActionTypes.UPDATE_COMMIT_MESSAGE:
                return this.updateCommitMessage(state, action);
            case ScreenActionTypes.REMOVE_VERSION:
                return this.removeVersion(state, action);
            case ScreenActionTypes.REMOVE_SCREEN_VARIANT:
                return this.removeScreenVariant(state, action);
            case ScreenActionTypes.ADD_SCREEN_VARIANT:
                return this.addScreenVariant(state, action);
            case ScreenActionTypes.RENAME_SCREEN_VARIANT:
                return this.renameScreenVariant(state, action);
            case ScreenActionTypes.REORDER_SCREEN_VARIANT:
                return this.reorderScreenVariant(state, action);
            case ScreenActionTypes.ADD_ANNOTATION:
                return this.addComponentLinkedAnnotation(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION:
                return this.updateComponentLinkedAnnotation(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_TYPE:
                return this.updateComponentLinkedAnnotationType(state, action);
            case ScreenActionTypes.DELETE_ANNOTATION_TYPE:
                return this.deleteComponentLinkedAnnotationType(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_TEXT:
                return this.updateComponentLinkedAnnotationText(state, action);
            case ScreenActionTypes.UPDATE_ANNOTATION_POSITION:
                return this.updateComponentLinkedAnnotationPosition(state, action);
            case ScreenActionTypes.REMOVE_ANNOTATION:
                return this.removeComponentLinkedAnnotation(state, action);
            case ScreenActionTypes.UNLINK_ANNOTATION:
                return this.unlinkComponentLinkedAnnotation(state, action);
            case ScreenActionTypes.SET_VERSION_DIFFS:
                return this.setVersionDiffs(state, action);
            case ScreenActionTypes.HIDE_DIFF_POPUP:
                return this.hideDiffPopup(state, action);

            case AppActionTypes.LOAD_WORKSPACE_DATA:
                return this.reducer.getBarrelsSuccess(state, action);
            case AppActionTypes.RESET:
                return this.reset();
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
                return this.hydrateEmbeddedData(state, action);

            case StyleguideActionTypes.CREATE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.addComponentVariantProperty(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.updateComponentVariantProperty(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_NAME:
                return this.reducer.updateComponentVariantPropertyName(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_VALUE:
                return this.reducer.updateComponentVariantPropertyValue(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_VALUES:
                return this.reducer.updateComponentVariantPropertyValues(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTIES_ORDER:
                return this.reducer.updateComponentVariantPropertiesOrder(state, action);
            case StyleguideActionTypes.SET_COMPONENT_VARIANT_DATA:
                return this.reducer.setComponentVariantData(state, action);
            case StyleguideActionTypes.REMOVE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.removeComponentVariantProperty(state, action);
            case StyleguideActionTypes.REMOVE_COMPONENT_VARIANT_DATA:
                return this.reducer.removeComponentVariantData(state, action);
            case StyleguideActionTypes.GROUP_COMPONENTS_BY_VARIANT_PROPERTY:
                return this.reducer.groupComponentsByVariantProperty(state, action);

            // Component actions
            case ComponentActionTypes.SET_COMPONENT_VERSIONS:
                return this.reducer.setComponentVersions(state, action);
            case ComponentActionTypes.UPDATE_COMMIT_MESSAGE:
                return this.reducer.updateCommitMessage(state, action);
            case ComponentActionTypes.REMOVE_VERSION:
                return this.reducer.removeVersion(state, action);
            case ComponentActionTypes.SET_VERSION_DIFFS:
                return this.reducer.setVersionDiffs(state, action);

            case AssetsActionTypes.SET_VERSION_ASSETS:
                return this.setSnapshot(state, {
                    ...action,
                    snapshot: {
                        assets: (action.assets) as ApiAsset[]
                    }
                });

            default:
                return state;
        }
    }
}

export default ProjectsDataStore;
export { State as ProjectsDataStoreState };
