import MenuItem, { MenuItemDescription } from "../../../../foundation/electron/MenuItem";
import { StyleguideSectionType } from "../../../../foundation/model/StyleguideSection";
import fluxRuntime from "../../../data/fluxRuntime";

import MenuItemID from "./MenuItemID";
import MenuViewType from "./MenuViewType";
import separator from "./separator";

const connectedComponentsMenuItem = (menuViewType: MenuViewType): MenuItemDescription => {
    if (menuViewType === MenuViewType.LOGIN) {
        return {
            id: MenuItemID.CONNECTED_COMPONENTS,
            label: "Connected Components",
            submenu: [{
                id: MenuItemID.ENABLE_LOCAL_CONFIGURATION,
                type: "radio",
                checked: false,
                label: "Enable Local Configuration",
                accelerator: "CmdOrCtrl+Alt+L",
                enabled: false
            }]
        };
    }

    const { selectedStyleguideSection } = fluxRuntime.StyleguideStore.getState();
    const { connectedComponentsDevModeEnabled } = fluxRuntime.BarrelStore.getState();

    const componentsSelected = selectedStyleguideSection === StyleguideSectionType.Components;

    const enableMenuItems = menuViewType === MenuViewType.SCREEN ||
        (menuViewType === MenuViewType.PROJECT_STYLEGUIDE && componentsSelected) ||
        (menuViewType === MenuViewType.STYLEGUIDE && componentsSelected);

    return {
        id: MenuItemID.CONNECTED_COMPONENTS,
        label: "Connected Components",
        submenu: [{
            id: MenuItemID.ENABLE_LOCAL_CONFIGURATION,
            type: "radio",
            checked: connectedComponentsDevModeEnabled,
            label: "Enable Local Configuration",
            accelerator: "CmdOrCtrl+Alt+L",
            enabled: enableMenuItems
        }]
    };
};

const extensionsMenuItem = (menuViewType: MenuViewType): MenuItemDescription => {
    const manageEnabled = menuViewType === MenuViewType.PROJECT_DASHBOARD ||
        menuViewType === MenuViewType.PROJECT_STYLEGUIDE ||
        menuViewType === MenuViewType.STYLEGUIDE ||
        menuViewType === MenuViewType.SCREEN;

    return {
        id: MenuItemID.EXTENSIONS,
        label: "Extensions",
        submenu: [{
            id: MenuItemID.MANAGE_EXTENSIONS,
            label: "Manage Extensions…",
            enabled: manageEnabled
        }, {
            id: MenuItemID.EXPLORE_EXTENSIONS,
            label: "Explore Extensions…"
        }]
    };
};

function enabledDevelopMenu(menuViewType: MenuViewType): MenuItem {
    return new MenuItem({
        id: MenuItemID.DEVELOP_SUBMENU,
        label: "Develop",
        submenu: [
            connectedComponentsMenuItem(menuViewType),
            separator,
            extensionsMenuItem(menuViewType)
        ]
    });
}

function disabledDevelopMenu(): MenuItem {
    return new MenuItem({
        id: MenuItemID.DEVELOP_SUBMENU,
        label: "Develop",
        enabled: false
    });
}

export default function developSubmenu(menuViewType: MenuViewType | null): MenuItem {
    switch (menuViewType) {
        case MenuViewType.WORKSPACE_PROJECTS:
        case MenuViewType.WORKSPACE_STYLEGUIDES:
        case MenuViewType.ACTIVITIES:
        case MenuViewType.PROJECT_DASHBOARD:
        case MenuViewType.PROJECT_FLOWS:
        case MenuViewType.PROJECT_STYLEGUIDE:
        case MenuViewType.STYLEGUIDE:
        case MenuViewType.SCREEN:
        case MenuViewType.LOGIN:
            return enabledDevelopMenu(menuViewType);
        default:
            return disabledDevelopMenu();
    }
}
