import { PLATFORM } from "../../../../../foundation/enums";
import BarrelActions from "../../../../data/barrel/BarrelActions";
import BarrelHelpers from "../../../../data/barrel/BarrelHelpers";
import { StoreState } from "../../StoreState";

import { StoreProps, DispatchProps } from "./AssetSettingsProps";

export function mapStateToProps({
    BarrelStore: {
        assetDensityScalePrefs,
        assetNamingConvention,
        showOptimizedSVG,
        showOptimizedPNG,
        showOptimizedJPG
    }
}: StoreState): StoreProps {
    const barrel = BarrelHelpers.getBarrel()!;

    return {
        platform: barrel.type as PLATFORM,
        assetDensityScalePrefs: assetDensityScalePrefs!,
        namingConvention: assetNamingConvention,
        showOptimizedSVG,
        showOptimizedPNG,
        showOptimizedJPG
    };
}

export function mapDispatchToProps(): DispatchProps {
    return {
        onAssetDensityOptionChange: BarrelActions.changeAssetDensityScalePref,
        onNamingConventionChange: BarrelActions.changeAssetNamingConvention,
        onShowOptimizedSVGChange: BarrelActions.toggleSVGOptimization,
        onShowOptimizedPNGChange: BarrelActions.togglePNGOptimization,
        onShowOptimizedJPGChange: BarrelActions.toggleJPGOptimization
    };
}
