enum ActionTypes {
    LOAD_USER = "user/loadUser",
    UPDATE_USER = "user/updateUser",
    SET_SELECTED_VIEW = "user/setSelectedView",
    SET_EMAIL_NOTIFICATION_PREFERENCES = "user/setEmailNotificationPreferences",
    MUTE_PROJECT_NOTIFICATIONS = "user/muteProjectNotifications",
    MUTE_STYLEGUIDE_NOTIFICATIONS = "user/muteStyleguideNotifications",
    UNMUTE_PROJECT_NOTIFICATIONS = "user/unmuteProjectNotifications",
    UNMUTE_STYLEGUIDE_NOTIFICATIONS = "user/unmuteStyleguideNotifications",
    UPDATE_FOLLOWED_PROJECT_NOTIFICATIONS = "user/updateFollowedProjectNotifications",
    SET_FOLLOWED_PROJECT_NOTIFICATIONS = "user/setFollowedProjectNotifications",
    MARK_ZEPLIN_THREE_ZERO_WHATS_NEW_AS_SEEN = "user/markZeplinThreeZeroWhatsNewAsSeen",
    STOP_PROMOTING_SPACING_SECTION_DESCRIPTION_EDIT_BUTTON = "user/stopPromotingSpacingSectionDescriptionEditButton",
    HIDE_STYLEGUIDE_TUTORIAL = "user/hideStyleguideTutorial",
    HIDE_INVITED_USER_HINTBOX = "user/hideInvitedUserHintbox",
    HIDE_PERSONAL_WORKSPACE_HINTBOX = "user/hidePersonalWorkspaceHintbox",
    HIDE_DASHBOARD_ONBOARDING_HINTBOX = "user/hideDashboardOnboardingHintbox",
    HIDE_FREE_TRIAL_ONBOARDING_HINTBOX = "user/hideFreeTrialOnboardingHintbox",
    HIDE_FREE_TRIAL_PROMOTION_DIALOG = "user/hideFreeTrialPromotionDialog",
    HIDE_PHOTOSHOP_INSTRUCTIONS_HINTBOX = "user/hidePhotoshopInstructionsHintbox",
    HIDE_ADD_TO_FLOW_ONBOARDING = "user/hideAddToFlowOnboarding",
    HIDE_FLOWS_TAB_TOOLTIP = "user/hideFlowsTabTooltip",
    STOP_PROMOTING_EXPORT_DESIGN_TOKENS = "user/stopPromotingExportDesignTokens",
    ACQUAINTANCES_REQUESTED = "user/acquaintancesRequested",
    SET_ACQUAINTANCES = "user/setAcquaintances",
    GET_ACQUAINTANCES_FAILED = "user/getAcquaintancesFailed",
    SET_USER_ATTRIBUTE = "user/setUserAttribute",
    TERMINATE_SESSION = "user/terminateSession",
    MARK_STORYBOOK_INTEGRATION_PROMOTION_AS_SEEN = "user/markStorybookIntegrationPromotionAsSeen",
    MARK_LINK_TO_STYLEGUIDE_PROMOTION_AS_SEEN = "user/markLinkToStyleguidePromotionAsSeen",
    MARK_COLOR_PALETTE_SECTIONS_PROMOTION_AS_SEEN = "user/markColorPaletteSectionsPromotionAsSeen",
    SET_TERMS_FOR_APPROVAL = "user/setTermsForApproval",
    ACCEPT_TERMS = "user/acceptTerms",
    HIDE_FLOW_ONBOARDING_CONNECTOR = "user/hideFlowOnboardingConnector",
    SET_LAST_SEEN_WHATS_NEW_DATA_ID = "user/setLastSeenWhatsNewDataId",
    SET_WHATS_NEW_EMOJI_SEEN_COUNT = "user/setWhatsNewEmojiSeenCount",
    SHOW_SVG_CONTENT = "user/showSVGContent",
    HIDE_SVG_CONTENT = "user/hideSVGContent",
    HIDE_DESKTOP_NOTIFICATION_BANNER= "user/hideDesktopNotificationBanner",
    HIDE_EMAIL_VERIFICATION_BANNER = "user/hideEmailVerificationBanner",
    UPDATE_PLAN_DATA = "user/updatePlanData",
    UPDATE_PERSONAL_SUBSCRIPTION = "user/updatePersonalSubscription",
    INCREASE_NEW_APPROVAL_CREATED_FEEDBACK_COUNTER = "user/increaseNewApprovalCreatedFeedbackCounter",
    INCREASE_CREATE_SECTION_PROMOTION_COUNTER = "user/increaseCreateSectionPromotionCounter",
    INCREASE_ADD_TAG_PROMOTION_COUNTER = "user/increaseAddTagPromotionCounter",
    INCREASE_SET_AS_VARIANT_PROMOTION_COUNTER = "user/increaseSetAsVariantPromotionCounter",
    INCREASE_REQUEST_APPROVAL_PROMOTION_COUNTER = "user/increaseRequestApprovalPromotionCounter",
    MARK_APPROVAL_REQUESTS_BUTTON_PROMOTION_AS_SEEN = "user/markApprovalRequestsButtonPromotionAsSeen"
}

export default ActionTypes;
