enum ActionTypes {
    GET_PROJECT_APPROVALS = "approvals/getProjectApprovals",
    GET_PROJECT_APPROVALS_SUCCESS = "approvals/getProjectApprovalsSuccess",
    GET_PROJECT_APPROVALS_FINISH = "approvals/getProjectApprovalsFinish",

    GET_WORKSPACE_APPROVALS = "approvals/getWorkspaceApprovals",
    GET_WORKSPACE_APPROVALS_SUCCESS = "approvals/getWorkspaceApprovalsSuccess",

    CREATE_APPROVAL = "approvals/createApproval",
    CREATE_APPROVAL_SUCCESS = "approvals/createApprovalSuccess",
    CREATE_APPROVAL_FAILURE = "approvals/createApprovalFailure",
    ADD_APPROVAL = "approvals/addApproval",

    UPDATE_APPROVAL = "approvals/updateApproval",
    UPDATE_APPROVAL_SUCCESS = "approvals/updateApprovalSuccess",
    UPDATE_APPROVAL_FAILURE = "approvals/updateApprovalFailure",

    UPDATE_APPROVAL_REVIEW = "approvals/updateApprovalReview",
    UPDATE_APPROVAL_REVIEW_SUCCESS = "approvals/updateApprovalReviewSuccess",
    UPDATE_APPROVAL_REVIEW_FAILURE = "approvals/updateApprovalReviewFailure",

    DELETE_APPROVAL_REVIEWER = "approvals/deleteApprovalReviewer",
    DELETE_APPROVAL_REVIEWER_SUCCESS = "approvals/deleteApprovalReviewerSuccess",
    DELETE_APPROVAL_REVIEWER_FAILURE = "approvals/deleteApprovalReviewerFailure",

    DELETE_APPROVAL = "approvals/deleteApproval",

    ADD_WORKSPACE_APPROVAL = "approvals/addWorkspaceApproval",
    UPDATE_WORKSPACE_APPROVAL = "approvals/updateWorkspaceApproval",
    UPDATE_WORKSPACE_APPROVAL_REVIEW = "approvals/updateWorkspaceApprovalReview",
    DELETE_WORKSPACE_APPROVAL_REVIEWER = "approvals/deleteWorkspaceApprovalReviewer",
    DELETE_WORKSPACE_APPROVAL = "approvals/deleteWorkspaceApproval"
}

export default ActionTypes;
