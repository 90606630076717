/**
 Note: Methods of this file actually returns PartialScreen instead of {@link Screen} when dots of screen
 are fetched before the screen itself. Typing is done using {@link Screen} for eliminating need of typecasts because the
 described condition is very rare. Consumers of those methods must be aware.

 This only happened on Activities at the time of writing this note.
 */

/**
 Note: Dot related methods of this file actually returns "Dot | DrafDot" instead of {@link Dot} when a dot is being
 created. Typing is done as {@link Dot} for eliminating need of typecasts because the described condition is very rare.
 Consumers of those methods must be aware.
 */
import { Annotation } from "../../../foundation/api/model";
import ScreenSection from "../../../foundation/api/model/barrels/ScreenSection";
import Dot from "../../../foundation/api/model/dots/Dot";
import DotComment from "../../../foundation/api/model/dots/DotComment";
import Layer from "../../../foundation/api/model/snapshots/layers/Layer";
import Barrel from "../../../foundation/model/Barrel";
import ComponentAnnotation from "../../../foundation/model/ComponentAnnotation";
import ComponentLinkedAnnotation from "../../../foundation/model/ComponentLinkedAnnotation";
import InspectLayer from "../../../foundation/model/InspectLayer";
import NoteType from "../../../foundation/model/NoteType";
import CommentStatusFilter from "../../../foundation/model/RemarkStatusFilters";
import RemarkType from "../../../foundation/model/RemarkType";
import Screen from "../../../foundation/model/Screen";
import ScreenVersion from "../../../foundation/model/ScreenVersion";
import router from "../../../foundation/router";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { isDraftAnnotation } from "../../../foundation/utils/dot";
import { filterSortScreensMemoized } from "../../../foundation/utils/filter-sort";
import { getFlattenedLayersMemoized } from "../../../foundation/utils/layer";
import { rectContainsPoint } from "../../../foundation/utils/rectangle";
import uniquifyArrayOfObjects from "../../../foundation/utils/uniquify";
import { urlResolve } from "../../../foundation/utils/url";

import ColorName from "../../../library/ColorDot/ColorName";
import { HEADER_HEIGHT } from "../../../library/v2/Header";

import ApprovalsHelpers from "../approvals/ApprovalsHelpers";
import BarrelHelpers from "../barrel/BarrelHelpers";
import fluxRuntime from "../fluxRuntime";

function getScreens({ pid, sids = [] }: {
    pid?: string;
    sids?: string[];
} = {}): Screen[] | null {
    const project = BarrelHelpers.getProject({ pid });

    if (!project?.screens) {
        return null;
    }

    return project.screens!.reduce((acc, screen) => {
        if (sids.includes(screen._id)) {
            acc.push(screen as Screen);
        }

        return acc;
    }, [] as Screen[]);
}

function getScreen({ pid, sid }: {
    pid?: string | null;
    sid?: string | null;
} = {}): Screen | null | undefined {
    const project = BarrelHelpers.getProject({ pid });

    if (!project?.screens) {
        return null;
    }

    let screenId: string | undefined | null = sid;
    if (!screenId) {
        ({ inspectableId: screenId } = fluxRuntime.InspectableViewStore.getState());
    }

    if (!screenId) {
        return null;
    }

    return project.screens.find(screen => screen._id === screenId) as Screen | undefined;
}

function getVersion({ pid, sid, vid }: {
    pid?: string;
    sid?: string;
    vid?: string;
} = {}): ScreenVersion | null | undefined {
    const screen = getScreen({ pid, sid });

    if (!screen?.versions) {
        return null;
    }

    let versionId: string | undefined | null = vid;
    if (!versionId) {
        ({ versionId } = fluxRuntime.InspectableViewStore.getState());
    }

    if (!versionId) {
        return null;
    }

    return screen.versions.find(version => version._id === versionId);
}

function getFilteredScreens(projectToBeFiltered?: Barrel) {
    const project = projectToBeFiltered || BarrelHelpers.getProject();
    if (!project?.screens) {
        return null;
    }

    const {
        filterValue, sortType, tagFilter, jiraIssueFilter, selectedTagGroup
    } = fluxRuntime.DashboardStore.getState();

    const {
        sortedSections,
        variantGroupsMapping
    } = filterSortScreensMemoized({
        project,
        sortType,
        filterValue,
        tagFilter,
        jiraIssueFilter,
        selectedTagGroup
    });

    const variantScreenMapping = Object.keys(variantGroupsMapping).reduce((acc, defaultVariantScreenId) => {
        variantGroupsMapping[defaultVariantScreenId].variants.forEach(({ id }) => {
            acc[id] = defaultVariantScreenId;
        });
        return acc;
    }, {} as BasicRecord);

    return {
        visibleScreens: sortedSections.flatMap(section => section.screens),
        variantScreenMapping
    };
}

function getPreviousScreen(screenId: string, nthPrevious = 1): Screen | null {
    const { visibleScreens, variantScreenMapping } = { ...getFilteredScreens() };
    if (!visibleScreens) {
        return null;
    }

    const defaultScreenId = variantScreenMapping![screenId] || screenId;
    const screenIndex = visibleScreens.findIndex(s => s._id === defaultScreenId);

    if (screenIndex < 1) {
        return null;
    }

    return visibleScreens[screenIndex - nthPrevious] as Screen;
}

function getPreviousScreenWithComments(screenId: string, callback: (dot: Dot) => boolean): Screen | null {
    const projectId = fluxRuntime.BarrelStore.getState().barrelId;
    const { visibleScreens, variantScreenMapping } = { ...getFilteredScreens() };

    if (!visibleScreens || !projectId) {
        return null;
    }

    const defaultScreenId = variantScreenMapping![screenId] || screenId;
    const screenIndex = visibleScreens.findIndex(
        s => s._id === defaultScreenId);

    if (screenIndex < 1) {
        return null;
    }

    const previousScreens = visibleScreens.slice(0, screenIndex);
    const screen = previousScreens
        .reverse()
        .find(
            previousScreen =>
                getAllDotsOfVariantGroupOfScreen({ pid: projectId, sid: previousScreen._id })?.some(callback)
        );

    if (screen === undefined) {
        return null;
    }

    return screen as Screen;
}

function getNextApprovalScreenId(direction: number): string | undefined {
    const { approvalsId, inspectableId } = fluxRuntime.InspectableViewStore.getState();

    if (!inspectableId || !approvalsId) {
        return;
    }

    const approvalsScreens = (ApprovalsHelpers.getSortedApprovalScreens(approvalsId) ?? [])
        .filter(approvalScreen => !approvalScreen.variantsCount);
    const currentApprovalsScreenIdx = approvalsScreens.findIndex(({ _id }) => _id === inspectableId);

    if (currentApprovalsScreenIdx === -1) {
        return;
    }

    const targetApSid = approvalsScreens[currentApprovalsScreenIdx + direction]?._id;

    if (!targetApSid) {
        return;
    }

    return targetApSid;
}

function getNextScreenWithComments(screenId: string, callback: (dot: Dot) => boolean): Screen | null {
    const projectId = fluxRuntime.BarrelStore.getState().barrelId;
    const { visibleScreens, variantScreenMapping } = { ...getFilteredScreens() };

    if (!visibleScreens || !projectId) {
        return null;
    }

    const defaultScreenId = variantScreenMapping![screenId] || screenId;
    const screenIndex = visibleScreens.findIndex(s => s._id === defaultScreenId);

    if (screenIndex === -1 || screenIndex === visibleScreens.length - 1) {
        return null;
    }

    const nextScreens = visibleScreens.slice(screenIndex + 1, visibleScreens.length);
    const screen = nextScreens
        .find(
            nextScreen =>
                getAllDotsOfVariantGroupOfScreen({ pid: projectId, sid: nextScreen._id })?.some(callback)
        );

    if (!screen) {
        return null;
    }

    return screen as Screen;
}

function getNextScreen(screenId: string, nthNext = 1): Screen | null {
    const { visibleScreens, variantScreenMapping } = { ...getFilteredScreens() };

    if (!visibleScreens) {
        return null;
    }

    const defaultScreenId = variantScreenMapping![screenId] || screenId;
    const screenIndex = visibleScreens.findIndex(s => s._id === defaultScreenId);

    if (screenIndex === -1 || screenIndex === visibleScreens.length - 1) {
        return null;
    }

    return visibleScreens[screenIndex + nthNext] as Screen;
}

function getSectionOfScreen({ pid, sid }: {
    pid?: string;
    sid?: string;
} = {}): ScreenSection | null | undefined {
    const project = BarrelHelpers.getProject({ pid });

    if (!project?.sections) {
        return null;
    }

    let screenId: string | undefined | null = sid;
    if (!screenId) {
        ({ inspectableId: screenId } = fluxRuntime.InspectableViewStore.getState());
    }

    if (!screenId) {
        return null;
    }

    return project.sections.find(section => section.screens.includes(screenId!));
}

function getDots({ pid, sid }: { pid?: string; sid?: string; } = {}): Dot[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;

    if (!projectId || !screenId) {
        return null;
    }

    const { dots } = fluxRuntime.DotsDataStore.getState();
    return dots?.[projectId]?.[screenId] ?? null;
}

function getDotsWithoutApprovals({ pid, sid }: { pid?: string; sid?: string; } = {}): Dot[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;
    const { approvalsMode } = fluxRuntime.InspectableViewStore.getState();

    if (!projectId || !screenId) {
        return null;
    }

    const { dots } = fluxRuntime.DotsDataStore.getState();
    const allDots = dots?.[projectId]?.[screenId] ?? null;

    if (approvalsMode) {
        return allDots;
    }

    return allDots?.filter(dot => dot.dotType !== RemarkType.ApprovalNote);
}

function getAllDotsOfVariantGroupOfScreen({ pid, sid }: { pid?: string; sid: string; }): Dot[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;

    if (!projectId || !screenId) {
        return null;
    }

    const { dots } = fluxRuntime.DotsDataStore.getState();
    const currVariantGroup = BarrelHelpers.getVariantGroupOfScreen(sid);

    if (currVariantGroup) {
        return currVariantGroup.screens
            .reduce((allDots, variantScreen) => (
                allDots.concat(dots?.[projectId]?.[variantScreen.id] || [])
            ), [] as Dot[]);
    }

    return dots?.[projectId]?.[screenId] ?? null;
}

function getAnnotations(
    { pid, sid }: { pid?: string; sid?: string; } = {}
): (Annotation | ComponentAnnotation)[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;

    if (!projectId || !screenId) {
        return null;
    }

    const { annotations } = fluxRuntime.AnnotationsDataStore.getState();

    if (!(annotations?.[projectId]?.[screenId])) {
        return null;
    }

    const componentAnnotations = getComponentAnnotationsOfScreen({ pid, sid });

    return [
        ...(annotations?.[projectId]?.[screenId] ?? []),
        ...(componentAnnotations ?? [])
    ];
}

function getComponentAnnotationsOfScreen({
    pid,
    sid
}: {
    pid?: string;
    sid?: string;
} = {}): ComponentAnnotation[] | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;

    if (!projectId || !screenId) {
        return null;
    }

    const { snapshot } = getVersion({ pid: projectId, sid: screenId }) || {};
    if (!snapshot?.layers) {
        return null;
    }

    const project = BarrelHelpers.getProject({ pid: projectId });
    if (!project?.componentAnnotations) {
        return null;
    }

    const snapshotLayers = getFlattenedLayersMemoized({
        layers: snapshot.layers
    });

    const linkedAnnotationsMap = new Map<string, ComponentLinkedAnnotation[]>();
    for (const annotation of project.componentAnnotations) {
        const addedAnnotations = linkedAnnotationsMap.get(annotation.componentSourceId!) || [];
        linkedAnnotationsMap.set(annotation.componentSourceId!, [...addedAnnotations, annotation]);
    }

    const layerContainsCoordinates = (
        linkedAnnotation: ComponentLinkedAnnotation,
        layer: InspectLayer
    ): linkedAnnotation is Annotation => {
        if (
            "originalCoordinates" in linkedAnnotation &&
            linkedAnnotation.originalCoordinates &&
            linkedAnnotation.originalCoordinates.screenId === screenId
        ) {
            return rectContainsPoint(layer.absoluteRect, {
                x: linkedAnnotation.originalCoordinates.x * snapshot.width,
                y: linkedAnnotation.originalCoordinates.y * snapshot.height
            });
        }

        return true;
    };

    const annotations: ComponentAnnotation[] = [];
    const addToAnnotations = (coid: string, layer: InspectLayer) => {
        // to be sure we don't have annotations for different layers, when we add it for a layer we will remove them.
        // If the length of the array reaches to zero this will mean that all annotations corresponding to provided
        // coid are added to the array. So we can safely delete the related map entry.
        const linkedAnnotations = linkedAnnotationsMap.get(coid)!;
        for (let i = linkedAnnotations.length - 1; i >= 0; i--) {
            const currentLinkedAnnotation = linkedAnnotations[i];
            if (!layerContainsCoordinates(currentLinkedAnnotation, layer)) {
                continue;
            }

            annotations.push({ ...currentLinkedAnnotation, componentLayer: layer });
            linkedAnnotations.splice(i, 1);
        }

        if (linkedAnnotations.length === 0) {
            linkedAnnotationsMap.delete(coid);
        }
    };

    for (const layer of snapshotLayers) {
        // if we consumed all the map values, no point of iterating
        if (linkedAnnotationsMap.size === 0) {
            break;
        }

        const { componentName, componentId } = layer;
        if (!componentName && !componentId) {
            continue;
        }

        if (componentId && linkedAnnotationsMap.has(componentId)) {
            addToAnnotations(componentId, layer as InspectLayer);
        }
    }

    return annotations;
}

function getAbsolutePositionOfComponentAnnotationDot(
    annotation: ComponentAnnotation | null
): { x: number; y: number; } | null {
    if (!annotation?.componentSourceId) {
        return null;
    }

    const { snapshot } = getVersion({}) || {};
    if (!snapshot?.layers) {
        return null;
    }

    const { componentLayer } = annotation as ComponentAnnotation;

    return {
        x: (componentLayer.absoluteRect.x + (annotation.x * componentLayer.absoluteRect.width)) / snapshot.width,
        y: (componentLayer.absoluteRect.y + (annotation.y * componentLayer.absoluteRect.height)) / snapshot.height
    };
}

function getDot({ pid, sid, did }: {
    pid?: string;
    sid?: string;
    did?: string;
} = {}): Dot | null | undefined {
    const dots = getDots({ pid, sid });

    if (!dots) {
        return null;
    }

    let dotId: string | null | undefined = did;
    if (!dotId) {
        ({ dotId } = fluxRuntime.DotsStore.getState());
    }

    if (!dotId) {
        return null;
    }

    return dots.find(d => d._id === dotId);
}

function getAnnotation({ pid, sid, atid }: {
    pid?: string;
    sid?: string;
    atid?: string;
} = {}): Annotation | ComponentAnnotation | null | undefined {
    const annotations = getAnnotations({ pid, sid });
    if (!annotations) {
        return null;
    }

    let annotationId: string | null | undefined = atid;
    if (!annotationId) {
        ({ annotationId } = fluxRuntime.AnnotationsStore.getState());
    }

    if (!annotationId) {
        return null;
    }

    return annotations.find(annotation => annotation._id === annotationId);
}

function getIsPositionInViewport({ x, y }: {
    x?: number;
    y?: number;
} = {}, snapshotWidth: number, snapshotHeight: number): boolean {
    if (x === undefined || y === undefined) {
        return false;
    }

    const HORIZONTAL_SCREEN_PADDING = 120;
    const VERTICAL_SCREEN_PADDING = 60;
    const absoluteX = x * snapshotWidth;
    const absoluteY = y * snapshotHeight;
    const { scrollLeft, scrollTop, zoomLevel, sidebarWidth } = fluxRuntime.InspectableViewStore.getState();
    const screenWindowWidth = window.innerWidth - sidebarWidth;
    const screenWindowHeight = window.innerHeight - HEADER_HEIGHT;

    const viewportTop = scrollTop;
    const viewportLeft = scrollLeft;
    const viewportBottom = scrollTop + screenWindowHeight;
    const viewportRight = scrollLeft + screenWindowWidth;
    const targetInViewportX = HORIZONTAL_SCREEN_PADDING + (absoluteX * zoomLevel);
    const targetInViewportY = VERTICAL_SCREEN_PADDING + (absoluteY * zoomLevel);

    return (
        targetInViewportY > viewportTop &&
        targetInViewportX > viewportLeft &&
        targetInViewportY < viewportBottom &&
        targetInViewportX < viewportRight
    );
}

function getUniqueAnnotationTypes({ pid, sid }: { pid: string; sid?: string; }): NoteType[] | null {
    const annotations = getAnnotations({ pid, sid });

    if (!annotations) {
        return null;
    }

    const annotationTypes: NoteType[] = annotations
        .filter(({ _id }) => !isDraftAnnotation(_id))
        .map(({ type }) => BarrelHelpers.findNoteType(pid, type));
    const noneExcludedTypes = annotationTypes.filter(type => type?.name !== NoteType.None.name);

    return uniquifyArrayOfObjects<NoteType>(noneExcludedTypes, "name");
}

function getAnnotationTypesToListInFilter({ pid, sid, filterId }: {
    pid: string;
    sid?: string;
    filterId?: string;
}): NoteType[] {
    const annotations = getAnnotations({ pid, sid });
    if (!annotations) {
        return [];
    }

    const { noteTypes = [] } = BarrelHelpers.getBarrel({ bid: pid }) || {};
    const noteTypesToList = noteTypes.filter(noteType =>
        annotations.some(annotation => annotation.type && annotation.type === noteType._id) ||
                        (filterId && filterId === noteType._id));
    return noteTypesToList;
}

function getComment({ pid, sid, did, cmid }: {
    pid?: string;
    sid?: string;
    did?: string;
    cmid?: string;
} = {}): DotComment | null {
    const dot = getDot({ pid, sid, did });

    return dot?.comments?.find(cm => cm._id === cmid) ?? null;
}

function navigateToScreen(sid: string) {
    const { barrelId: pid } = fluxRuntime.BarrelStore.getState();
    const { stageMode } = fluxRuntime.InspectableViewStore.getState();

    const route = stageMode ? "project-screen-stage-mode" : "project-screen";
    router.history.push(urlResolve(route, { url: { pid, sid } }));
}

// eslint-disable-next-line max-params
function getDotDetails(
    dots: Dot[],
    dotStatusFilter: CommentStatusFilter,
    dotColorFilter: ColorName | null,
    did: string | null,
    highlightedDots: string[]
) {
    let dotId = did;
    let statusFilter = dotStatusFilter;
    let colorFilter = dotColorFilter;

    if (dotId) {
        const selectedDot = dots.find(({ _id }) => _id === dotId);
        if (selectedDot) {
            [statusFilter, colorFilter] = [selectedDot.status as unknown as CommentStatusFilter, null];
        } else {
            dotId = null;
        }
    } else if (highlightedDots.length > 1) {
        const anyDotWithOpenStatus = dots
            .some(d => {
                const isDotOpen = (d.status as unknown as CommentStatusFilter) === CommentStatusFilter.Open;

                return isDotOpen && highlightedDots.includes(d._id);
            });

        if (anyDotWithOpenStatus && statusFilter !== CommentStatusFilter.Open) {
            [statusFilter, colorFilter] = [CommentStatusFilter.Open, null];
        } else if (!anyDotWithOpenStatus && statusFilter !== CommentStatusFilter.Resolved) {
            [statusFilter, colorFilter] = [CommentStatusFilter.Resolved, null];
        }
    }

    return {
        dotId,
        colorFilter,
        statusFilter
    };
}

function getAnnotationNoteType(annotations: Annotation[], annotationId: string | null, pid: string) {
    let noteType;
    if (annotationId) {
        const selectedAnnotation = annotations.find(annotation => annotation._id === annotationId);
        if (selectedAnnotation) {
            noteType = BarrelHelpers.findNoteType(pid, selectedAnnotation.type);
        }
    }

    return noteType;
}

function getScreenStats({ sid, pid } : {sid: string; pid?: string;}) {
    const currVariantGroup = BarrelHelpers.getVariantGroupOfScreen(sid);
    const screen = getScreen({ sid, pid });
    if (!screen) {
        return { screenCount: 0, variantGroupCount: 0, screenVariantCount: 0 };
    }

    if (currVariantGroup) {
        return { screenCount: 0, variantGroupCount: 1, screenVariantCount: currVariantGroup.screens.length };
    }

    return { screenCount: 1, variantGroupCount: 0, screenVariantCount: 0 };
}

function getScreenStatsForScreens({ sids }: {sids: string[];}) {
    let totalVariantGroupCount = 0;
    let totalScreenCount = 0;
    let totalScreenVariantCount = 0;

    sids.forEach(sid => {
        const { screenCount, variantGroupCount, screenVariantCount } = getScreenStats({ sid });
        totalVariantGroupCount += variantGroupCount;
        totalScreenCount += screenCount;
        totalScreenVariantCount += screenVariantCount;
    });
    return { totalVariantGroupCount, totalScreenCount, totalScreenVariantCount };
}

function getLayerBySourceId({ pid, sid, vid, sourceId }: {
    pid?: string;
    sid?: string;
    vid?: string;
    sourceId: string;
}): Layer | null {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;
    const versionId = vid ?? fluxRuntime.InspectableViewStore.getState().versionId;

    if (!projectId || !screenId || !versionId) {
        return null;
    }

    const { snapshot } = getVersion({ pid: projectId, sid: screenId, vid: versionId }) || {};

    if (!snapshot?.layers) {
        return null;
    }

    const snapshotLayers = getFlattenedLayersMemoized({
        layers: snapshot.layers
    });

    return snapshotLayers.find(layer => layer.sourceId === sourceId) ?? null;
}

function getParentComponentLayer(layer: InspectLayer | null): InspectLayer | null {
    if (!layer) {
        return null;
    }

    if (layer.componentId) {
        return layer;
    }

    return getParentComponentLayer(layer.parent);
}

function hasOverlappingDots({
    x,
    y
}: {
    x: number;
    y: number;
}) {
    const [dotsEl] = Array.from(document.getElementsByClassName("dots"));

    const absoluteX = (dotsEl.clientWidth * x) + dotsEl.getBoundingClientRect().left;
    const absoluteY = (dotsEl.clientHeight * y) + dotsEl.getBoundingClientRect().top;

    const dotEls = Array.from(document.getElementsByClassName("dot"))
        .concat(Array.from(document.getElementsByClassName("annotationWrapper")));

    for (let i = 0; i < dotEls.length; i++) {
        const dotRect = dotEls[i].getBoundingClientRect();

        if (
            (absoluteX > dotRect.left && absoluteX < dotRect.right) &&
            (absoluteY > dotRect.top && absoluteY < dotRect.bottom)
        ) {
            return true;
        }
    }

    return false;
}

export default {
    getScreens,
    getScreen,
    getVersion,
    getFilteredScreens,
    getPreviousScreen,
    getPreviousScreenWithComments,
    getNextScreenWithComments,
    getNextApprovalScreenId,
    getNextScreen,
    getSectionOfScreen,
    getComment,
    navigateToScreen,
    getDots,
    getDotsWithoutApprovals,
    getAllDotsOfVariantGroupOfScreen,
    getAnnotations,
    getDot,
    getAnnotation,
    getUniqueAnnotationTypes,
    getDotDetails,
    getAnnotationNoteType,
    getAnnotationTypesToListInFilter,
    getAbsolutePositionOfComponentAnnotationDot,
    getComponentAnnotationsOfScreen,
    getScreenStats,
    getScreenStatsForScreens,
    getLayerBySourceId,
    getIsPositionInViewport,
    getParentComponentLayer,
    hasOverlappingDots
};
