/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { FigmaSyncData } from "../../../foundation/api/model";
import { FigmaSyncErrorData, FigmaSyncRedirectData } from "../../../foundation/api/model/figmaLibrarySync/FigmaSyncData";
import { FIGMA_PLACEHOLDER_STYLEGUIDE_ID, FigmaSyncType } from "../../../foundation/model/FigmaLibrarySync";

import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { AllPayloads } from "../payloads";

import FigmaLibrarySyncActionTypes from "./FigmaLibrarySyncActionTypes";

interface State {
    displaySyncInProgressDialog: string | null;
    importStyleguideLibraryDialogType?: FigmaSyncType;
    syncData?: FigmaSyncData;
}

class FigmaLibrarySyncDataStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            syncData: undefined,
            displaySyncInProgressDialog: null,
            importStyleguideLibraryDialogType: undefined
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setFigmaSyncRedirectData(state: State, {
        figmaSyncRedirectData
    }: {
        figmaSyncRedirectData: FigmaSyncRedirectData;
    }): State {
        return {
            ...state,
            syncData: {
                ...(state.syncData || {}),
                figmaSyncRedirectData
            }
        };
    }

    setFigmaSyncErrorData(state: State, {
        figmaSyncErrorData
    }: {
        figmaSyncErrorData: FigmaSyncErrorData;
    }): State {
        return {
            ...state,
            syncData: {
                ...(state.syncData || {}),
                figmaSyncErrorData
            }
        };
    }

    resetFigmaSyncData(state: State): State {
        return {
            ...state,
            syncData: undefined,
            displaySyncInProgressDialog: null,
            importStyleguideLibraryDialogType: undefined
        };
    }

    resetFigmaSyncErrorData(state: State): State {
        const syncData = { ...state.syncData };
        if (syncData?.figmaSyncErrorData) {
            delete syncData.figmaSyncErrorData;
        }

        return {
            ...state,
            syncData
        };
    }

    closeImportProgressDialog(state: State) {
        return {
            ...state,
            displaySyncInProgressDialog: null,
            importStyleguideLibraryDialogType: undefined
        };
    }

    openImportProgressDialog(state: State, {
        stid
    }: {
        stid: string;
    }) {
        return {
            ...state,
            displaySyncInProgressDialog: stid,
            importStyleguideLibraryDialogType: undefined
        };
    }

    openImportStyleguideLibraryDialog(state: State, {
        importType
    }: {
        importType?: FigmaSyncType;
    }) {
        return {
            ...state,
            displaySyncInProgressDialog: null,
            importStyleguideLibraryDialogType: importType
        };
    }

    onUpdateStyleguideSyncFile(state: State, {
        stid
    }: {
        stid: string;
    }) {
        return {
            ...state,
            displaySyncInProgressDialog: stid,
            importStyleguideLibraryDialogType: undefined
        };
    }

    onStartStyleguideLibImportProgress(state: State, {
        updateKey
    }: {
        updateKey?: boolean;
    }) {
        if (updateKey) {
            return state;
        }

        return {
            ...state,
            displaySyncInProgressDialog: FIGMA_PLACEHOLDER_STYLEGUIDE_ID,
            importStyleguideLibraryDialogType: undefined
        };
    }
    // #endregion

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case FigmaLibrarySyncActionTypes.SET_FIGMA_SYNC_REDIRECT_DATA:
                return this.setFigmaSyncRedirectData(state, action);
            case FigmaLibrarySyncActionTypes.SET_FIGMA_SYNC_ERROR_DATA:
                return this.setFigmaSyncErrorData(state, action);
            case FigmaLibrarySyncActionTypes.RESET_FIGMA_SYNC_DATA:
                return this.resetFigmaSyncData(state);
            case FigmaLibrarySyncActionTypes.RESET_FIGMA_SYNC_ERROR_DATA:
                return this.resetFigmaSyncErrorData(state);
            case FigmaLibrarySyncActionTypes.CLOSE_SYNC_PROGRESS_DIALOG:
            case FigmaLibrarySyncActionTypes.REDIRECT_FROM_FIGMA_LIBRARY_SYNC_PROGRESS_DIALOG:
                return this.closeImportProgressDialog(state);
            case FigmaLibrarySyncActionTypes.OPEN_SYNC_PROGRESS_DIALOG:
                return this.openImportProgressDialog(state, action);
            case FigmaLibrarySyncActionTypes.OPEN_IMPORT_STYLEGUIDE_LIBRARY_DIALOG:
                return this.openImportStyleguideLibraryDialog(state, action);
            case FigmaLibrarySyncActionTypes.UPDATE_STYLEGUIDE_SYNC_FILE:
                return this.onUpdateStyleguideSyncFile(state, action);
            case FigmaLibrarySyncActionTypes.START_IMPORT_STYLEGUIDE_LIBRARY_PROGRESS:
                return this.onStartStyleguideLibImportProgress(state, action);
            case BarrelActionTypes.REMOVE:
            case BarrelActionTypes.ARCHIVE:
                return this.reset();

            default:
                return state;
        }
    }
}

export default FigmaLibrarySyncDataStore;
export { State as FigmaLibrarySyncDataStoreState };
