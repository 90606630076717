enum StorybookIntegrationActionTypes {
    RESET_CONNECT_ERROR_AND_FETCH_STATE = "storybookIntegration/resetConnectErrorAndFetchState",
    FETCH_STORIES = "storybookIntegration/fetchStories",
    FETCH_STORIES_SUCCESS = "storybookIntegration/fetchStoriesSuccess",
    FETCH_STORIES_FAILURE = "storybookIntegration/fetchStoriesFailure",
    CONNECT_STORYBOOK_REQUEST = "storybookIntegration/connectStorybookRequest",
    CONNECT_STORYBOOK_SUCCESS = "storybookIntegration/connectStorybookSuccess",
    CONNECT_STORYBOOK_FAILURE = "storybookIntegration/connectStorybookFailure",
    SET_STORIES = "storybookIntegration/setStories",
    UPDATE_URL = "storybookIntegration/updateUrl",
    UPDATE_STORY = "storybookIntegration/updateStory",
    DELETE_STORY = "storybookIntegration/deleteStory",
    UPDATE_SELECTED_STORY_PATH = "storybookIntegration/updateSelectedStoryPath",
    ADD_STORY_CONNECTED_COMPONENT = "storybookIntegration/addStoryConnectedComponent",
    REMOVE_STORY_CONNECTED_COMPONENT = "storybookIntegration/removeStoryConnectedComponent",
    UPDATE_FOR_UPDATE_CONNECTED_COMPONENTS = "storybookIntegration/updateForUpdateConnectedComponents"
}

export default StorybookIntegrationActionTypes;
