import { h, Component, Fragment } from "preact";

import { PLATFORM } from "../../../../../foundation/enums";
import NamingConvention from "../../../../../foundation/model/NamingConvention";
import { getSupportedDensityScales, getOptimizedAssetsSelectedNames } from "../../../../../foundation/utils/asset";
import { getDensityScaleRepresentation } from "../../../../../foundation/utils/legacy";

import DropdownMenu from "../../../../../library/v2/DropdownMenu";
import IconSettings from "../../../../../library/v2/Icons/IconSettings";
import Menu from "../../../../../library/v2/Menu";

import { Props } from "./AssetSettingsProps";

const NAMING_OPTIONS = [
    { content: "Default", value: NamingConvention.Default },
    { content: "camelCase", value: NamingConvention.CamelCase },
    { content: "snake_case", value: NamingConvention.SnakeCase },
    { content: "kebab-case", value: NamingConvention.KebabCase },
    { content: "Original", value: NamingConvention.Original }
];

const MIN_NUMBER_OF_ASSET_DENSITY_SCALE_PREFS = 1;

function densityScaleOptionVisible(platform: PLATFORM) {
    return platform !== PLATFORM.BASE;
}

function optimizedAssetsOptionVisible(platform: PLATFORM) {
    return platform !== PLATFORM.BASE;
}

function namingOptionVisible(platform: PLATFORM) {
    return platform !== PLATFORM.ANDROID;
}

class AssetSettings extends Component<Props> {
    get optimizedAssetsSelectedOptionValues() {
        const {
            showOptimizedSVG,
            showOptimizedPNG,
            showOptimizedJPG
        } = this.props;

        return getOptimizedAssetsSelectedNames({
            showOptimizedSVG,
            showOptimizedPNG,
            showOptimizedJPG
        });
    }

    handleDensityScaleOptionsChange = (value: string[]) => {
        this.props.onAssetDensityOptionChange("png", value.map(Number));
    };

    handleOptimizedAssetsOptionsChange = (value: string[]) => {
        const {
            onShowOptimizedSVGChange,
            onShowOptimizedPNGChange,
            onShowOptimizedJPGChange
        } = this.props;

        let [changedOption] = value.filter(item => !this.optimizedAssetsSelectedOptionValues.includes(item));
        let newValue = true;

        if (!changedOption) {
            [changedOption] = this.optimizedAssetsSelectedOptionValues.filter(item => !value.includes(item));
            newValue = false;
        }

        if (changedOption === "SVG") {
            onShowOptimizedSVGChange(newValue);
        } else if (changedOption === "PNG") {
            onShowOptimizedPNGChange(newValue);
        } else if (changedOption === "JPG") {
            onShowOptimizedJPGChange(newValue);
        }
    };

    handleNamingConventionChange = (value: string) => {
        this.props.onNamingConventionChange(value as NamingConvention);
    };

    render() {
        const {
            platform,
            assetDensityScalePrefs,
            namingConvention
        } = this.props;

        const settings = [];

        if (densityScaleOptionVisible(platform)) {
            const checkedDensityScaleValues = assetDensityScalePrefs ? assetDensityScalePrefs["png"] : [];

            settings.push(
                <Menu.MultiSelectSubmenu
                    content="Bitmap scales"
                    checkedOptionValues={checkedDensityScaleValues.map(String)}
                    minNumberOfCheckedOptions={MIN_NUMBER_OF_ASSET_DENSITY_SCALE_PREFS}
                    options={
                        getSupportedDensityScales(platform).map(densityScale => ({
                            value: String(densityScale),
                            content: getDensityScaleRepresentation(platform, densityScale)
                        }))
                    }
                    onChange={this.handleDensityScaleOptionsChange}/>
            );
        }

        if (namingOptionVisible(platform)) {
            settings.push(
                <Menu.SingleSelectSubmenu
                    content="Naming"
                    checkedValue={namingConvention}
                    options={NAMING_OPTIONS}
                    onChange={this.handleNamingConventionChange}/>
            );
        }

        if (optimizedAssetsOptionVisible(platform)) {
            settings.push(
                <Menu.MultiSelectSubmenu
                    content="Optimize assets"
                    checkedOptionValues={this.optimizedAssetsSelectedOptionValues}
                    options={[{
                        content: "PNG",
                        value: "PNG"
                    }, {
                        content: "JPG",
                        value: "JPG"
                    }, {
                        content: "SVG",
                        value: "SVG"
                    }]}
                    footer={(
                        <>
                            <Menu.Divider/>
                            <Menu.Link href="https://zpl.io/optimized-assets">About optimized assets</Menu.Link>
                        </>
                    )}
                    onChange={this.handleOptimizedAssetsOptionsChange}/>
            );
        }

        return (
            <DropdownMenu
                buttonProps={{
                    icon: IconSettings,
                    showChevron: false
                }}>
                {settings}
            </DropdownMenu>
        );
    }
}

export default AssetSettings;
export {
    namingOptionVisible
};
