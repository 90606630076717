/* eslint-disable class-methods-use-this */

import Dispatcher from "flux/lib/Dispatcher";
import ReduceStore from "flux/lib/FluxReduceStore";

import { Dot } from "../../../foundation/api/model";
import CreateDotParameters from "../../../foundation/api/model/dots/CreateDotParameters";
import DraftDot from "../../../foundation/model/DraftDot";
import RemarkType from "../../../foundation/model/RemarkType";
import * as ActivitiesActionPayloads from "../activities/ActivitiesActionPayloads";
import ActivitiesActionTypes from "../activities/ActivitiesActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as DotCommentsActionPayloads from "../dotComments/DotCommentsActionPayloads";
import DotCommentsActionTypes from "../dotComments/DotCommentsActionTypes";
import DotsAndAnnotationsReducer from "../dotsAndAnnotationsReducer/DotsAndAnnotationsReducer";
import * as NotificationsActionPayloads from "../notifications/NotificationsActionPayloads";
import NotificationsActionTypes from "../notifications/NotificationsActionTypes";
import * as ProjectActionPayloads from "../project/ProjectActionPayloads";
import ProjectActionTypes from "../project/ProjectActionTypes";

import { AllPayloads } from "../payloads";

import * as ScreenActionPayloads from "./ScreenActionPayloads";
import ScreenActionTypes from "./ScreenActionTypes";

type State = {
    flash: boolean;
    dots: {
        [pid: string]: {
            [sid: string]: Dot[];
        };
    };
};

type DotType = Dot | DraftDot | (CreateDotParameters & { _id: string; });

class DotsDataStore extends ReduceStore<State, AllPayloads> {
    reducer: DotsAndAnnotationsReducer<State, DotType, "dots">;

    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
        this.reducer = new DotsAndAnnotationsReducer({ propertyName: "dots" });
    }

    getInitialState(): State {
        return {
            flash: false,
            dots: {}
        };
    }

    dotsFlashed(state: State): State {
        return {
            ...state,
            flash: false
        };
    }

    setProjectDots(state: State, {
        pid,
        dotsMap
    }: ProjectActionPayloads.SetProjectDots): State {
        return this.reducer.setProjectDotsAndAnnotations(
            state,
            { pid, data: dotsMap }
        );
    }

    setDots(state: State, {
        pid,
        sid,
        dots
    }: ScreenActionPayloads.SetDots): State {
        return this.reducer.setDotsAndAnnotations(
            state,
            { pid, sid, data: dots }
        );
    }

    setDotsForIntermediateLoading(state: State, {
        dotsDetails: {
            pid,
            sid,
            dots
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        return this.reducer.setDotsAndAnnotations(
            state,
            { pid, sid, data: dots }
        );
    }

    createNote(
        state: State,
        { pid, sid, dot }: { pid: string; sid: string; dot: DotType; }
    ) {
        return this.reducer.insertDotAndAnnotation(
            state,
            { pid, sid, data: dot }
        );
    }

    updateDot(state: State, {
        pid,
        sid,
        did: oldDid,
        dotData
    }: ScreenActionPayloads.UpdateDot): State {
        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: oldDid, data: dotData }
        );
    }

    updateDotPosition(state: State, {
        pid,
        sid,
        did,
        x,
        y
    }: ScreenActionPayloads.UpdateDotPosition): State {
        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: did, data: { x, y } }
        );
    }

    updateDotStatus(state: State, {
        pid,
        sid,
        did: dotId,
        status
    }: ScreenActionPayloads.UpdateDotStatus): State {
        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: dotId, data: { status } }
        );
    }

    updateDotColor(
        state: State,
        { pid, sid, did, color }: ScreenActionPayloads.UpdateDotColor
    ): State {
        return this.reducer.updateDotAndAnnotation(
            state,
            { pid, sid, daid: did, data: { color } }
        );
    }

    addDot(
        state: State,
        { pid, sid, dot }: { pid: string; sid: string; dot: DotType; }
    ) {
        return this.reducer.insertDotAndAnnotation(
            state,
            { pid, sid, data: dot }
        );
    }

    createComment(state: State, {
        pid,
        sid,
        did,
        comment
    }: ScreenActionPayloads.CreateComment): State {
        return this.reducer.insertDotComment(
            state,
            { pid, sid, daid: did, comment }
        );
    }

    addComment(state: State, {
        pid,
        sid,
        did,
        comment
    }: ScreenActionPayloads.AddComment): State {
        return this.reducer.insertDotComment(
            state,
            { pid, sid, daid: did, comment }
        );
    }

    updateComment(state: State, {
        pid,
        sid,
        did,
        cmid,
        commentData
    }: ScreenActionPayloads.UpdateComment): State {
        return this.reducer.updateDotComment(state, {
            pid, sid, daid: did, cmid, commentData
        });
    }

    updateCommentReadStatuses(state: State, {
        parameters
    }: DotCommentsActionPayloads.UpdateDotCommentReadStatus) {
        return this.reducer.updateCommentReadStatuses(state, {
            ...parameters
        });
    }

    updateDotReadStatusFromNotifications(state: State, {
        notification,
        eventIds,
        markedAsRead
    }: NotificationsActionPayloads.SetEventReadStatus) {
        return this.reducer.updateNotificationCommentReadStatuses(state, {
            notification,
            eventIds,
            markedAsRead
        });
    }

    updateDotReadStatusFromActivities(state: State, {
        activity,
        eventIds
    }: ActivitiesActionPayloads.Select) {
        return this.reducer.updateNotificationCommentReadStatuses(state, {
            notification: activity,
            eventIds,
            markedAsRead: true
        });
    }

    removeComment(state: State, {
        pid,
        sid,
        did,
        cmid
    }: ScreenActionPayloads.RemoveComment): State {
        return this.reducer.removeDotComment(state, {
            pid, sid, daid: did, cmid
        });
    }

    removeDot(state: State, {
        pid,
        sid,
        did
    }: ScreenActionPayloads.RemoveDot): State {
        return this.reducer.removeDotAndAnnotation(
            state,
            { pid, sid, daid: did }
        );
    }

    updateDotPopupRemarkType(state: State, {
        pid,
        sid,
        did,
        remark,
        remarkType
    }: ScreenActionPayloads.UpdateDotPopupRemarkType): State {
        if (remarkType === RemarkType.Dot) {
            return this.createNote(state, { pid, sid, dot: (remark as DraftDot) });
        }

        return this.reducer.removeDotAndAnnotation(
            state,
            { pid, sid, daid: did! }
        );
    }

    updateUserData(state: State, {
        bid,
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        const projectDots = state.dots[bid];

        // eslint-disable-next-line guard-for-in
        for (const screenId in projectDots) {
            const screenDots: Dot[] = projectDots[screenId];

            if (screenDots && screenDots.length) {
                const newScreenDots: Dot[] = screenDots.map(dot => {
                    const comments = dot.comments.map(comment => {
                        if (comment.author && comment.author._id === uid) {
                            return { ...comment, author: { ...comment.author, ...userData } };
                        }

                        return comment;
                    });

                    if (dot.creator && dot.creator._id === uid) {
                        return { ...dot, comments, creator: { ...dot.creator, ...userData } };
                    }

                    return { ...dot, comments };
                });

                return this.reducer.setDotsAndAnnotations(
                    state,
                    { pid: bid, sid: screenId, data: newScreenDots }
                );
            }
        }

        return state;
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // Screen action reducers
            case ScreenActionTypes.SET_DOTS:
                return this.setDots(state, action);

            case ScreenActionTypes.CREATE_NOTE:
                return this.createNote(state, action);

            case ScreenActionTypes.UPDATE_DOT:
                return this.updateDot(state, action);

            case ScreenActionTypes.UPDATE_DOT_STATUS:
                return this.updateDotStatus(state, action);

            case ScreenActionTypes.UPDATE_DOT_POSITION:
                return this.updateDotPosition(state, action);

            case ScreenActionTypes.UPDATE_DOT_COLOR:
                return this.updateDotColor(state, action);

            case ScreenActionTypes.ADD_DOT:
            case ScreenActionTypes.ADD_APPROVAL_PENDING_DOT:
                return this.addDot(state, action);

            case ScreenActionTypes.CREATE_COMMENT:
                return this.createComment(state, action);

            case ScreenActionTypes.ADD_COMMENT:
                return this.addComment(state, action);

            case ScreenActionTypes.UPDATE_COMMENT:
                return this.updateComment(state, action);

            case ScreenActionTypes.REMOVE_COMMENT:
                return this.removeComment(state, action);

            case ScreenActionTypes.REMOVE_DOT:
                return this.removeDot(state, action);

            case ScreenActionTypes.DOTS_FLASHED:
                return this.dotsFlashed(state);

            case ScreenActionTypes.UPDATE_DOTPOPUP_REMARK_TYPE:
                return this.updateDotPopupRemarkType(state, action);

            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateUserData(state, action);

            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.setDotsForIntermediateLoading(state, action);

            // Project action reducers
            case ProjectActionTypes.SET_PROJECT_DOTS:
                return this.setProjectDots(state, action);

            // Dot status
            case DotCommentsActionTypes.UpdateDotCommentReadStatus:
                return this.updateCommentReadStatuses(state, action);

            case NotificationsActionTypes.SetEventReadStatus:
                return this.updateDotReadStatusFromNotifications(state, action);

            case ActivitiesActionTypes.Select:
                return this.updateDotReadStatusFromActivities(state, action);

            default:
                return state;
        }
    }
}

export default DotsDataStore;
export { State as DotsDataStoreState };
