/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { AllPayloads } from "../payloads";

import { SetJiraIntegrationInfo, UpdateJiraDeploymentUrl } from "./JiraIntegrationActionPayloads";
import JiraIntegrationActionTypes from "./JiraIntegrationActionTypes";

interface State {
    userHasJiraToken: boolean;
    deploymentUrl: string;
    workspaceName?: string;
    loading: boolean;
}

class JiraIntegrationStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            userHasJiraToken: false,
            deploymentUrl: "",
            loading: true
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    getJiraIntegrationInfoFinished(state: State): State {
        return {
            ...state,
            loading: false
        };
    }

    setJiraIntegrationInfo(state: State, action: SetJiraIntegrationInfo): State {
        const {
            deploymentUrl,
            workspaceName,
            userHasJiraToken
        } = action;
        return {
            ...state,
            deploymentUrl,
            workspaceName,
            userHasJiraToken,
            loading: false
        };
    }

    updateJiraDeploymentUrl(state: State, action: UpdateJiraDeploymentUrl): State {
        const {
            oldDeploymentUrl,
            newDeploymentUrl,
            workspaceName
        } = action;
        if (state.deploymentUrl === oldDeploymentUrl) {
            return {
                ...state,
                deploymentUrl: newDeploymentUrl,
                workspaceName
            };
        }

        return state;
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.RESET:
                return this.reset();
            case JiraIntegrationActionTypes.GET_JIRA_INTEGRATION_INFO_FINISHED:
                return this.getJiraIntegrationInfoFinished(state);
            case JiraIntegrationActionTypes.SET_JIRA_INTEGRATION_INFO:
                return this.setJiraIntegrationInfo(state, action);
            case JiraIntegrationActionTypes.UPDATE_JIRA_DEPLOYMENT_URL:
                return this.updateJiraDeploymentUrl(state, action);

            default:
                return state;
        }
    }
}

export default JiraIntegrationStore;
export {
    State as JiraIntegrationStoreState
};
