/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import DotState from "../../../foundation/api/model/dots/DotState";

import GetDotStatesParameters from "../../../foundation/api/model/dots/GetDotStatesParameters";
import { union, difference } from "../../../foundation/utils/array";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { isAllPropertiesFilled, deleteEmptyProperties } from "../../../foundation/utils/object";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./DotStatesActionPayloads";
import DotStatesActionTypes from "./DotStatesActionTypes";

interface State {
    loadingDotIds: string[];
    errorDotIds: string[];
    dots: BasicRecord<DotState>;
}

const helpers = {
    getIdsFromParameters(parameters: GetDotStatesParameters): string[] {
        return parameters.projects.flatMap(({ screens }) => screens).flatMap(({ dots }) => dots);
    },

    /**
     * Updates dot state if already present,
     * otherwise adds new dot state if given dot state is full (i.e contains name, color and status)
     */
    updateDotState(state: State, oldDid: string, rawDotState: Partial<DotState>): State {
        const { _id: newId, name, color, status } = rawDotState;
        const _id = newId ?? oldDid;
        const dotState: Partial<DotState> = { _id, status, name, color };
        const dotStateComplete = isAllPropertiesFilled(dotState);
        if (!dotStateComplete && !state.dots[oldDid]) {
            return state;
        }

        deleteEmptyProperties(dotState);
        const { [oldDid]: oldDotState, ...dots } = state.dots;
        dots[_id] = { ...oldDotState, ...dotState };

        const errorDotIds = state.errorDotIds.filter(id => id !== _id);

        return {
            ...state,
            errorDotIds,
            dots
        };
    }
};

class DotStatesStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            loadingDotIds: [],
            errorDotIds: [],
            dots: {}
        };
    }

    requestDotStates(state: State, {
        parameters
    }: Payloads.RequestDotStates): State {
        const dotIdsToLoad = helpers.getIdsFromParameters(parameters);
        const loadingDotIds = union(state.loadingDotIds, dotIdsToLoad);
        const errorDotIds = difference(state.errorDotIds, dotIdsToLoad);

        return {
            ...state,
            errorDotIds,
            loadingDotIds
        };
    }

    addDotStates(state: State, {
        parameters,
        response
    }: Payloads.AddDotStates): State {
        const requestedDotIds = helpers.getIdsFromParameters(parameters);
        const loadedDotStates = response.projects.flatMap(({ screens }) => screens).flatMap(({ dots }) => dots);
        const loadedDotIds = loadedDotStates.map(({ _id }) => _id);
        const newErrorDotIds = difference(requestedDotIds, loadedDotIds);
        const loadingDotIds = difference(state.loadingDotIds, requestedDotIds);
        const errorDotIds = union(state.errorDotIds, newErrorDotIds);

        return {
            ...state,
            loadingDotIds,
            errorDotIds,
            dots: {
                ...state.dots,
                ...Object.fromEntries(loadedDotStates.map(dotState => [dotState._id, dotState]))
            }
        };
    }

    dotStatesError(state: State, {
        parameters
    }: Payloads.DotStatesError): State {
        const errorForDotIds = helpers.getIdsFromParameters(parameters);
        const loadingDotIds = difference(state.loadingDotIds, errorForDotIds);

        return {
            ...state,
            loadingDotIds
        };
    }

    addDotState(state: State, {
        dot
    }: ScreenActionPayloads.AddDot | ScreenActionPayloads.AddApprovalPendingDot): State {
        return helpers.updateDotState(state, dot._id, dot);
    }

    updateDotState(state: State, {
        did,
        dotData
    }: ScreenActionPayloads.UpdateDot): State {
        return helpers.updateDotState(state, did, dotData);
    }

    updateDotColor(state: State, {
        did,
        color
    }: ScreenActionPayloads.UpdateDotColor): State {
        return helpers.updateDotState(state, did, { color });
    }

    updateDotStatus(state: State, {
        did,
        status
    }: ScreenActionPayloads.UpdateDotStatus): State {
        return helpers.updateDotState(state, did, { status });
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case DotStatesActionTypes.RequestDotStates:
                return this.requestDotStates(state, action);
            case DotStatesActionTypes.AddDotStates:
                return this.addDotStates(state, action);
            case DotStatesActionTypes.DotStatesError:
                return this.dotStatesError(state, action);
            case ScreenActionTypes.ADD_DOT:
            case ScreenActionTypes.ADD_APPROVAL_PENDING_DOT:
                return this.addDotState(state, action);
            case ScreenActionTypes.UPDATE_DOT:
                return this.updateDotState(state, action);
            case ScreenActionTypes.UPDATE_DOT_COLOR:
                return this.updateDotColor(state, action);
            case ScreenActionTypes.UPDATE_DOT_STATUS:
                return this.updateDotStatus(state, action);
            default:
                return state;
        }
    }
}

export default DotStatesStore;
export {
    State as DotStatesStoreState
};
