/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { ProjectViewTypes, TagGroupTypes } from "../../../foundation/enums";
import ComponentsFilter from "../../../foundation/model/ComponentsFilter";
import InspectLayer from "../../../foundation/model/InspectLayer";
import RemarkType from "../../../foundation/model/RemarkType";
import SourceFile from "../../../foundation/model/SourceFile";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { pipe } from "../../../foundation/utils/legacy";
import { clamp } from "../../../foundation/utils/math";
import { isMobileViewport } from "../../../foundation/utils/ua-utils";
import { PartializeProps } from "../../../foundation/utils/UtilityTypes";
import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import * as ComponentActionPayloads from "../component/ComponentActionPayloads";
import ComponentActionTypes from "../component/ComponentActionTypes";
import * as DashboardActionPayloads from "../dashboard/DashboardActionPayloads";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import ComponentVariantActionTypes from "../screen/componentVariant/ComponentVariantActionTypes";
import * as ScreenActionPayloads from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";

import ScreenOnboardingStep from "../screen/ScreenOnboardingStep";
import { ZOOM_LEVEL_DEFAULT, ZOOM_LEVEL_MIN, ZOOM_LEVEL_MAX, ZOOM_LEVELS } from "../screen/zoomLevels";

import { AllPayloads } from "../payloads";

import * as Payloads from "./InspectableViewActionPayloads";
import InspectableViewActionTypes from "./InspectableViewActionTypes";

import ViewTypes from "./ViewTypes";

const WHEEL_ZOOM_DIVIDER = 100;
const SIDEBAR_INITIAL_WIDTH = 260;

const VERSIONS_SIDEBAR_INITIAL_WIDTH = 260;

interface State {
    loadingSnapshot: boolean;
    processingSnapshotTiles: boolean | undefined;
    inspectableId: string | null;
    approvalsId?: string | null;
    approvalReviewerId: string | null | undefined;
    percentageMode: boolean;
    selectedLayer: InspectLayer | null;
    destinationLayer: InspectLayer | null;
    highlightedLayer: InspectLayer | null;
    notesVisible: boolean;
    noteMode: boolean;
    tagManagerScreenIds: string[];
    tagManagerType?: TagGroupTypes | null;
    tagManagerSource?: string | null;
    gridLayoutEnabled: boolean;
    gridWidgetEnabled: boolean;
    scrollTop: number;
    scrollLeft: number;
    zoomLevel: number;
    zoomLevelAtGestureStart: number | null;
    animateZooming: boolean;
    zooming: boolean;
    flowLinksVisible: boolean;
    sidebarWidth: number;
    sidebarCollapsed: boolean;
    inspectableSidebarView: ViewTypes;
    sourceFiles: BasicRecord<SourceFile>;
    componentVariantsOnboardingHintboxSeen: boolean;
    imageProcessingHintboxSeen: boolean;
    componentVariantViewSeen: boolean;
    numberOfLayerComponentViews: number;
    componentsFilter: ComponentsFilter;
    screenOnboardingStep: ScreenOnboardingStep;
    screenOnboardingInfoVisible: boolean;
    screenOnboardingFlowHintboxHidden: boolean;
    stageModeFirstTimeLandingHidden: boolean;
    stageMode: boolean;
    approvalsMode: boolean;
    stageSidebarOpen: boolean;
    stageModeHistoryCount: number;
    flashStageModeHotspots: boolean;
    movingRemarkRect?: DOMRect;
    zoomToSelectedLayer: boolean;
    isLayoutPaddingVisible: boolean;
    isLayoutGapVisible: boolean;

    // Versions state
    loadingVersions: boolean;
    versionId: string | null;
    latestVersionSelected: boolean;
    versionsVisible: boolean;
    versionsSidebarWidth: number;
    loadingVersionDiffs: boolean;
    versionsDiffVisible: boolean;
    noDiffs: boolean;
    tooManyDiffs: boolean;
    showVersionDiffAnnouncementPopup: boolean;

    dotPopupRemarkType: RemarkType;
    activeRemarkTabType: RemarkType;
}

class InspectableViewStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            loadingSnapshot: true,
            processingSnapshotTiles: true,
            inspectableId: null,
            approvalsId: null,
            approvalReviewerId: null,
            percentageMode: false,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null,
            notesVisible: true,
            noteMode: false,
            tagManagerScreenIds: [],
            tagManagerType: null,
            tagManagerSource: null,
            gridLayoutEnabled: false,
            gridWidgetEnabled: false,
            scrollTop: 0,
            scrollLeft: 0,
            zoomLevel: ZOOM_LEVEL_DEFAULT,
            zoomLevelAtGestureStart: null,
            animateZooming: false,
            zooming: false,
            flowLinksVisible: false,
            sidebarWidth: SIDEBAR_INITIAL_WIDTH,
            sidebarCollapsed: isMobileViewport(),
            inspectableSidebarView: ViewTypes.info,
            sourceFiles: {},
            componentVariantsOnboardingHintboxSeen: false,
            imageProcessingHintboxSeen: false,
            componentVariantViewSeen: false,
            numberOfLayerComponentViews: 0,
            componentsFilter: ComponentsFilter.Hide,
            screenOnboardingStep: null,
            screenOnboardingInfoVisible: true,
            screenOnboardingFlowHintboxHidden: false,
            stageModeFirstTimeLandingHidden: false,
            stageMode: false,
            approvalsMode: false,
            stageSidebarOpen: false,
            stageModeHistoryCount: 0,
            flashStageModeHotspots: false,
            movingRemarkRect: undefined,
            zoomToSelectedLayer: false,
            isLayoutPaddingVisible: false,
            isLayoutGapVisible: false,

            // Versions state
            loadingVersions: true,
            versionId: null,
            latestVersionSelected: false,
            versionsVisible: false,
            versionsSidebarWidth: VERSIONS_SIDEBAR_INITIAL_WIDTH,
            versionsDiffVisible: false,
            loadingVersionDiffs: false,
            noDiffs: false,
            tooManyDiffs: false,
            showVersionDiffAnnouncementPopup: false,

            dotPopupRemarkType: RemarkType.Dot,
            activeRemarkTabType: RemarkType.Annotation
        };
    }

    reset(action?: BarrelActionPayloads.Reset): State {
        const { screen = {} } = action || {};

        return {
            ...this.getInitialState(),
            ...screen
        };
    }

    load(state: State, {
        screen: update = {}
    }: PartializeProps<BarrelActionPayloads.Load, "screen">): State {
        return {
            ...state,
            ...update,
            selectedLayer: state.selectedLayer,
            dotPopupRemarkType: update.lastRemarkTypeUsed ?? RemarkType.Dot
        };
    }

    loadIntermediate(state: State, {
        barrelData: {
            screen
        },
        openScreenData: {
            shouldResetDashboardFilters: _shouldResetDashboardFilters,
            barrelId: _barrelId,
            dotId: _dotId,
            commentId: _commentId,
            annotationId: _annotationId,
            screenId: inspectableId,
            ...update
        }
    }: BarrelActionPayloads.LoadIntermediate): State {
        return {
            ...state,
            ...screen,
            ...update,
            inspectableId,
            selectedLayer: null,
            destinationLayer: null,
            noteMode: false,
            dotPopupRemarkType: screen?.lastRemarkTypeUsed ?? RemarkType.Dot
        };
    }

    openScreen(
        state: State,
        {
            shouldResetDashboardFilters: _shouldResetDashboardFilters,
            barrelId: _barrelId,
            dotId: _dotId,
            commentId: _commentId,
            annotationId: _annotationId,
            screenId: inspectableId,
            ...update
        }: ScreenActionPayloads.OpenScreen
    ): State {
        // See ScreenActions.openScreen to see possible
        // properties that can be sent in the update
        return {
            ...state,
            ...update,
            inspectableId,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null,
            inspectableSidebarView: ViewTypes.info,
            noteMode: false,
            noDiffs: false,
            tooManyDiffs: false,
            approvalsMode: !!update.approvalsId
        };
    }

    openComponent(state: State, {
        coid,
        versionId,
        ...update
    }: ComponentActionPayloads.OpenComponent) {
        return {
            ...state,
            ...update,
            inspectableId: coid,
            versionId,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null,
            noteMode: false,
            versionsVisible: true
        };
    }

    closeScreen(state: State): State {
        // Reset everything except the properties
        // notesVisible, zoomLevel, gridLayoutEnabled, sidebarWidth,
        // versionsSidebarWidth
        // which are commonly used amongst different screens

        return {
            ...state,
            loadingSnapshot: true,
            processingSnapshotTiles: true,
            inspectableId: null,
            percentageMode: false,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null,
            noteMode: false,
            tagManagerScreenIds: [],
            tagManagerType: null,
            tagManagerSource: null,
            gridWidgetEnabled: false,
            scrollTop: 0,
            scrollLeft: 0,
            zoomLevelAtGestureStart: null,
            animateZooming: false,
            flowLinksVisible: false,
            inspectableSidebarView: ViewTypes.info,
            componentsFilter: ComponentsFilter.Hide,

            loadingVersions: true,
            versionId: null,
            latestVersionSelected: false,
            versionsVisible: false,
            noDiffs: false,
            tooManyDiffs: false
        };
    }

    // #region Zoom
    changeZoomLevel(state: State, newZoomLevel: number, animateZooming = false): State {
        const zoomLevel = clamp(newZoomLevel, ZOOM_LEVEL_MIN, ZOOM_LEVEL_MAX);

        if (state.stageMode) {
            return state;
        }

        return {
            ...state,
            zoomLevel,
            animateZooming
        };
    }

    zoomIn(state: State): State {
        const zoomLevel = ZOOM_LEVELS.find(level => level > state.zoomLevel) ?? ZOOM_LEVEL_MAX;

        return this.changeZoomLevel(state, zoomLevel, true);
    }

    zoomOut(state: State): State {
        const zoomLevel = [...ZOOM_LEVELS].reverse().find(level => level < state.zoomLevel) ?? ZOOM_LEVEL_MIN;

        return this.changeZoomLevel(state, zoomLevel, true);
    }

    resetZoom(state: State): State {
        return this.changeZoomLevel(state, ZOOM_LEVEL_DEFAULT, true);
    }

    zoomApplied(state: State): State {
        if (!state.animateZooming) {
            return state;
        }

        return {
            ...state,
            animateZooming: false
        };
    }

    startGesture(state: State): State {
        return {
            ...state,
            zoomLevelAtGestureStart: state.zoomLevel
        };
    }

    zoomWithGesture(state: State, {
        scale
    }: Payloads.ZoomWithGesture): State {
        // TODO-performance: Safari uses gestures while zoom in/out. Pausing renders while 'zooming' broke the zoom interaction with gestures.
        // Try to find a way to make it work so that Safari will also has a performant zooming experience.
        return this.changeZoomLevel(state, state.zoomLevelAtGestureStart! * scale);
    }

    zoomWithWheel(state: State, {
        deltaY
    }: Payloads.ZoomWithWheel): State {
        const updatedState = this.changeZoomLevel(state, state.zoomLevel - deltaY / WHEEL_ZOOM_DIVIDER);

        return {
            ...updatedState,
            zooming: true
        };
    }

    zoomEnd(state: State): State {
        return {
            ...state,
            zooming: false
        };
    }
    // #endregion

    changeScrollPosition(state: State, {
        scrollTop,
        scrollLeft
    }: Payloads.ChangeScrollPosition): State {
        return {
            ...state,
            scrollTop,
            scrollLeft
        };
    }

    openTagManager(state: State, {
        sids: tagManagerScreenIds,
        tagManagerType,
        source: tagManagerSource
    }: ScreenActionPayloads.OpenTagManager): State {
        return {
            ...state,
            tagManagerScreenIds,
            tagManagerType,
            tagManagerSource
        };
    }

    closeTagManager(state: State): State {
        return {
            ...state,
            tagManagerScreenIds: [],
            tagManagerType: null,
            tagManagerSource: null
        };
    }

    // #region Layout & Widget Grids
    toggleGridLayout(state: State): State {
        return {
            ...state,
            gridLayoutEnabled: !state.gridLayoutEnabled
        };
    }
    toggleGridWidget(state: State): State {
        return {
            ...state,
            gridWidgetEnabled: !state.gridWidgetEnabled
        };
    }
    // #endregion

    // #region Notes
    enableNoteMode(state: State, {
        dotPopupRemarkType
    }: ScreenActionPayloads.EnableNoteMode): State {
        return {
            ...state,
            notesVisible: true,
            noteMode: true,
            dotPopupRemarkType: dotPopupRemarkType ?? state.dotPopupRemarkType
        };
    }

    disableNoteMode(state: State): State {
        return {
            ...state,
            noteMode: false
        };
    }

    showNotes(state: State): State {
        return {
            ...state,
            notesVisible: true,
            noteMode: false
        };
    }

    hideNotes(state: State): State {
        return {
            ...state,
            notesVisible: false,
            noteMode: false
        };
    }
    // #endregion

    // #region Layers
    selectLayer(state: State, {
        layer,
        shouldIncrementNumberOfLayerComponentViews,
        shouldCompleteLayerOnboarding
    }: Payloads.SelectLayer): State {
        const updatedState = shouldCompleteLayerOnboarding ? this.resetScreenOnboardingStep(state) : state;

        const numberOfLayerComponentViews = updatedState.numberOfLayerComponentViews +
            (shouldIncrementNumberOfLayerComponentViews ? 1 : 0);

        return {
            ...updatedState,
            selectedLayer: layer,
            destinationLayer: layer,
            numberOfLayerComponentViews
        };
    }

    deselectLayer(state: State): State {
        return {
            ...state,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null
        };
    }

    enterLayer(state: State, {
        layer: destinationLayer
    }: Payloads.EnterLayer): State {
        return {
            ...state,
            destinationLayer
        };
    }

    leaveLayer(state: State): State {
        return {
            ...state,
            destinationLayer: null
        };
    }

    highlightLayer(state: State, {
        layer: highlightedLayer
    }: Payloads.HighlightLayer): State {
        return {
            ...state,
            highlightedLayer
        };
    }

    unhighlightLayer(state: State): State {
        return {
            ...state,
            highlightedLayer: null
        };
    }

    changeLayoutPaddingVisibility(
        state: State,
        { isLayoutPaddingVisible }: Payloads.ChangeLayoutPaddingVisibility
    ): State {
        if (state.isLayoutPaddingVisible === isLayoutPaddingVisible) {
            return state;
        }

        return {
            ...state,
            isLayoutPaddingVisible
        };
    }

    changeLayoutGapVisibility(state: State, { isLayoutGapVisible }: Payloads.ChangeLayoutGapVisibility): State {
        if (state.isLayoutGapVisible === isLayoutGapVisible) {
            return state;
        }

        return {
            ...state,
            isLayoutGapVisible
        };
    }

    enablePercentageMode(state: State): State {
        return {
            ...state,
            percentageMode: true
        };
    }

    disablePercentageMode(state: State): State {
        return {
            ...state,
            percentageMode: false
        };
    }

    setZoomToSelectedLayer(state: State, {
        zoomToSelectedLayer
    }: Payloads.SetZoomToSelectedLayer): State {
        return {
            ...state,
            zoomToSelectedLayer
        };
    }
    // #endregion

    // #region Flow Links
    showFlowLinks(state: State): State {
        return {
            ...state,
            flowLinksVisible: true
        };
    }

    hideFlowLinks(state: State): State {
        return {
            ...state,
            flowLinksVisible: false
        };
    }
    // #endregion

    changeSidebarView(state: State, {
        viewType
    }: Payloads.ChangeSidebarView): State {
        return {
            ...state,
            inspectableSidebarView: viewType
        };
    }

    changeSidebarWidth(state: State, {
        sidebarWidth
    }: Payloads.ChangeSidebarWidth): State {
        return {
            ...state,
            sidebarWidth
        };
    }

    changeSidebarCollapsed(state: State, sidebarCollapsed: boolean): State {
        return {
            ...state,
            sidebarCollapsed
        };
    }

    setScreenSnapshot(state: State, {
        sid,
        snapshot
    }: ScreenActionPayloads.SetSnapshot): State {
        if (sid !== state.inspectableId) {
            return state;
        }

        return {
            ...state,
            loadingSnapshot: false,
            processingSnapshotTiles: snapshot.tileInfo && snapshot.tileInfo.status === "processing"
        };
    }

    setComponentSnapshot(state: State, {
        coid,
        snapshot
    }: BarrelActionPayloads.SetComponentSnapshot): State {
        if (coid !== state.inspectableId || !snapshot) {
            return state;
        }

        return {
            ...state,
            loadingSnapshot: false,
            processingSnapshotTiles: false
        };
    }

    addScreenTiles(state: State, {
        sid
    }: ScreenActionPayloads.AddScreenTiles): State {
        if (sid !== state.inspectableId) {
            return state;
        }

        return {
            ...state,
            processingSnapshotTiles: false
        };
    }

    // Mark: Versions reducers
    setVersionId(state: State, {
        versionId,
        latestVersionSelected,
        shouldCompleteVersionsOnboarding
    }: Payloads.SetVersionId): State {
        const updatedState = shouldCompleteVersionsOnboarding ? this.resetScreenOnboardingStep(state) : state;

        return {
            ...updatedState,
            versionId,
            latestVersionSelected,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null,
            noDiffs: false,
            tooManyDiffs: false
        };
    }

    setVersions(state: State, {
        sid,
        versionId
    }: ScreenActionPayloads.SetVersions): State {
        if (sid !== state.inspectableId) {
            return state;
        }

        return {
            ...state,
            versionId,
            loadingVersions: false
        };
    }

    setComponentVersions(state: State, {
        coid,
        versionId
    }: ComponentActionPayloads.SetComponentVersions): State {
        if (coid !== state.inspectableId) {
            return state;
        }

        return {
            ...state,
            versionId,
            loadingVersions: false
        };
    }

    addVersion(state: State, {
        sid,
        versionId,
        latestVersionSelected
    }: ScreenActionPayloads.AddVersion): State {
        if (sid !== state.inspectableId) {
            return state;
        }

        return {
            ...state,
            versionId,
            latestVersionSelected,
            loadingSnapshot: true,
            processingSnapshotTiles: false
        };
    }

    showVersions(state: State, {
        loadingVersions
    }: Payloads.ShowVersions | Payloads.ToggleVersions): State {
        return {
            ...state,
            loadingVersions,
            versionsVisible: true
        };
    }

    hideVersions(state: State): State {
        return {
            ...state,
            versionsVisible: false
        };
    }

    toggleVersions(state: State, action: Payloads.ToggleVersions): State {
        return state.versionsVisible
            ? this.hideVersions(state)
            : this.showVersions(state, action);
    }

    changeVersionsSidebarWidth(state: State, {
        versionsSidebarWidth
    }: Payloads.ChangeVersionsSidebarWidth): State {
        return {
            ...state,
            versionsSidebarWidth
        };
    }

    removeVersion(state: State, {
        sid,
        versionId,
        removingCurrentVersion
    }: ScreenActionPayloads.RemoveVersion): State {
        if (sid !== state.inspectableId || !removingCurrentVersion) {
            return state;
        }

        return {
            ...state,
            versionId,
            latestVersionSelected: true,
            versionsDiffVisible: false,
            noDiffs: false,
            tooManyDiffs: false
        };
    }

    addComponentVersion(state: State, {
        coid,
        isCurrentBid,
        componentVersion
    }: BarrelActionPayloads.AddComponentVersion) {
        if (!isCurrentBid || coid !== state.inspectableId || !state.latestVersionSelected) {
            return state;
        }

        return {
            ...state,
            versionId: componentVersion._id,
            loadingSnapshot: true
        };
    }

    removeComponentVersion(state: State, {
        coid,
        nextVersionId,
        removingCurrentVersion
    }: ComponentActionPayloads.RemoveVersion): State {
        if (coid !== state.inspectableId || !removingCurrentVersion) {
            return state;
        }

        return {
            ...state,
            versionId: nextVersionId,
            latestVersionSelected: true,
            versionsDiffVisible: false,
            noDiffs: false,
            tooManyDiffs: false
        };
    }

    setSourceFile(state: State, {
        versionId,
        sourceFile
    }: ScreenActionPayloads.SetSourceFile): State {
        return {
            ...state,
            // TODO: Check if versionId can really be null or undefined
            sourceFiles: { ...state.sourceFiles, [versionId!]: sourceFile }
        };
    }

    updateForDeleteScreens(state: State, {
        removingCurrentScreen
    }: DashboardActionPayloads.DeleteScreens): State {
        if (!removingCurrentScreen) {
            return state;
        }

        return this.closeScreen(state);
    }

    updateForRemoveScreen(state: State, {
        removingCurrentScreen
    }: ScreenActionPayloads.RemoveScreen): State {
        if (!removingCurrentScreen) {
            return state;
        }

        return this.closeScreen(state);
    }

    updateForRemoveComponent(state: State, {
        removingCurrentComponent
    }: BarrelActionPayloads.RemoveComponent) {
        if (!removingCurrentComponent) {
            return state;
        }

        return this.closeScreen(state);
    }

    hideComponentVariantsOnboardingHintbox(state: State): State {
        return {
            ...state,
            componentVariantsOnboardingHintboxSeen: true
        };
    }

    hideImageProcessingHintbox(state: State): State {
        return {
            ...state,
            imageProcessingHintboxSeen: true
        };
    }

    setComponentVariantViewAsSeen(state: State): State {
        return {
            ...state,
            componentVariantViewSeen: true
        };
    }

    changeComponentsFilter(state: State, filter: ComponentsFilter): State {
        return {
            ...state,
            componentsFilter: filter,
            selectedLayer: null,
            destinationLayer: null,
            highlightedLayer: null
        };
    }

    updateDotPopupRemarkType(state: State, remarkType: RemarkType): State {
        return {
            ...state,
            dotPopupRemarkType: remarkType
        };
    }

    handleCreateNote(state: State): State {
        return {
            ...this.showNotes(state),
            dotPopupRemarkType: RemarkType.Dot
        };
    }

    handleCreateAnnotation(state: State): State {
        return {
            ...this.showNotes(state),
            dotPopupRemarkType: RemarkType.Annotation
        };
    }

    selectDot(state: State, {
        shouldCompleteDotOnboarding
    }: ScreenActionPayloads.SelectDot): State {
        let updatedState = state;

        if (shouldCompleteDotOnboarding) {
            updatedState = pipe(
                this.resetScreenOnboardingStep,
                this.deselectLayer
            )(state);
        }

        return this.showNotes(updatedState);
    }

    notifyAssetsTabOpened(state: State, {
        shouldCompleteAssetsOnboarding
    }: ScreenActionPayloads.NotifyAssetsTabOpened): State {
        if (!shouldCompleteAssetsOnboarding) {
            return state;
        }

        return this.resetScreenOnboardingStep(state);
    }

    setScreenOnboardingStep(state: State, {
        step
    }: ScreenActionPayloads.SetOnboardingStep): State {
        return {
            ...state,
            screenOnboardingStep: step,
            screenOnboardingInfoVisible: true
        };
    }

    resetScreenOnboardingStep(state: State): State {
        return {
            ...state,
            screenOnboardingStep: null
        };
    }

    setScreenOnboardingInfoVisibility(state: State, {
        visible
    }: {
        visible: boolean;
    }): State {
        return {
            ...state,
            screenOnboardingInfoVisible: visible
        };
    }

    hideScreenOnboardingFlowHintbox(state: State): State {
        return {
            ...state,
            screenOnboardingFlowHintboxHidden: true
        };
    }

    enterStageMode(state: State): State {
        return {
            ...state,
            stageMode: true,
            stageSidebarOpen: false
        };
    }

    leaveStageMode(state: State): State {
        return {
            ...state,
            stageMode: false,
            stageSidebarOpen: false,
            stageModeHistoryCount: 0
        };
    }

    showStageComments(state: State): State {
        return {
            ...state,
            stageSidebarOpen: true
        };
    }

    hideStageComments(state: State): State {
        return {
            ...state,
            stageSidebarOpen: false
        };
    }

    hideStageModeFirstTimeLandingHidden(state: State): State {
        return {
            ...state,
            stageModeFirstTimeLandingHidden: true
        };
    }

    applyStageModeHistoryOperation(
        state: State,
        { operation }: ScreenActionPayloads.ApplyStageModeHistoryOperation
    ): State {
        let newHistoryCount = state.stageModeHistoryCount;

        switch (operation) {
            case "increment":
                newHistoryCount += 1;
                break;
            case "decrement":
                newHistoryCount -= 1;
                break;
            default:
                break;
        }

        return {
            ...state,
            stageModeHistoryCount: newHistoryCount,
            flashStageModeHotspots: false
        };
    }

    flashStageModeHotspots(state: State): State {
        return {
            ...state,
            flashStageModeHotspots: true
        };
    }

    flashStageModeHotspotsDone(state: State): State {
        return {
            ...state,
            flashStageModeHotspots: false
        };
    }

    setActiveRemarkTypeTab(
        state: State,
        { tabType }: ScreenActionPayloads.SetActiveRemarkTypeTab
    ): State {
        return {
            ...state,
            activeRemarkTabType: tabType ?? RemarkType.Annotation
        };
    }

    showVersionDiffs(state: State): State {
        return {
            ...state,
            versionsDiffVisible: true,
            loadingVersionDiffs: true,
            noDiffs: false,
            tooManyDiffs: false,
            showVersionDiffAnnouncementPopup: false
        };
    }

    setVersionDiffs(state: State, { tooManyDiffs, noDiffs }: Pick<ScreenActionPayloads.SetVersionDiffs, "tooManyDiffs" | "noDiffs">): State {
        return {
            ...state,
            loadingVersionDiffs: false,
            tooManyDiffs,
            noDiffs
        };
    }

    hideVersionDiffs(state: State): State {
        return {
            ...state,
            versionsDiffVisible: false,
            loadingVersionDiffs: false,
            tooManyDiffs: false,
            noDiffs: false
        };
    }

    showTooManyDiffs(state: State): State {
        return {
            ...state,
            tooManyDiffs: false
        };
    }

    hideVersionDiffAnnouncementPopup(state: State): State {
        return {
            ...state,
            showVersionDiffAnnouncementPopup: false
        };
    }

    showVersionDiffAnnouncementPopup(state: State): State {
        return {
            ...state,
            showVersionDiffAnnouncementPopup: true
        };
    }

    setMovingRemarkRect(state: State, {
        movingRemarkRect
    }: Payloads.SetMovingRemarkRect): State {
        return {
            ...state,
            movingRemarkRect
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
                return this.reset();
            case BarrelActionTypes.LOAD_INTERMEDIATE:
                return this.loadIntermediate(state, action);
            case BarrelActionTypes.RESET:
                return this.reset(action);
            case BarrelActionTypes.LOAD:
                return this.load(state, action);
            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return action.selectedView === ProjectViewTypes.SCREEN
                    ? state
                    : this.closeScreen(state);
            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.updateForRemoveComponent(state, action);

            case ScreenActionTypes.OPEN_SCREEN:
                return this.openScreen(state, action);

            case ComponentActionTypes.OPEN_COMPONENT:
                return this.openComponent(state, action);

            // Zoom
            // Todo: Move these to SnapshotHandlers
            case InspectableViewActionTypes.CHANGE_ZOOM_LEVEL:
                return this.changeZoomLevel(state, action.zoomLevel, action.animateZooming);
            case InspectableViewActionTypes.ZOOM_IN:
                return this.zoomIn(state);
            case InspectableViewActionTypes.ZOOM_OUT:
                return this.zoomOut(state);
            case InspectableViewActionTypes.RESET_ZOOM:
                return this.resetZoom(state);

            case InspectableViewActionTypes.START_GESTURE:
                return this.startGesture(state);
            case InspectableViewActionTypes.ZOOM_WITH_GESTURE:
                return this.zoomWithGesture(state, action);
            case InspectableViewActionTypes.ZOOM_WITH_WHEEL:
                return this.zoomWithWheel(state, action);
            case InspectableViewActionTypes.ZOOM_APPLIED:
                return this.zoomApplied(state);
            case InspectableViewActionTypes.ZOOM_END:
                return this.zoomEnd(state);

            case InspectableViewActionTypes.CHANGE_SCROLL_POSITION:
                return this.changeScrollPosition(state, action);

            case ScreenActionTypes.OPEN_TAG_MANAGER:
                return this.openTagManager(state, action);
            case ScreenActionTypes.CLOSE_TAG_MANAGER:
                return this.closeTagManager(state);

            // Grid Layout & Widgets
            case InspectableViewActionTypes.TOGGLE_GRID_LAYOUT:
                return this.toggleGridLayout(state);
            case InspectableViewActionTypes.TOGGLE_GRID_WIDGET:
                return this.toggleGridWidget(state);

            // Notes
            case ScreenActionTypes.ENABLE_NOTE_MODE:
                return this.enableNoteMode(state, action);

            case ScreenActionTypes.DISABLE_NOTE_MODE:
                return this.disableNoteMode(state);

            case ScreenActionTypes.SHOW_NOTES:
            case ScreenActionTypes.SELECT_ANNOTATION:
                return this.showNotes(state);

            case ScreenActionTypes.SELECT_DOT:
                return this.selectDot(state, action);

            case ScreenActionTypes.CREATE_NOTE:
                return this.handleCreateNote(state);
            case ScreenActionTypes.CREATE_ANNOTATION:
                return this.handleCreateAnnotation(state);

            case ScreenActionTypes.HIDE_NOTES:
                return this.hideNotes(state);

            // Layers
            case InspectableViewActionTypes.SELECT_LAYER:
                return this.selectLayer(state, action);
            case InspectableViewActionTypes.DESELECT_LAYER:
                return this.deselectLayer(state);
            case InspectableViewActionTypes.ENTER_LAYER:
                return this.enterLayer(state, action);
            case InspectableViewActionTypes.HIGHLIGHT_LAYER:
                return this.highlightLayer(state, action);
            case InspectableViewActionTypes.UNHIGHLIGHT_LAYER:
                return this.unhighlightLayer(state);
            case InspectableViewActionTypes.CHANGE_LAYOUT_PADDING_VISIBILITY:
                return this.changeLayoutPaddingVisibility(state, action);
            case InspectableViewActionTypes.CHANGE_LAYOUT_GAP_VISIBILITY:
                return this.changeLayoutGapVisibility(state, action);
            case InspectableViewActionTypes.LEAVE_LAYER:
                return this.leaveLayer(state);
            case ScreenActionTypes.ENABLE_PERCENTAGE_MODE:
                return this.enablePercentageMode(state);
            case ScreenActionTypes.DISABLE_PERCENTAGE_MODE:
                return this.disablePercentageMode(state);
            case InspectableViewActionTypes.SET_ZOOM_TO_SELECTED_LAYER:
                return this.setZoomToSelectedLayer(state, action);

            // Flow Links
            case ScreenActionTypes.SHOW_FLOW_LINKS:
                return this.showFlowLinks(state);

            case ScreenActionTypes.HIDE_FLOW_LINKS:
                return this.hideFlowLinks(state);

            case InspectableViewActionTypes.CHANGE_SIDEBAR_VIEW:
                return this.changeSidebarView(state, action);

            case InspectableViewActionTypes.CHANGE_SIDEBAR_WIDTH:
                return this.changeSidebarWidth(state, action);

            case InspectableViewActionTypes.COLLAPSE_SIDEBAR:
                return this.changeSidebarCollapsed(state, true);

            case InspectableViewActionTypes.EXPAND_SIDEBAR:
                return this.changeSidebarCollapsed(state, false);

            case BarrelActionTypes.SET_COMPONENT_SNAPSHOT:
                return this.setComponentSnapshot(state, action);

            case ScreenActionTypes.SET_SNAPSHOT:
                return this.setScreenSnapshot(state, action);

            // Mark: Versions cases
            case InspectableViewActionTypes.SET_VERSION_ID:
                return this.setVersionId(state, action);
            case ScreenActionTypes.SET_VERSIONS:
                return this.setVersions(state, action);
            case ScreenActionTypes.ADD_VERSION:
                return this.addVersion(state, action);
            case ScreenActionTypes.ADD_SCREEN_TILES:
                return this.addScreenTiles(state, action);
            case InspectableViewActionTypes.SHOW_VERSIONS:
                return this.showVersions(state, action);
            case InspectableViewActionTypes.HIDE_VERSIONS:
                return this.hideVersions(state);
            case InspectableViewActionTypes.TOGGLE_VERSIONS:
                return this.toggleVersions(state, action);
            case InspectableViewActionTypes.CHANGE_VERSIONS_SIDEBAR_WIDTH:
                return this.changeVersionsSidebarWidth(state, action);
            case ScreenActionTypes.REMOVE_VERSION:
                return this.removeVersion(state, action);
            case ScreenActionTypes.SET_SOURCE_FILE:
                return this.setSourceFile(state, action);

            case ScreenActionTypes.REMOVE_SCREEN:
                return this.updateForRemoveScreen(state, action);
            case DashboardActionTypes.DELETE_SCREENS:
                return this.updateForDeleteScreens(state, action);
            case ScreenActionTypes.HIDE_COMPONENT_VARIANTS_ONBOARDING_HINTBOX:
                return this.hideComponentVariantsOnboardingHintbox(state);
            case ScreenActionTypes.HIDE_IMAGE_PROCESSING_HINTBOX:
                return this.hideImageProcessingHintbox(state);

            case ComponentVariantActionTypes.OPEN:
                return this.setComponentVariantViewAsSeen(state);
            case ComponentVariantActionTypes.SELECT_LAYER:
                return this.deselectLayer(state);

            case ScreenActionTypes.CHANGE_COMPONENTS_FILTER:
                return this.changeComponentsFilter(state, action.filter);

            case ScreenActionTypes.UPDATE_DOTPOPUP_REMARK_TYPE:
                return this.updateDotPopupRemarkType(state, action.remarkType);

            case ScreenActionTypes.NOTIFY_ASSETS_TAB_OPENED:
                return this.notifyAssetsTabOpened(state, action);

            case ScreenActionTypes.SET_ONBOARDING_STEP:
                return this.setScreenOnboardingStep(state, action);

            case ScreenActionTypes.COMPLETE_ONBOARDING_STEP:
                return this.resetScreenOnboardingStep(state);

            case ScreenActionTypes.SET_ONBOARDING_INFO_VISIBILITY:
                return this.setScreenOnboardingInfoVisibility(state, action);

            case ScreenActionTypes.HIDE_ONBOARDING_FLOW_HINTBOX:
                return this.hideScreenOnboardingFlowHintbox(state);

            case ScreenActionTypes.ENTER_STAGE_MODE:
                return this.enterStageMode(state);

            case ScreenActionTypes.LEAVE_STAGE_MODE:
                return this.leaveStageMode(state);

            case ScreenActionTypes.SHOW_STAGE_COMMENTS:
                return this.showStageComments(state);

            case ScreenActionTypes.HIDE_STAGE_COMMENTS:
            case ScreenActionTypes.CLOSE_STAGE_MODE_SIDEBAR:
                return this.hideStageComments(state);

            case ScreenActionTypes.HIDE_STAGE_MODE_FIRST_TIME_LANDING:
                return this.hideStageModeFirstTimeLandingHidden(state);

            case ScreenActionTypes.APPLY_STAGE_MODE_HISTORY_OPERATION:
                return this.applyStageModeHistoryOperation(state, action);

            case ScreenActionTypes.FLASH_STAGE_MODE_HOTSPOTS:
                return this.flashStageModeHotspots(state);

            case ScreenActionTypes.FLASH_STAGE_MODE_HOTSPOTS_DONE:
                return this.flashStageModeHotspotsDone(state);

            case ScreenActionTypes.SET_ACTIVE_REMARK_TYPE_TAB:
                return this.setActiveRemarkTypeTab(state, action);

            case InspectableViewActionTypes.SHOW_VERSION_DIFFS:
                return this.showVersionDiffs(state);

            case ScreenActionTypes.SET_VERSION_DIFFS:
            case ComponentActionTypes.SET_VERSION_DIFFS:
                return this.setVersionDiffs(state, action);

            case InspectableViewActionTypes.HIDE_VERSION_DIFFS:
                return this.hideVersionDiffs(state);

            case ScreenActionTypes.SHOW_TOO_MANY_DIFFS:
                return this.showTooManyDiffs(state);

            case ScreenActionTypes.HIDE_VERSION_DIFF_ANNOUNCEMENT_POPUP:
                return this.hideVersionDiffAnnouncementPopup(state);

            case ScreenActionTypes.SHOW_VERSION_DIFF_ANNOUNCEMENT_POPUP:
                return this.showVersionDiffAnnouncementPopup(state);

            case ComponentActionTypes.SET_COMPONENT_VERSIONS:
                return this.setComponentVersions(state, action);
            case ComponentActionTypes.REMOVE_VERSION:
                return this.removeComponentVersion(state, action);
            case BarrelActionTypes.ADD_COMPONENT_VERSION:
                return this.addComponentVersion(state, action);
            case InspectableViewActionTypes.SET_MOVING_REMARK_RECT:
                return this.setMovingRemarkRect(state, action);

            default:
                return state;
        }
    }
}

export default InspectableViewStore;
export { State as InspectableViewStoreState };
