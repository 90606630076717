
import { Dot } from "../../../foundation/api/model";
import BarrelOnboardingType from "../../../foundation/api/model/barrels/BarrelOnboardingType";
import DashboardOnboardingHintboxType from "../dashboard/DashboardOnboardingHintboxType";
import ScreenHelpers from "../screen/ScreenHelpers";
import ScreenOnboardingHintboxType from "../screen/ScreenOnboardingHintboxType";
import ScreenOnboardingStep from "../screen/ScreenOnboardingStep";
import fluxRuntime from "../fluxRuntime";

import BarrelHelpers from "./BarrelHelpers";

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 */
function getOnboardingHighlightScreenId(): string | null {
    if (!BarrelHelpers.isOnboardingProject()) {
        return null;
    }

    const project = BarrelHelpers.getProject()!;
    const highlightedScreen = project.screens?.find(screen => screen.onboardingHighlighted);

    if (!highlightedScreen) {
        return null;
    }

    return highlightedScreen._id;
}

/**
 *
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 *
 * @param sid screen id
 * @returns whether given screen id is equal to `onboardingHighlightScreenId` if an onboarding highlight screen exists.
 */
function isOnboardingHighlightScreen(sid: string | null): boolean {
    // Explicitly check whether `sid` is falsy because `onboardingHighlightScreenId` can also be falsy
    if (!sid) {
        return false;
    }

    const onboardingHighlightScreenId = getOnboardingHighlightScreenId();

    return sid === onboardingHighlightScreenId;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - DashboardStore
 * - DotsDataStore
 * - AppStore
 * - UserStore
 * - OrganizationStore
 */
function getDashboardOnboardingHintboxType(): DashboardOnboardingHintboxType {
    const userRole = BarrelHelpers.findUser()!.role;
    const isOnboardingProject = BarrelHelpers.isOnboardingProject();

    if (isOnboardingProject) {
        const { onboardingHighlightScreenOpened } = fluxRuntime.BarrelStore.getState();
        const { onboardingType } = BarrelHelpers.getBarrel() ?? {};

        if (onboardingType === BarrelOnboardingType.Figma) {
            return "highlight";
        }

        const { dashboardOnboardingEndingHintboxHidden } = fluxRuntime.DashboardStore.getState();
        if (dashboardOnboardingEndingHintboxHidden) {
            return null;
        }

        if (isScreenOnboardingCompleted()) {
            return "ending";
        }

        if (onboardingHighlightScreenOpened) {
            return "dropOff";
        }

        return "highlight";
    }

    const { registeredAt, shouldDisplayDashboardOnboardingHintbox } = fluxRuntime.UserStore.getState();
    const { config } = fluxRuntime.AppStore.getState();

    const registeredAfterDateToShowOnboarding =
        new Date(config!.minRegistrationDateToShowOnboarding) < new Date(registeredAt!);

    const isOwner = ["owner", "king"].includes(userRole);

    if (registeredAfterDateToShowOnboarding &&
        shouldDisplayDashboardOnboardingHintbox &&
        !isOwner) {
        return "invited";
    }

    return null;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - DotsDataStore
 */
function getOnboardingHighlightDot(): Dot | null {
    const onboardingHighlightScreenId = getOnboardingHighlightScreenId();

    if (!onboardingHighlightScreenId) {
        return null;
    }

    const { approvalsMode } = fluxRuntime.InspectableViewStore.getState();

    if (approvalsMode) {
        return null;
    }

    // Use non-null assertion for `project` because of the `onboardingHighlightScreenId` check above
    const project = BarrelHelpers.getProject()!;

    // Even though we use `ScreenHelpers` here, it doesn't create a dependency to InspectableViewStore because we provide sid here
    const dots = ScreenHelpers
        .getDots({ pid: project._id, sid: onboardingHighlightScreenId });

    if (!dots?.length) {
        return null;
    }

    // Highlighted comment must have name "1" in a hard-coded way
    const onboardingHighlightDot = dots.find(dot => dot.name === "1");
    if (!onboardingHighlightDot) {
        return null;
    }

    return onboardingHighlightDot;
}

/**
 * Store dependencies:
 * - BarrelStore
 */
function isOnboardingAssetsCompleted(): boolean {
    const { onboardingType } = BarrelHelpers.getBarrel() ?? {};
    if (onboardingType === BarrelOnboardingType.Figma) {
        return true;
    }

    const { onboardingAssetsCompleted } = fluxRuntime.BarrelStore.getState();
    return onboardingAssetsCompleted;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - DotsDataStore
 */
function isOnboardingDotCompleted(): boolean {
    const { onboardingDotCompleted } = fluxRuntime.BarrelStore.getState();
    const onboardingHighlightDot = getOnboardingHighlightDot();

    if (!onboardingHighlightDot) {
        return true;
    }

    return onboardingDotCompleted;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - DotsDataStore
 */
function isScreenOnboardingCompleted(): boolean {
    const {
        onboardingLayerCompleted,
        onboardingVersionsCompleted
    } = fluxRuntime.BarrelStore.getState();

    const onboardingAssetsCompleted = isOnboardingAssetsCompleted();
    const onboardingDotCompleted = isOnboardingDotCompleted();

    return onboardingLayerCompleted &&
        onboardingDotCompleted &&
        onboardingVersionsCompleted &&
        onboardingAssetsCompleted;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - DotsDataStore
 */
function getNextOnboardingStep(): ScreenOnboardingStep {
    const {
        onboardingLayerCompleted,
        onboardingVersionsCompleted
    } = fluxRuntime.BarrelStore.getState();

    const onboardingAssetsCompleted = isOnboardingAssetsCompleted();
    const onboardingDotCompleted = isOnboardingDotCompleted();

    if (!onboardingLayerCompleted) {
        return "layer";
    }

    if (!onboardingDotCompleted) {
        return "dot";
    }

    if (!onboardingAssetsCompleted) {
        return "assets";
    }

    if (!onboardingVersionsCompleted) {
        return "versions";
    }

    return null;
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - InspectableViewStore
 * - DotsDataStore
 */
function shouldHighlightOnboardingLayer(): boolean {
    const { inspectableId } = fluxRuntime.InspectableViewStore.getState();
    const isHighlightedScreen = isOnboardingHighlightScreen(inspectableId);

    /**
     * We start to save the onboarding step as `screenOnboardingStep` in store after the layer step is completed.
     * Therefore, `screenOnboardingStep` in store is always undefined at the layer step.
     * Because of that, here we get the `screenOnboardingStep` value via `getNextOnboardingStep` instead of store.
     */
    const screenOnboardingStep = getNextOnboardingStep();

    return isHighlightedScreen && screenOnboardingStep === "layer";
}

/**
 * Store dependencies:
 * - InspectableViewStore
 */
function shouldHighlightOnboardingDot(): boolean {
    const { screenOnboardingStep, inspectableId } = fluxRuntime.InspectableViewStore.getState();
    const isHighlightedScreen = isOnboardingHighlightScreen(inspectableId);

    return isHighlightedScreen && screenOnboardingStep === "dot";
}

/**
 * Store dependencies:
 * - InspectableViewStore
 */
function shouldHighlightOnboardingAssets(): boolean {
    const { screenOnboardingStep, inspectableId } = fluxRuntime.InspectableViewStore.getState();
    const isHighlightedScreen = isOnboardingHighlightScreen(inspectableId);

    return isHighlightedScreen && screenOnboardingStep === "assets";
}

/**
 * Store dependencies:
 * - InspectableViewStore
 */
function shouldHighlightOnboardingVersions(): boolean {
    const { screenOnboardingStep, inspectableId } = fluxRuntime.InspectableViewStore.getState();
    const isHighlightedScreen = isOnboardingHighlightScreen(inspectableId);

    return isHighlightedScreen && screenOnboardingStep === "versions";
}

/**
 * Store dependencies:
 * - BarrelStore
 * - ProjectsDataStore
 * - InspectableViewStore
 * - DotsDataStore
 */
function getScreenOnboardingHintboxType(): ScreenOnboardingHintboxType {
    const { inspectableId } = fluxRuntime.InspectableViewStore.getState();

    if (!isOnboardingHighlightScreen(inspectableId)) {
        return null;
    }

    if (shouldHighlightOnboardingLayer()) {
        return "layer";
    }

    if (!isScreenOnboardingCompleted()) {
        return "highlight";
    }

    const { onboardingType } = BarrelHelpers.getBarrel() ?? {};
    if (onboardingType === BarrelOnboardingType.Figma) {
        return "ending";
    }

    const { screenOnboardingFlowHintboxHidden } = fluxRuntime.InspectableViewStore.getState();
    if (screenOnboardingFlowHintboxHidden) {
        return null;
    }

    return "flow";
}

export default {
    getOnboardingHighlightScreenId,
    isOnboardingHighlightScreen,
    getDashboardOnboardingHintboxType,
    getOnboardingHighlightDot,
    getNextOnboardingStep,
    shouldHighlightOnboardingLayer,
    shouldHighlightOnboardingDot,
    shouldHighlightOnboardingAssets,
    shouldHighlightOnboardingVersions,
    getScreenOnboardingHintboxType,
    isScreenOnboardingCompleted
};
