/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import { AssetOptimizationStatus } from "../../../foundation/api/model/snapshots/Asset";

import Asset, { AssetContent, AssetStatState } from "../../../foundation/model/Asset";
import BasicRecord from "../../../foundation/utils/BasicRecord";

import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";

import { AllPayloads } from "../payloads";

import * as Payloads from "./AssetsActionPayloads";
import AssetsActionTypes from "./AssetsActionTypes";

interface State {
    versionsAssets: BasicRecord<Asset[]>;
    componentsAssets: BasicRecord<Asset[]>;
    asyncAssetsProgress: BasicRecord<number>;
    asyncAssetsOptimizing: BasicRecord<boolean>;
    incompleteAsyncAssets: BasicRecord<Asset[]>;
    numberOfAssetBatches: BasicRecord<number>;
}

class AssetsStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            versionsAssets: {},
            componentsAssets: {},
            asyncAssetsProgress: {},
            asyncAssetsOptimizing: {},
            incompleteAsyncAssets: {},
            numberOfAssetBatches: {}
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    setVersionAssets(state: State, {
        vid,
        assets
    }: Payloads.SetVersionAssets): State {
        return {
            ...state,
            versionsAssets: { ...state.versionsAssets, [vid]: assets }
        };
    }

    updateAssets(state: State, {
        vid,
        assets,
        isComponent
    }: Payloads.UpdateAssets): State {
        const storeKey = isComponent ? "componentsAssets" : "versionsAssets";
        const existingAssets = state[storeKey][vid];

        if (!existingAssets || existingAssets.length === 0) {
            return state;
        }

        const updatedAssets = existingAssets.map(asset => {
            const updatedAsset = assets.find(({ _id }) => _id === asset._id);
            return updatedAsset
                ? { ...asset, ...updatedAsset }
                : asset;
        });

        return {
            ...state,
            [storeKey]: {
                ...state[storeKey],
                [vid]: updatedAssets
            }
        };
    }

    setAsyncAssetsOptimizing(
        state: State, { vid, optimizing }: Payloads.SetAsyncAssetsOptimizing
    ): State {
        return {
            ...state,
            asyncAssetsOptimizing: { ...state.asyncAssetsOptimizing, [vid]: optimizing }
        };
    }

    setComponentAssets(state: State, {
        vid,
        assets
    }: Payloads.SetComponentAssets): State {
        return {
            ...state,
            componentsAssets: { ...state.componentsAssets, [vid]: assets }
        };
    }

    setIncompleteAsyncAssets(state: State, {
        vid,
        assets,
        assetBatchesLength,
        isComponent
    }: Payloads.SetIncompleteAsyncAssets): State {
        let modifiedAssets = assets;
        const currentAssets = state.incompleteAsyncAssets[vid] ?? [];
        const updatedAssets = currentAssets.map(currentAsset => {
            const updatedAsset = assets.find(asset => asset._id === currentAsset._id);
            if (!updatedAsset) {
                return currentAsset;
            }

            return {
                ...currentAsset,
                contents: [...currentAsset.contents, ...updatedAsset.contents]
            };
        });

        const newAssets = assets.filter(
            asset => !currentAssets.some(current => current._id === asset._id)
        );

        modifiedAssets = [...updatedAssets, ...newAssets];

        if (state.asyncAssetsOptimizing[vid]) {
            modifiedAssets = modifiedAssets.map(targetAsset => ({
                ...targetAsset,
                statState: AssetStatState.optimizing
            }));
        }

        const numberOfAssetBatches = (state.numberOfAssetBatches[vid] ?? 0) + 1;

        //  All finalize async assets pushers have arrived
        if (numberOfAssetBatches === assetBatchesLength) {
            const { [vid]: _, ...asyncAssetsProgressRest } = state.asyncAssetsProgress;

            if (isComponent) {
                return {
                    ...state,
                    componentsAssets: { ...state.componentsAssets, [vid]: modifiedAssets },
                    incompleteAsyncAssets: { ...state.incompleteAsyncAssets, [vid]: [] },
                    asyncAssetsProgress: { ...asyncAssetsProgressRest }
                };
            }

            return {
                ...state,
                versionsAssets: { ...state.versionsAssets, [vid]: modifiedAssets },
                incompleteAsyncAssets: { ...state.incompleteAsyncAssets, [vid]: [] },
                asyncAssetsProgress: { ...asyncAssetsProgressRest }
            };
        }

        return {
            ...state,
            incompleteAsyncAssets: { ...state.incompleteAsyncAssets, [vid]: modifiedAssets },
            numberOfAssetBatches: { ...state.numberOfAssetBatches, [vid]: numberOfAssetBatches }
        };
    }

    setAsyncAssetsProgress(state: State, { vid, progress }: Payloads.SetAsyncAssetsProgress): State {
        if (progress && state.asyncAssetsProgress && state.asyncAssetsProgress[vid] >= progress) {
            return state;
        }

        if (!progress) {
            const updatedAsyncAssetsProgress = { ...state.asyncAssetsProgress };

            delete updatedAsyncAssetsProgress[vid];

            return {
                ...state,
                asyncAssetsProgress: updatedAsyncAssetsProgress
            };
        }

        return {
            ...state,
            asyncAssetsProgress: { ...state.asyncAssetsProgress, [vid]: progress }
        };
    }

    updateVersionAssetOptimizedContents(state: State, {
        vid,
        assets
    }: Payloads.UpdateVersionAssetOptimizedContents): State {
        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || !versionAssets.length) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    const updatedAsset = assets.find(({ _id: vaid }) => vaid === asset._id);

                    if (!updatedAsset) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            const updatedContent = updatedAsset.contents.find(
                                ({ _id: vacid, originalContentId }) => content._id === (originalContentId || vacid)
                            );

                            if (!updatedContent) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized,
                                    ...updatedContent
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    updateVersionAssetOptimizedContent(state: State, { vid, vaid, vacid, patch }: {
        vid: string;
        vaid: string;
        vacid: string;
        patch: Partial<AssetContent>;
    }): State {
        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || !versionAssets.length) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    if (asset._id !== vaid) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            if (content._id !== vacid) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized!,
                                    ...patch
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    processVersionAssetContentRequest(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessVersionAssetContent): State {
        return this.updateVersionAssetOptimizedContent(state, {
            vid,
            vaid,
            vacid,
            patch: { status: AssetOptimizationStatus.Processing }
        });
    }

    processVersionAssetContentFailure(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessVersionAssetContentFailure): State {
        return this.updateVersionAssetOptimizedContent(state, {
            vid,
            vaid,
            vacid,
            patch: { status: AssetOptimizationStatus.Error }
        });
    }

    updateComponentVersionAssetOptimizedContents(state: State, {
        vid,
        assets
    }: Payloads.UpdateComponentVersionAssetOptimizedContents): State {
        const componentAssets = state.componentsAssets[vid];

        if (!componentAssets || !componentAssets.length) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [vid]: componentAssets.map(asset => {
                    const updatedAsset = assets.find(({ _id: vaid }) => vaid === asset._id);

                    if (!updatedAsset) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            const updatedContent = updatedAsset.contents.find(
                                ({ _id: vacid, originalContentId }) => content._id === (originalContentId || vacid)
                            );

                            if (!updatedContent) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized,
                                    ...updatedContent
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    updateComponentVersionAssetOptimizedContent(state: State, {
        vid, vaid, vacid, patch
    }: {
        vid: string;
        vaid: string;
        vacid: string;
        patch: Partial<AssetContent>;
    }): State {
        const componentAssets = state.componentsAssets[vid];

        if (!componentAssets || !componentAssets.length) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [vid]: componentAssets.map(asset => {
                    if (asset._id !== vaid) {
                        return asset;
                    }

                    return {
                        ...asset,
                        contents: asset.contents.map(content => {
                            if (content._id !== vacid) {
                                return content;
                            }

                            return {
                                ...content,
                                optimized: {
                                    ...content.optimized!,
                                    ...patch
                                }
                            };
                        })
                    };
                })
            }
        };
    }

    processComponentVersionAssetContentRequest(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessComponentVersionAssetContent): State {
        return this.updateComponentVersionAssetOptimizedContent(state, {
            vid, vaid, vacid, patch: { status: AssetOptimizationStatus.Processing }
        });
    }

    processComponentVersionAssetContentFailure(state: State, {
        vid,
        vaid,
        vacid
    }: Payloads.ProcessComponentVersionAssetContentFailure): State {
        return this.updateComponentVersionAssetOptimizedContent(state, {
            vid, vaid, vacid, patch: { status: AssetOptimizationStatus.Error }
        });
    }

    changeComponentAssetName(state: State, {
        component,
        assetId,
        name
    }: BarrelActionPayloads.ChangeAssetName): State {
        if (!component) {
            return state;
        }

        const vid = component.latestVersion._id;
        const componentAssets = state.componentsAssets[vid];

        if (!componentAssets || componentAssets.length === 0) {
            return state;
        }

        return {
            ...state,
            componentsAssets: {
                ...state.componentsAssets,
                [vid]: componentAssets.map(asset => {
                    if (asset._id !== assetId) {
                        return asset;
                    }

                    return {
                        ...asset,
                        localName: name
                    };
                })
            }
        };
    }

    changeVersionAssetName(state: State, {
        versionId: vid,
        assetId,
        name
    }: BarrelActionPayloads.ChangeAssetName): State {
        if (!vid) {
            return state;
        }

        const versionAssets = state.versionsAssets[vid];

        if (!versionAssets || versionAssets.length === 0) {
            return state;
        }

        return {
            ...state,
            versionsAssets: {
                ...state.versionsAssets,
                [vid]: versionAssets.map(asset => {
                    if (asset._id !== assetId) {
                        return asset;
                    }

                    return {
                        ...asset,
                        localName: name
                    };
                })
            }
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
                return this.reset();

            case AssetsActionTypes.SET_VERSION_ASSETS:
                return this.setVersionAssets(state, action);

            case AssetsActionTypes.UPDATE_ASSETS:
                return this.updateAssets(state, action);

            case AssetsActionTypes.SET_COMPONENT_ASSETS:
                return this.setComponentAssets(state, action);

            case AssetsActionTypes.SET_INCOMPLETE_ASYNC_ASSETS:
                return this.setIncompleteAsyncAssets(state, action);

            case AssetsActionTypes.SET_ASYNC_ASSETS_PROGRESS:
                return this.setAsyncAssetsProgress(state, action);

            case AssetsActionTypes.SET_ASYNC_ASSETS_OPTIMIZING:
                return this.setAsyncAssetsOptimizing(state, action);

            case AssetsActionTypes.UPDATE_VERSION_ASSET_OPTIMIZED_CONTENTS:
                return this.updateVersionAssetOptimizedContents(state, action);
            case AssetsActionTypes.PROCESS_VERSION_ASSET_CONTENT_REQUEST:
                return this.processVersionAssetContentRequest(state, action);
            case AssetsActionTypes.PROCESS_VERSION_ASSET_CONTENT_FAILURE:
                return this.processVersionAssetContentFailure(state, action);

            case AssetsActionTypes.UPDATE_COMPONENT_VERSION_ASSET_OPTIMIZED_CONTENTS:
                return this.updateComponentVersionAssetOptimizedContents(state, action);
            case AssetsActionTypes.PROCESS_COMPONENT_VERSION_ASSET_CONTENT_REQUEST:
                return this.processComponentVersionAssetContentRequest(state, action);
            case AssetsActionTypes.PROCESS_COMPONENT_VERSION_ASSET_CONTENT_FAILURE:
                return this.processComponentVersionAssetContentFailure(state, action);

            case BarrelActionTypes.CHANGE_ASSET_NAME:
                if (action.component) {
                    return this.changeComponentAssetName(state, action);
                } else if (action.versionId) {
                    return this.changeVersionAssetName(state, action);
                }

                return state;
            default:
                return state;
        }
    }
}

export default AssetsStore;
export { State as AssetsStoreState };
