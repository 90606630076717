import Barrel from "../../../foundation/model/Barrel";
import Screen from "../../../foundation/model/Screen";
import ScreenVersion from "../../../foundation/model/ScreenVersion";
import BarrelHelpers from "../barrel/BarrelHelpers";
import OnboardingProjectHelpers from "../barrel/OnboardingProjectHelpers";
import fluxRuntime from "../fluxRuntime";

import ScreenHelpers from "./ScreenHelpers";

function setCurrentDate() {
    const { inspectableId } = fluxRuntime.InspectableViewStore.getState();

    if (!inspectableId) {
        return {};
    }

    return {
        sid: inspectableId,
        date: new Date()
    };
}

function getVersionsLastSeenForCurrentScreen() {
    const { inspectableId } = fluxRuntime.InspectableViewStore.getState();

    if (!inspectableId) {
        return {};
    }
    const { screenVersionsLastSeen } = fluxRuntime.BarrelStore.getState();
    const currentScreenVersionLastSeen = screenVersionsLastSeen?.[inspectableId] ?? new Date();

    return {
        sid: inspectableId,
        date: currentScreenVersionLastSeen
    };
}

function getOpenScreenData({
    sid,
    vid,
    did,
    cmid,
    atid,
    stageMode,
    project,
    apid,
    aprid
}: {
    sid: string;
    vid: string | undefined;
    did?: string | null;
    cmid?: string | string[];
    atid?: string | null;
    stageMode?: boolean;
    project?: Barrel;
    apid?: string | null;
    aprid?: string | null | undefined;
}) {
    let screen;
    if (project) {
        screen = project?.screens
        ?.find(projectScreen => projectScreen._id === sid) as Screen;
    } else {
        screen = ScreenHelpers.getScreen({ sid });
    }

    const { barrelId } = fluxRuntime.BarrelStore.getState();
    const isOnboardingHighlightScreen = OnboardingProjectHelpers.isOnboardingHighlightScreen(sid);
    const { onboardingType } = BarrelHelpers.getBarrel() ?? {};

    const payload = {
        barrelId: barrelId!,
        screenId: sid,
        dotId: did,
        commentId: cmid,
        annotationId: atid,
        stageMode,
        approvalsId: apid,
        approvalReviewerId: aprid,
        isOnboardingHighlightScreen,
        onboardingType
    };

    if (screen) {
        const { versions: [latestVersion] } = screen;

        let version: ScreenVersion | null | undefined;
        let versionId: string | undefined;

        if (vid) {
            version = ScreenHelpers.getVersion({ sid, vid });
            versionId = vid;

            Object.assign(payload, {
                versionsVisible: true
            });
        } else {
            version = latestVersion;
            versionId = version?._id;
        }

        if (!stageMode) {
            Object.assign(payload, {
                stageModeHistoryCount: 0
            });
        }

        const latestVersionSelected = latestVersion._id === versionId;

        const { visibleScreens } = ScreenHelpers.getFilteredScreens(project) ?? {};
        if (!visibleScreens) {
            return;
        }

        const shouldResetDashboardFilters = !visibleScreens.some(s => s._id === sid);

        Object.assign(payload, {
            versionId,
            latestVersionSelected,
            loadingSnapshot: !version || !("layers" in version.snapshot),
            processingSnapshotTiles: version?.snapshot?.tileInfo?.status === "processing",
            loadingVersions: !screen.totalVersionCount,
            shouldResetDashboardFilters
        });
    }

    const { versionsVisible } = fluxRuntime.InspectableViewStore.getState();

    return {
        ...payload,
        screenVersionsLastSeen: versionsVisible
            ? setCurrentDate()
            : getVersionsLastSeenForCurrentScreen(),
        screenLastOpened: setCurrentDate()
    };
}

export default {
    getOpenScreenData
};
