import { h } from "preact";
import { urlResolve } from "../../../../foundation/utils/url";
import BasicRecord from "../../../../foundation/utils/BasicRecord";
import classname from "../../../../foundation/utils/classname";
import { imageUrl } from "../../../../foundation/utils/image";

import "./style.css";

interface Props {
    type: "login" | "signup";
    query?: BasicRecord;
    disabled?: boolean;
    onClick?: (event: Event, href: string) => void;
}

function FigmaSSOButton({ type, query, disabled, onClick }: Props) {
    const href = urlResolve("authFigma", { query });

    function handleAnchorKeyPress(event: KeyboardEvent) {
        if (event.keyCode === Zeplin.KEY_CODE.SPACE) {
            handleAnchorClick(event);
            location.assign(href);
        }
    }

    function handleAnchorClick(event: Event) {
        if (onClick) {
            onClick(event, href);
        }
    }

    return (
        <a
            class={classname("figmaSSOButton", { disabled })}
            href={href}
            onKeyPress={handleAnchorKeyPress}
            onClick={handleAnchorClick}>
            <img src={imageUrl("img/icFigmaLogo.svg")}/>
            <span class="figmaSSOButtonText">
                {type === "login" ? "Login" : "Continue"} with <span class="figmaText">Figma</span>
            </span>
        </a>
    );
}

export default FigmaSSOButton;
