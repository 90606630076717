/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import { SortTypes, ProjectViewTypes } from "../../../foundation/enums";
import BarrelType from "../../../foundation/model/BarrelType";
import { StyleguideSectionType } from "../../../foundation/model/StyleguideSection";
import { PartializeProps } from "../../../foundation/utils/UtilityTypes";

import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./StyleguideActionPayloads";
import StyleguideActionTypes from "./StyleguideActionTypes";
import State from "./StyleguideStoreState";

class StyleguideStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            sortType: SortTypes.SECTIONS,
            filterValue: "",
            leftSidebarWidth: 240,
            rightSidebarWidth: 280,
            selectedStyleguideSection: StyleguideSectionType.About,
            selectedNavigatorId: null,
            collapsedSections: {},
            highlightedColors: [],
            highlightedTextStyles: [],
            highlightedSpacingTokens: [],
            scrolledSection: null,
            highlightScrolledSection: false,
            highlightedTreeViewSectionId: null,
            selectionComponentId: null,
            selectionColorId: null,
            styleguideSelection: null,
            moveResourcesPopup: null,
            componentVariantsOnboardingDialogSeen: false,
            convertedAnySectionIntoVariant: false,
            componentsUpdatedLastSeen: null,
            loadingComponentSnapshot: false
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    load({
        styleguide: update = {}
    }: PartializeProps<BarrelActionPayloads.Load, "styleguide">): State {
        const scrolledSection = update.scrolledSection ?? null;
        const selectedNavigatorId = update.selectedNavigatorId ?? null;
        const selectionComponentId = update.selectionComponentId ?? null;
        const styleguideSelection = update.styleguideSelection ?? null;

        return {
            ...this.getInitialState(),
            ...update,
            scrolledSection,
            selectedNavigatorId,
            selectionComponentId,
            styleguideSelection
        };
    }

    preloadProjectDashboard(state: State): State {
        return {
            ...state,
            selectedStyleguideSection: StyleguideSectionType.Colors
        };
    }

    updateState(state: State, {
        update
    }: Payloads.UpdateState): State {
        return {
            ...state,
            ...update
        };
    }

    changeSortType(state: State, {
        sortType
    }: Payloads.ChangeSortType): State {
        return {
            ...state,
            sortType
        };
    }

    changeComponentFilterValue(state: State, {
        filterValue
    }: Payloads.ChangeComponentFilterValue): State {
        return {
            ...state,
            filterValue
        };
    }

    changeLeftSidebarWidth(state: State, {
        leftSidebarWidth
    }: Payloads.ChangeLeftSidebarWidth): State {
        return {
            ...state,
            leftSidebarWidth
        };
    }

    changeRightSidebarWidth(state: State, {
        rightSidebarWidth
    }: Payloads.ChangeRightSidebarWidth): State {
        return {
            ...state,
            rightSidebarWidth
        };
    }

    changeSelectedNavigatorId(state: State, {
        selectedStyleguideSection,
        selectedNavigatorId,
        highlightScrolledSection
    }: Payloads.ChangeSelectedNavigatorId): State {
        return {
            ...state,
            selectedStyleguideSection,
            selectedNavigatorId,
            highlightedTreeViewSectionId: null,
            scrolledSection: selectedNavigatorId,
            highlightScrolledSection: !!highlightScrolledSection
        };
    }

    highlightColors(state: State, {
        cids
    }: Payloads.HighlightColors): State {
        return {
            ...state,
            highlightedColors: [...cids]
        };
    }

    unhighlightColor(state: State, {
        cid
    }: Payloads.UnhighlightColor): State {
        return {
            ...state,
            highlightedColors: state.highlightedColors.filter(id => id !== cid)
        };
    }

    highlightTextStyles(state: State, {
        tsids
    }: Payloads.HighlightTextStyles): State {
        return {
            ...state,
            highlightedTextStyles: [...tsids]
        };
    }

    unhighlightTextStyle(state: State, {
        tsid
    }: Payloads.UnhighlightTextStyle): State {
        return {
            ...state,
            highlightedTextStyles: state.highlightedTextStyles.filter(id => id !== tsid)
        };
    }

    highlightSpacingTokens(state: State, {
        sptids
    }: Payloads.HighlightSpacingTokens): State {
        return {
            ...state,
            highlightedSpacingTokens: [...sptids]
        };
    }

    unhighlightSpacingToken(state: State, {
        sptid
    }: Payloads.UnhighlightSpacingToken): State {
        return {
            ...state,
            highlightedSpacingTokens: state.highlightedSpacingTokens.filter(id => id !== sptid)
        };
    }

    highlightComponentTreeView(state: State, {
        sectionId
    }: Payloads.HighlightComponentTreeView): State {
        return {
            ...state,
            highlightedTreeViewSectionId: sectionId,
            selectedNavigatorId: null
        };
    }

    sectionScrolled(state: State): State {
        return {
            ...state,
            scrolledSection: null,
            highlightScrolledSection: false
        };
    }

    changeSelection(state: State, {
        selectionComponentId,
        styleguideSelection,
        collapsedSections
    }: Payloads.ChangeSelection): State {
        return {
            ...state,
            selectionComponentId,
            styleguideSelection,
            collapsedSections: collapsedSections ?? state.collapsedSections
        };
    }

    updateForChangeSelectedView(state: State, {
        selectedView,
        barrelType
    }: BarrelActionPayloads.ChangeSelectedView): State {
        if (barrelType === BarrelType.STYLEGUIDE ||
            selectedView === ProjectViewTypes.STYLEGUIDE) {
            return state;
        }

        return {
            ...state,
            selectionComponentId: null,
            styleguideSelection: null,
            selectedNavigatorId: null,
            highlightedTreeViewSectionId: null,
            convertedAnySectionIntoVariant: false
        };
    }

    removeComponent(state: State, {
        coid
    }: BarrelActionPayloads.RemoveComponent): State {
        if (coid === state.selectionComponentId) {
            return {
                ...state,
                selectionComponentId: null,
                styleguideSelection: null
            };
        }

        return state;
    }

    removeComponents(state: State, {
        sectionComponents
    }: BarrelActionPayloads.RemoveComponents): State {
        const foundSelectedComponent = sectionComponents.find(({ coids }) =>
            coids.find(coid => coid === state.selectionComponentId)
        );

        if (foundSelectedComponent) {
            return {
                ...state,
                selectionComponentId: null,
                styleguideSelection: null
            };
        }

        return state;
    }

    openMoveResourcesPopup(state: State, {
        resources,
        resourceType,
        targetNode
    }: Payloads.OpenMoveResourcesPopup): State {
        return {
            ...state,
            moveResourcesPopup: {
                resources,
                resourceType,
                targetNode,
                targetStyleguide: null
            }
        };
    }

    closeMoveResourcesPopup(state: State): State {
        return {
            ...state,
            moveResourcesPopup: null
        };
    }

    selectMoveResourcesTargetStyleguide(state: State, {
        targetStyleguide
    }: Payloads.SelectMoveResourcesTargetStyleguide): State {
        const { moveResourcesPopup } = state;
        return {
            ...state,
            moveResourcesPopup: {
                ...moveResourcesPopup!,
                targetStyleguide
            }
        };
    }

    deselectMoveResourcesTargetStyleguide(state: State): State {
        const { moveResourcesPopup } = state;
        return {
            ...state,
            moveResourcesPopup: {
                ...moveResourcesPopup!,
                targetStyleguide: null
            }
        };
    }

    expandSections(state: State, {
        seids
    }: Payloads.ExpandSections): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, false]))
            }
        };
    }

    expandAllSections(state: State, {
        seids
    }: Payloads.ExpandAllSections): State {
        return {
            ...state,
            selectedNavigatorId: null,
            selectionColorId: null,
            selectionComponentId: null,
            styleguideSelection: null,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, false]))
            }
        };
    }

    collapseSections(state: State, {
        seids
    }: Payloads.CollapseSections): State {
        return {
            ...state,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, true]))
            }
        };
    }

    collapseAllSections(state: State, {
        seids
    }: Payloads.CollapseAllSections): State {
        return {
            ...state,
            selectedNavigatorId: null,
            selectionColorId: null,
            selectionComponentId: null,
            styleguideSelection: null,
            collapsedSections: {
                ...state.collapsedSections,
                ...Object.fromEntries(seids.map(seid => [seid, true]))
            }
        };
    }

    hideComponentVariantsOnboardingDialog(state: State): State {
        return {
            ...state,
            componentVariantsOnboardingDialogSeen: true
        };
    }

    convertedAnySectionIntoVariant(state: State, {
        userConvertedSectionIntoVariant
    }: Payloads.SetComponentVariantData): State {
        // if user previously converted a section
        // or this is not a user converting a section into variant
        // then ignore this
        if (state.convertedAnySectionIntoVariant || !userConvertedSectionIntoVariant) {
            return state;
        }

        return {
            ...state,
            convertedAnySectionIntoVariant: true
        };
    }

    updateComponentsUpdatedLastSeen(state: State, {
        date
    }: Payloads.UpdateComponentsUpdatedLastSeen) {
        return {
            ...state,
            componentsUpdatedLastSeen: date
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
                return this.reset();

            case BarrelActionTypes.LOAD:
                return this.load(action);

            case BarrelActionTypes.PRELOAD_PROJECT_DASHBOARD:
                return this.preloadProjectDashboard(state);

            case BarrelActionTypes.CHANGE_SELECTED_VIEW:
                return this.updateForChangeSelectedView(state, action);

            case StyleguideActionTypes.UPDATE_STATE:
                return this.updateState(state, action);

            case StyleguideActionTypes.CHANGE_SORT_TYPE:
                return this.changeSortType(state, action);

            case StyleguideActionTypes.CHANGE_COMPONENT_FILTER_VALUE:
                return this.changeComponentFilterValue(state, action);

            case StyleguideActionTypes.CHANGE_LEFT_SIDEBAR_WIDTH:
                return this.changeLeftSidebarWidth(state, action);

            case StyleguideActionTypes.CHANGE_RIGHT_SIDEBAR_WIDTH:
                return this.changeRightSidebarWidth(state, action);

            case StyleguideActionTypes.CHANGE_SELECTED_NAVIGATOR_ID:
                return this.changeSelectedNavigatorId(state, action);

            case StyleguideActionTypes.HIGHLIGHT_COLORS:
                return this.highlightColors(state, action);

            case StyleguideActionTypes.UNHIGHLIGHT_COLOR:
                return this.unhighlightColor(state, action);

            case StyleguideActionTypes.HIGHLIGHT_TEXT_STYLES:
                return this.highlightTextStyles(state, action);

            case StyleguideActionTypes.UNHIGHLIGHT_TEXT_STYLE:
                return this.unhighlightTextStyle(state, action);

            case StyleguideActionTypes.HIGHLIGHT_SPACING_TOKENS:
                return this.highlightSpacingTokens(state, action);

            case StyleguideActionTypes.UNHIGHLIGHT_SPACING_TOKEN:
                return this.unhighlightSpacingToken(state, action);

            case StyleguideActionTypes.HIGHLIGHT_TREEVIEW_SECTION:
                return this.highlightComponentTreeView(state, action);

            case StyleguideActionTypes.SECTION_SCROLLED:
                return this.sectionScrolled(state);

            case StyleguideActionTypes.CHANGE_SELECTION:
                return this.changeSelection(state, action);

            case StyleguideActionTypes.OPEN_MOVE_RESOURCES_POPUP:
                return this.openMoveResourcesPopup(state, action);

            case StyleguideActionTypes.CLOSE_MOVE_RESOURCES_POPUP:
                return this.closeMoveResourcesPopup(state);

            case StyleguideActionTypes.SELECT_MOVE_RESOURCES_TARGET_STYLEGUIDE:
                return this.selectMoveResourcesTargetStyleguide(state, action);

            case StyleguideActionTypes.DESELECT_MOVE_RESOURCES_TARGET_STYLEGUIDE:
                return this.deselectMoveResourcesTargetStyleguide(state);

            case StyleguideActionTypes.HIDE_COMPONENT_VARIANTS_ONBOARDING_DIALOG:
                return this.hideComponentVariantsOnboardingDialog(state);

            case StyleguideActionTypes.EXPAND_SECTIONS:
                return this.expandSections(state, action);

            case StyleguideActionTypes.EXPAND_ALL_SECTIONS:
                return this.expandAllSections(state, action);

            case StyleguideActionTypes.COLLAPSE_SECTIONS:
                return this.collapseSections(state, action);

            case StyleguideActionTypes.COLLAPSE_ALL_SECTIONS:
                return this.collapseAllSections(state, action);

            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.removeComponent(state, action);

            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.removeComponents(state, action);

            case BarrelActionTypes.MOVE_RESOURCES_SUCCESS:
                return this.closeMoveResourcesPopup(state);

            case BarrelActionTypes.MOVE_RESOURCES_FAIL:
                return this.deselectMoveResourcesTargetStyleguide(state);

            case StyleguideActionTypes.SET_COMPONENT_VARIANT_DATA:
                return this.convertedAnySectionIntoVariant(state, action);

            case StyleguideActionTypes.UPDATE_COMPONENTS_UPDATED_LAST_SEEN:
                return this.updateComponentsUpdatedLastSeen(state, action);

            default:
                return state;
        }
    }
}

export default StyleguideStore;
export { State as StyleguideStoreState };
