/* eslint-disable class-methods-use-this */

import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import BarrelDataStage from "../../../foundation/api/model/barrels/BarrelDataStage";
import StyleguideLinkInfo from "../../../foundation/api/model/barrels/StyleguideLinkInfo";
import { FigmaSyncProgressData } from "../../../foundation/api/model/integrations/barrel/FigmaIntegration";
import Barrel from "../../../foundation/model/Barrel";
import BarrelType from "../../../foundation/model/BarrelType";
import FigmaIntegration from "../../../foundation/model/FigmaIntegration";

import isUserMemberOfBarrel from "../../../foundation/utils/barrel/isUserMemberOfBarrel";
import resourceTypeToBarrelKey from "../../../foundation/utils/barrel/resourceTypeToBarrelKey";
import BasicRecord from "../../../foundation/utils/BasicRecord";

import * as AppActionPayloads from "../app/AppActionPayloads";
import AppActionTypes from "../app/AppActionTypes";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import BarrelsReducer from "../barrelsReducer/BarrelsReducer";
import ComponentActionTypes from "../component/ComponentActionTypes";
import * as FigmaLibrarySyncActionPayloads from "../figmaLibrarySync/FigmaLibrarySyncActionPayloads";
import FigmaLibrarySyncActionTypes from "../figmaLibrarySync/FigmaLibrarySyncActionTypes";
import OrganizationActionTypes from "../organization/OrganizationActionTypes";
import * as ProjectActionPayloads from "../project/ProjectActionPayloads";
import ProjectActionTypes from "../project/ProjectActionTypes";
import StyleguideActionTypes from "../styleguide/StyleguideActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./StyleguidesActionPayloads";
import StyleguidesActionTypes from "./StyleguidesActionTypes";

interface State {
    createStyleguideInProgress: boolean;
    joinStyleguideInProgress: boolean;
    loadingStyleguideId: string | null;
    loadingStyleguides: boolean;
    loadingSelectParent: boolean;
    styleguides: BasicRecord<Barrel>;
    loadingComponentShapshot: boolean;
}

class StyleguidesDataStore extends ReduceStore<State, AllPayloads> {
    reducer: BarrelsReducer<State, "styleguides", "loadingStyleguideId", "loadingStyleguides">;

    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);

        this.reducer = new BarrelsReducer({
            barrelsPropertyName: "styleguides",
            loadingBarrelIdPropertyName: "loadingStyleguideId",
            loadingBarrelsPropertyName: "loadingStyleguides"
        }, {
            deleteBarrel: this.remove
        });
    }

    /**
     * @state {Object} styleguides - Storing `styleguides` in normalized format to make CRUD operations easy;
     * {
     *   "stid1": {
     *     "_id": "stid1"
     *     ..
     *   },
     *   "stid2": {
     *     "_id": "stid2"
     *     ..
     *   },
     * }
     */
    getInitialState(): State {
        return {
            createStyleguideInProgress: false,
            joinStyleguideInProgress: false,
            loadingStyleguideId: null,
            loadingStyleguides: true,
            loadingSelectParent: false,
            styleguides: {},
            loadingComponentShapshot: false
        };
    }

    reset(): State {
        return this.getInitialState();
    }

    // #region Common Barrel Reducers
    add(state: State, {
        barrel,
        barrelType
    }: BarrelActionPayloads.Add): State {
        if (barrelType !== BarrelType.STYLEGUIDE) {
            return state;
        }

        return this.reducer.addBarrel(state, { barrel });
    }

    updateStatus(state: State, { bid, barrelType, affectedIds, status } : {
        bid: string;
        barrelType: BarrelType;
        affectedIds: string[];
        status: "active" | "archived";
     }): State {
        if (barrelType !== BarrelType.STYLEGUIDE) {
            return state;
        }

        const { styleguides } = state;
        const rootStyleguide = styleguides[bid];

        const updatedStyleguides = Object.fromEntries(affectedIds.map(stid => {
            const styleguide = styleguides[stid];

            styleguide.status = status;
            if (styleguide.parent) {
                styleguide.ancestors = styleguide.ancestors!.filter(
                    ancestor => !rootStyleguide.ancestors!.includes(ancestor)
                );

                if (bid === stid) {
                    styleguide.parent = null;
                }
            }

            return [stid, styleguide];
        }));

        return {
            ...state,
            styleguides: {
                ...styleguides,
                ...updatedStyleguides
            }
        };
    }

    resetFigmaSyncProgress(state: State, bid: string) {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "integration",
            value: ({ integration }: Barrel) => {
                if (!integration) {
                    return;
                }

                return {
                    ...integration,
                    figma: {
                        ...(integration.figma || {}),
                        isSyncing: false
                    }
                };
            }
        });
    }

    getFigmaSyncStarted(state: State, {
        styleguideId: stid
    }: {
        styleguideId: string;
    }) {
        if (!state.styleguides[stid]) {
            return state;
        }

        return {
            ...state,
            styleguides: {
                ...state.styleguides,
                [stid]: new Barrel({
                    ...state.styleguides[stid],
                    integration: {
                        ...(state.styleguides[stid].integration || {}),
                        figma: {
                            ...(state.styleguides[stid].integration?.figma || {}),
                            isSyncing: true,
                            lastSync: {
                                startedAt: new Date(),
                                endedAt: new Date(),
                                exportCounts: undefined
                            }
                        }
                    }
                })
            }
        };
    }

    onUpdateStyleguideSyncFile(state: State, {
        stid
    }: {
        stid: string;
    }) {
        return {
            ...state,
            styleguides: {
                ...state.styleguides,
                [stid]: new Barrel({
                    ...state.styleguides[stid],
                    integration: {
                        ...(state.styleguides[stid].integration || {}),
                        figma: {
                            ...(state.styleguides[stid].integration?.figma || {}),
                            isSyncing: true,
                            lastSync: {
                                startedAt: new Date(),
                                endedAt: new Date(),
                                exportCounts: undefined
                            }
                        }
                    }
                })
            }
        };
    }

    onUpdateStyleguideSyncData(state: State, {
        stid,
        syncData
    }: {
        stid: string;
        syncData: FigmaIntegration;
    }) {
        function getExportObject(key: keyof FigmaSyncProgressData) {
            const exportData = syncData?.lastSync?.exportCounts?.[key];
            const currentExportData = state.styleguides[stid].integration?.figma?.lastSync?.exportCounts?.[key];

            if (exportData) {
                return {
                    success: exportData.success,
                    total: exportData.total,
                    isSyncFinished: currentExportData?.isSyncFinished // Do not update this value, endpoint does not include this field in its response
                };
            }

            return {};
        }

        return {
            ...state,
            styleguides: {
                ...state.styleguides,
                [stid]: new Barrel({
                    ...state.styleguides[stid],
                    integration: {
                        ...(state.styleguides[stid].integration || {}),
                        figma: {
                            ...(syncData || {}),
                            lastSync: {
                                // eslint-disable-next-line no-sync
                                ...(syncData?.lastSync || {
                                    startedAt: new Date()
                                }),
                                endedAt: state.styleguides[stid].integration?.figma?.lastSync?.endedAt || new Date(),
                                exportCounts: {
                                    // eslint-disable-next-line no-sync
                                    ...(state.styleguides[stid].integration?.figma?.lastSync?.exportCounts || {}),
                                    components: getExportObject("components"),
                                    colorStyles: getExportObject("colorStyles"),
                                    textStyles: getExportObject("textStyles")
                                }
                            }
                        }
                    }
                })
            }
        };
    }

    updateStyleguideSyncData(state: State, {
        stid,
        styleguideName,
        libraryName,
        figmaFileKey,
        isSyncStarted
    }: FigmaLibrarySyncActionPayloads.UpdateStyleguideSyncData) {
        if (!state.styleguides[stid]) {
            return state;
        }

        return {
            ...state,
            styleguides: {
                ...state.styleguides,
                [stid]: new Barrel({
                    ...state.styleguides[stid],
                    name:
                        styleguideName ||
                        state.styleguides[stid].name,
                    integration: {
                        ...(state.styleguides[stid].integration || {}),
                        figma: {
                            ...(state.styleguides[stid].integration?.figma || {}),
                            fileName: libraryName || state.styleguides[stid].integration?.figma?.fileName,
                            fileKey: figmaFileKey || state.styleguides[stid].integration?.figma?.fileKey,
                            isSyncing: isSyncStarted
                        }
                    }
                })
            }
        };
    }

    removeFigmaSyncData(state: State, {
        stid
    }: FigmaLibrarySyncActionPayloads.RemoveFigmaSyncData): State {
        const integrationData = { ...state.styleguides[stid].integration };
        delete integrationData.figma;

        return {
            ...state,
            styleguides: {
                ...state.styleguides,
                [stid]: new Barrel({
                    ...state.styleguides[stid],
                    integration: integrationData
                })
            }
        };
    }

    activateStyleguide(state: State, {
        bid,
        barrelType,
        affectedIds
    }: BarrelActionPayloads.Activate): State {
        const syncAborted = this.resetFigmaSyncProgress(state, bid);

        return this.updateStatus(syncAborted, { bid, barrelType, affectedIds, status: "active" });
    }

    archiveStyleguide(state: State, {
        bid,
        barrelType,
        affectedIds
    }: BarrelActionPayloads.Archive): State {
        const syncAborted = this.resetFigmaSyncProgress(state, bid);

        return this.updateStatus(syncAborted, { bid, barrelType, affectedIds, status: "archived" });
    }

    remove(
        state: State,
        { bid, barrelType }: Pick<BarrelActionPayloads.Remove, "bid" | "barrelType">,
        preserveLinkRelation = false
    ): State {
        if (barrelType === BarrelType.PROJECT) {
            // Linked styleguide data should not be lost, when a restricted user is deleted from a project.
            if (preserveLinkRelation) {
                return state;
            }

            // If deleted project is a linked project of a styleguide, it should be unlinked from that styleguide
            const styleguideToUnlink = Object.values(state.styleguides)
                .find(styleguide => styleguide.linkedProjects?.find(({ _id }) => _id === bid));

            if (styleguideToUnlink) {
                return this.unlinkProjectToStyleguide(
                    state,
                    { pid: bid, styleguide: (styleguideToUnlink as StyleguideLinkInfo) }
                );
            }

            return state;
        }

        const { [bid]: deletedStyleguide, ...remainingStyleguides } = state.styleguides;

        Object.values(remainingStyleguides).forEach(styleguide => {
            // Make deleted styleguide's parent its immediate children's parent
            if (styleguide.parent === bid) {
                styleguide.parent = deletedStyleguide.parent;
            }

            // Remove deleted styleguide id from ancestors of children styleguides
            if (styleguide.ancestors!.includes(bid)) {
                styleguide.ancestors = styleguide.ancestors!.filter(ancestor => ancestor !== bid);
            }
        });

        return {
            ...state,
            styleguides: {
                ...remainingStyleguides
            }
        };
    }

    linkProjectToStyleguideSuccess(state: State, {
        project,
        styleguide,
        styleguides
    }: ProjectActionPayloads.LinkProjectToStyleguideSuccess): State {
        const linkedState = this.reducer.updateBarrelValue(state, {
            bid: styleguide._id,
            key: "linkedProjects",
            value({ linkedProjects = [] }) {
                return [...linkedProjects, project];
            }
        });

        const newStyleguidesObject = styleguides.reduce((prevStyleguideObject, currentStyleguide) => ({
            ...prevStyleguideObject,
            [currentStyleguide._id]: new Barrel(currentStyleguide)
        }), {});

        return {
            ...linkedState,
            loadingSelectParent: false,
            styleguides: {
                ...linkedState.styleguides,
                ...newStyleguidesObject
            }
        };
    }

    unlinkProjectToStyleguide(
        state: State,
        { pid, styleguide }: Omit<ProjectActionPayloads.UnlinkProjectFromStyleguide, "type">
    ): State {
        return this.reducer.updateBarrelValue(state, {
            bid: styleguide._id,
            key: "linkedProjects",
            value({ linkedProjects = [] }: Barrel) {
                return linkedProjects.filter(project => project._id !== pid);
            }
        });
    }

    updateUserData(state: State, {
        bid,
        uid,
        userData
    }: BarrelActionPayloads.UpdateUserData): State {
        return this.reducer.updateBarrelValue(state, {
            bid,
            key: "users",
            value({ users }: Barrel) {
                return users.map(({ role, user }) => {
                    if (user._id !== uid) {
                        return { role, user };
                    }

                    return { role, user: { ...user, ...userData } };
                });
            }
        });
    }

    transferOwnership(state: State, {
        bid,
        affectedIds,
        fromUserId,
        targetId,
        toOrganization,
        users,
        oid
    }: Payloads.TransferOwnership | (BarrelActionPayloads.OwnershipTransferred & { users?: undefined; })): State {
        const targetStyleguide = state.styleguides[bid];

        if (!targetStyleguide) {
            return state;
        }

        // Find target styleguide and its children
        const targetAndChildrenStyleguides = affectedIds!.map(stid => state.styleguides[stid]);

        // Update ancestors
        if (toOrganization || oid !== "user") {
            const updatedStyleguides = targetAndChildrenStyleguides.reduce<BasicRecord<Barrel>>((acc, cur) => {
                // Delete target styleguide's parent so it forms a tree
                if (cur._id === bid && cur.parent) {
                    cur.ancestors = cur.ancestors!.filter(ancestor => ancestor !== cur.parent);
                    cur.parent = null;
                }

                // Remove target styleguide's parent from its children ancestors
                if (cur.ancestors!.includes(bid)) {
                    if (targetStyleguide.parent) {
                        cur.ancestors = cur.ancestors!.filter(ancestor => ancestor !== targetStyleguide.parent);
                    }
                }

                acc[cur._id] = cur;
                return acc;
            }, {});

            return {
                ...state,
                styleguides: {
                    ...state.styleguides,
                    ...updatedStyleguides
                }
            };
        }

        // Remove target styleguide's ancestors and update target styleguide's children's ancestors to not include removed ancestors
        const ancestorsUpdatedState = targetAndChildrenStyleguides.reduce((prevState, currentStyleguide) => (
            this.reducer.updateBarrelValue(prevState, {
                bid: currentStyleguide._id,
                key: "ancestors",
                value({ ancestors }: Barrel) {
                    return ancestors!.filter(ancestor => !targetStyleguide.ancestors!.includes(ancestor));
                }
            })
        ), state);

        // Remove target styleguide's parent
        const parentRemovedState = this.reducer.updateBarrelValue(ancestorsUpdatedState, {
            bid: targetStyleguide._id,
            key: "parent",
            value: null
        });

        // Swap each styleguide's roles if transferred to user
        const updatedState = targetAndChildrenStyleguides.reduce((prevState, currentStyleguide) => (
            this.reducer.updateBarrelValue(prevState, {
                bid: currentStyleguide._id,
                key: "users",
                value(styleguide: Barrel) {
                    return styleguide.users.map(userObj => {
                        if (users) {
                            if (userObj.user._id === fromUserId || userObj.user._id === targetId) {
                                const foundUser = users.find(userData => userObj.user._id === userData.user._id)!;
                                userObj.role = foundUser.role;
                                userObj.user.paymentPlan = foundUser.user.paymentPlan!;
                            }

                            return userObj;
                        }

                        if (userObj.user._id === targetId) {
                            userObj.role = "owner";
                        }

                        if (userObj.user._id === fromUserId) {
                            userObj.role = "admin";
                        }

                        return userObj;
                    });
                }
            })
        ), parentRemovedState);
        return updatedState;
    }

    load(state: State, {
        barrel: {
            barrelType
        },
        data: {
            project,
            styleguide,
            organizationStyleguides,
            linkedStyleguides,
            ancestors
        },
        user: {
            userId
        }
    }: BarrelActionPayloads.Load): State {
        if (barrelType === BarrelType.STYLEGUIDE) {
            const isMemberOfStyleguide = isUserMemberOfBarrel(styleguide, userId);
            if (!isMemberOfStyleguide) {
                return this.reducer.getBarrelSuccess(state, {
                    barrel: styleguide!
                });
            }

            let initialState = state;
            if (organizationStyleguides) {
                initialState = this.reducer.getBarrelsSuccess(state, { styleguides: organizationStyleguides });
            }

            return ancestors!.reduce((prevState, ancestor) => this.reducer.getBarrelSuccess(prevState, {
                barrel: ancestor
            }), this.reducer.getBarrelSuccess(initialState, {
                barrel: styleguide!
            }));
        }

        const isMemberOfProject = isUserMemberOfBarrel(project, userId);
        if (!isMemberOfProject) {
            return state;
        }

        const stateWithOrganizationStyleguides = organizationStyleguides
            ? this.reducer.getBarrelsSuccess(state, { styleguides: organizationStyleguides }
            ) : state;

        return linkedStyleguides!.reduce(
            (prevState, linkedStyleguide) => this.reducer.getBarrelSuccess(prevState, {
                barrel: linkedStyleguide
            }), stateWithOrganizationStyleguides
        );
    }

    moveResourcesSuccess(state: State, {
        bid,
        barrelType,
        targetStyleguideId,
        resourceType,
        resources
    }: BarrelActionPayloads.MoveResourcesSuccess): State {
        const keyInBarrel = resourceTypeToBarrelKey(resourceType);
        let removedFromCurrentBarrelState = state;
        if (barrelType === BarrelType.STYLEGUIDE) {
            // Remove moved resources from current barrel
            removedFromCurrentBarrelState = this.reducer.updateBarrelValue(state, {
                bid,
                key: keyInBarrel,
                value(styleguide: Barrel) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return (styleguide[keyInBarrel] as any[]).filter(resource => !resources.includes(resource));
                }
            });
        }

        const targetStyleguide = state.styleguides[targetStyleguideId];

        // If target styleguide is not in store or not full we can't update its resources, return only removed state
        if (targetStyleguide?.dataStage !== BarrelDataStage.Complete) {
            return removedFromCurrentBarrelState;
        }

        // Add resources to target styleguide
        return this.reducer.updateBarrelValue(removedFromCurrentBarrelState, {
            bid: targetStyleguideId,
            key: keyInBarrel,
            value(styleguide: Barrel) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return (styleguide[keyInBarrel] as any[]).concat(resources);
            }
        });
    }

    changeAssetName(state: State, action: BarrelActionPayloads.ChangeAssetName): State {
        return this.reducer.changeComponentAssetName(state, action);
    }

    joinStyleguideRequest(state: State, { barrelType }: BarrelActionPayloads.Join): State {
        if (barrelType !== BarrelType.STYLEGUIDE) {
            return state;
        }

        return {
            ...state,
            joinStyleguideInProgress: true
        };
    }

    joinStyleguideFinalize(state: State, { barrelType }: BarrelActionPayloads.StopLoadingJoin): State {
        if (barrelType !== BarrelType.STYLEGUIDE) {
            return state;
        }

        return {
            ...state,
            joinStyleguideInProgress: false
        };
    }
    // #endregion

    // #region Styleguides Specific Reducers
    createStyleguideRequest(state: State): State {
        return {
            ...state,
            createStyleguideInProgress: true
        };
    }

    createStyleguideFinalize(state: State): State {
        return {
            ...state,
            createStyleguideInProgress: false
        };
    }

    selectParent(state: State, {
        stid,
        parent
    }: Pick<Payloads.SelectParent, "stid" | "parent">): State {
        const targetStyleguide = state.styleguides[stid];

        const nextParentStyleguide = state.styleguides[parent];
        const nextAncestors = [...nextParentStyleguide.ancestors!, parent];

        // Set parent of target styleguide
        targetStyleguide.parent = parent;
        targetStyleguide.ancestors = nextAncestors;

        // Update ancestor property of children styleguides
        Object.values(state.styleguides).forEach(styleguide => {
            const targetStyleguideIndexInAncestors = styleguide.ancestors!.indexOf(stid);
            if (targetStyleguideIndexInAncestors !== -1) {
                styleguide.ancestors =
                    [...nextAncestors, ...styleguide.ancestors!.slice(targetStyleguideIndexInAncestors)];
            }
        });

        return {
            ...state,
            styleguides: { ...state.styleguides }
        };
    }

    removeParent(state: State, {
        stid
    }: Payloads.RemoveParent): State {
        const targetStyleguide = state.styleguides[stid];

        // Remove parent and ancestors of target styleguide
        targetStyleguide.ancestors = [];
        targetStyleguide.parent = null;

        // Remove parent & its ancestors from children styleguides' ancestors
        Object.values(state.styleguides).forEach(styleguide => {
            const targetStyleguideIndexInAncestors = styleguide.ancestors!.indexOf(stid);
            if (targetStyleguideIndexInAncestors !== -1) {
                styleguide.ancestors = styleguide.ancestors!.slice(targetStyleguideIndexInAncestors);
            }
        });

        return {
            ...state,
            styleguides: { ...state.styleguides }
        };
    }

    selectParentRequest(state: State): State {
        return {
            ...state,
            loadingSelectParent: true
        };
    }

    selectParentSuccess(state: State, {
        stid,
        parent,
        styleguides
    }: Payloads.SelectParentSuccess): State {
        const parentSelectedState = this.selectParent(state, { stid, parent });

        const newStyleguidesObject = styleguides.reduce((prevStyleguideObject, styleguide) => ({
            ...prevStyleguideObject,
            [styleguide._id]: new Barrel(styleguide)
        }), {});

        return {
            ...parentSelectedState,
            loadingSelectParent: false,
            styleguides: {
                ...parentSelectedState.styleguides,
                ...newStyleguidesObject
            }
        };
    }

    selectParentFailed(state: State): State {
        return {
            ...state,
            loadingSelectParent: false
        };
    }

    hydrateEmbeddedData(state: State, {
        styleguides,
        project,
        linkedStyleguides,
        styleguide,
        ancestors,
        user
    }: AppActionPayloads.HydrateEmbeddedData): State {
        let newState = state;

        if (styleguides) {
            newState = this.reducer.getBarrelsSuccess(state, { styleguides });
        }

        if (project) {
            const isMemberOfProject = isUserMemberOfBarrel(project, user._id);
            if (!isMemberOfProject) {
                return newState;
            }

            return linkedStyleguides!.reduce(
                (prevState, linkedStyleguide) => this.reducer.getBarrelSuccess(prevState, {
                    barrel: linkedStyleguide
                }), newState
            );
        }

        if (!styleguide) {
            return newState;
        }

        const isMemberOfStyleguide = isUserMemberOfBarrel(styleguide, user._id);
        if (!isMemberOfStyleguide) {
            return this.reducer.getBarrelSuccess(newState, {
                barrel: styleguide
            });
        }

        return ancestors!.reduce((prevState, ancestor) => this.reducer.getBarrelSuccess(prevState, {
            barrel: ancestor
        }), this.reducer.getBarrelSuccess(newState, {
            barrel: styleguide
        }));
    }
    // #endregion

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            // BarrelsReducer actions
            case StyleguidesActionTypes.GET_STYLEGUIDES_SUCCESS:
                return this.reducer.getBarrelsSuccess(state, action);
            case StyleguidesActionTypes.GET_STYLEGUIDE_REQUESTED:
                return this.reducer.getBarrelRequested(state, {
                    bid: action.stid
                });
            case StyleguidesActionTypes.GET_STYLEGUIDE_SUCCESS:
                return this.reducer.getBarrelSuccess(state, {
                    barrel: action.styleguide
                });
            case StyleguidesActionTypes.GET_STYLEGUIDE_FAILED:
                return this.reducer.getBarrelFailed(state, action);
            case StyleguidesActionTypes.UPDATE_STYLEGUIDE:
                return this.reducer.updateBarrel(state, { bid: action.stid, data: action.data });
            case BarrelActionTypes.UPDATE_NAME:
                return this.reducer.updateName(state, action);
            case BarrelActionTypes.UPDATE_DESCRIPTION:
                return this.reducer.updateDescription(state, action);
            case BarrelActionTypes.UPDATE_DENSITY_SCALE:
                return this.reducer.updateDensityScale(state, action);
            case BarrelActionTypes.UPDATE_THUMBNAIL:
                return this.reducer.updateThumbnail(state, action);
            case BarrelActionTypes.ADD_INTEGRATION:
                return this.reducer.addIntegration(state, action);
            case BarrelActionTypes.AUTHENTICATED_SLACK_INTEGRATION:
                return this.reducer.addIntegration(state, {
                    ...action,
                    intype: "slacks"
                });
            case BarrelActionTypes.REMOVE_INTEGRATION:
                return this.reducer.removeIntegration(state, action);
            case BarrelActionTypes.TOGGLE_SCENE:
                return this.reducer.toggleScene(state, action);
            case BarrelActionTypes.ADD_MEMBERS:
                return this.reducer.addMembers(state, action);
            case BarrelActionTypes.ADD_COLOR:
                return this.reducer.addColor(state, action);
            case BarrelActionTypes.ADD_MULTIPLE_COLORS:
                return this.reducer.addMultipleColors(state, action);
            case BarrelActionTypes.UPDATE_COLOR:
                return this.reducer.updateColor(state, action);
            case BarrelActionTypes.UPDATE_COLORS_NAME:
                return this.reducer.updateColorsName(state, action);
            case BarrelActionTypes.REMOVE_COLOR:
                return this.reducer.removeColor(state, action);
            case BarrelActionTypes.REMOVE_COLORS:
                return this.reducer.removeColors(state, action);
            case BarrelActionTypes.MIGRATE_COLORS:
                return this.reducer.migrateColors(state, action);
            case BarrelActionTypes.UPDATE_COLORS_ORDER:
                return this.reducer.updateColorsOrder(state, action);
            case BarrelActionTypes.ADD_TEXT_STYLE:
                return this.reducer.addTextStyle(state, action);
            case BarrelActionTypes.ADD_MULTIPLE_TEXT_STYLES:
                return this.reducer.addMultipleTextStyles(state, action);
            case BarrelActionTypes.UPDATE_TEXT_STYLE:
                return this.reducer.updateTextStyle(state, action);
            case BarrelActionTypes.REMOVE_TEXT_STYLE:
                return this.reducer.removeTextStyle(state, action);
            case BarrelActionTypes.REMOVE_ALL_TEXT_STYLES:
                return this.reducer.removeAllTextStyles(state, action);
            case BarrelActionTypes.ADD_SPACING_SECTION:
                return this.reducer.addSpacingSection(state, action);
            case BarrelActionTypes.UPDATE_SPACING_SECTION:
                return this.reducer.updateSpacingSection(state, action);
            case BarrelActionTypes.REMOVE_SPACING_SECTION:
                return this.reducer.removeSpacingSection(state, action);
            case BarrelActionTypes.ADD_SPACING_TOKEN:
                return this.reducer.addSpacingToken(state, action);
            case BarrelActionTypes.UPDATE_SPACING_TOKEN:
                return this.reducer.updateSpacingToken(state, action);
            case BarrelActionTypes.REMOVE_SPACING_TOKEN:
                return this.reducer.removeSpacingToken(state, action);
            case BarrelActionTypes.UPDATE_REM_PREFERENCES:
                return this.reducer.updateRemPreferences(state, action);
            case BarrelActionTypes.ADD_COMPONENT:
                return this.reducer.addComponent(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT:
                return this.reducer.updateComponent(state, action);
            case BarrelActionTypes.SET_COMPONENT_SNAPSHOT:
                return this.reducer.setComponentSnapshot(state, action);
            case BarrelActionTypes.SET_MULTIPLE_COMPONENT_SNAPSHOTS:
                return this.reducer.setMultipleComponentSnapshots(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT:
                return this.reducer.removeComponent(state, action);
            case BarrelActionTypes.REMOVE_COMPONENTS:
                return this.reducer.removeComponents(state, action);
            case BarrelActionTypes.ADD_COMPONENT_VERSION:
                return this.reducer.addComponentVersion(state, action);
            case BarrelActionTypes.ADD_COMPONENT_SECTION:
                return this.reducer.addComponentSection(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT_SECTION:
                return this.reducer.updateComponentSection(state, action);
            case BarrelActionTypes.MERGE_COMPONENT_SECTION:
                return this.reducer.mergeComponentSection(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT_SECTION:
                return this.reducer.removeComponentSection(state, action);
            case BarrelActionTypes.REMOVE_COMPONENT_SECTIONS:
                return this.reducer.removeComponentSections(state, action);
            case BarrelActionTypes.UPDATE_COMPONENTS_ORDER:
                return this.reducer.updateComponentsOrder(state, action);
            case BarrelActionTypes.UPDATE_COMPONENT_SECTIONS_ORDER:
                return this.reducer.updateComponentSectionsOrder(state, action);
            case BarrelActionTypes.SET_LOCAL_CONNECTED_COMPONENTS:
                return this.reducer.setLocalConnectedComponents(state, action);
            case BarrelActionTypes.UPDATE_CONNECTED_COMPONENTS:
                return this.reducer.updateConnectedComponents(state, action);
            case BarrelActionTypes.CREATE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.createConnectedComponentItem(state, action);
            case BarrelActionTypes.UPDATE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.updateConnectedComponentItem(state, action);
            case BarrelActionTypes.DELETE_CONNECTED_COMPONENT_ITEM:
                return this.reducer.deleteConnectedComponentItem(state, action);
            case BarrelActionTypes.UPDATE_WORKFLOW_STATUS:
                return this.reducer.updateWorkflowStatus(state, action);
            case BarrelActionTypes.DELETE_WORKFLOW_STATUS:
                return this.reducer.deleteWorkflowStatus(state, action);

            case BarrelActionTypes.UPDATE_USER_ROLE:
                return this.reducer.updateUserRole(state, action);
            case BarrelActionTypes.REMOVE_MEMBER:
                return this.reducer.removeMember(state, action);

            case BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_DESCRIPTION:
                return this.reducer.updateVariableCollectionDescription(state, action);
            case BarrelActionTypes.ADD_VARIABLE_COLLECTION:
                return this.reducer.addVariableCollection(state, action);
            case BarrelActionTypes.DELETE_VARIABLE_COLLECTION:
                return this.reducer.deleteVariableCollection(state, action);
            case BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_GROUP_DESCRIPTION:
                return this.reducer.updateVariableCollectionGroupDescription(state, action);
            case BarrelActionTypes.UPDATE_VARIABLE_ORDER:
                return this.reducer.updateVariableOrder(state, action);

            // Common Barrel Actions
            case BarrelActionTypes.ADD:
                return this.add(state, action);
            case BarrelActionTypes.ACTIVATE:
                return this.activateStyleguide(state, action);
            case BarrelActionTypes.ARCHIVE:
                return this.archiveStyleguide(state, action);
            case BarrelActionTypes.REMOVE:
                return this.remove(state, action);
            case ProjectActionTypes.LINK_PROJECT_SUCCESS:
                return this.linkProjectToStyleguideSuccess(state, action);
            case ProjectActionTypes.UNLINK_PROJECT:
                return this.unlinkProjectToStyleguide(state, action);
            case BarrelActionTypes.UPDATE_USER_DATA:
                return this.updateUserData(state, action);
            case StyleguidesActionTypes.TRANSFER_OWNERSHIP:
            case BarrelActionTypes.OWNERSHIP_TRANSFERRED:
                return this.transferOwnership(state, action);
            case BarrelActionTypes.LOAD:
                return this.load(state, action);
            case BarrelActionTypes.MOVE_RESOURCES_SUCCESS:
                return this.moveResourcesSuccess(state, action);
            case BarrelActionTypes.CHANGE_ASSET_NAME:
                return this.changeAssetName(state, action);
            case BarrelActionTypes.JOIN:
                return this.joinStyleguideRequest(state, action);
            case BarrelActionTypes.STOP_JOIN_LOADING:
                return this.joinStyleguideFinalize(state, action);

            // Organization Specific Actions
            case OrganizationActionTypes.DELETE_ORGANIZATION_WORKFLOW_STATUS:
                return this.reducer.onOrganizationWorkflowStatusDelete(state, action);
            case OrganizationActionTypes.REMOVE_UNJOINED_BARRELS:
                return this.reducer.removeUnjoinedBarrels(state, action);

            // Styleguides Specific Actions
            case StyleguidesActionTypes.CREATE_STYLEGUIDE_REQUEST:
                return this.createStyleguideRequest(state);
            case StyleguidesActionTypes.CREATE_STYLEGUIDE_FINALIZE:
                return this.createStyleguideFinalize(state);
            case StyleguidesActionTypes.SELECT_PARENT:
                return this.selectParent(state, action);
            case StyleguidesActionTypes.REMOVE_PARENT:
                return this.removeParent(state, action);
            case StyleguidesActionTypes.SELECT_PARENT_REQUEST:
                return this.selectParentRequest(state);
            case StyleguidesActionTypes.SELECT_PARENT_SUCCESS:
                return this.selectParentSuccess(state, action);
            case StyleguidesActionTypes.SELECT_PARENT_FAILED:
                return this.selectParentFailed(state);

            // Figma library sync actions
            case FigmaLibrarySyncActionTypes.UPDATE_STYLEGUIDE_SYNC_DATA:
                return this.updateStyleguideSyncData(state, action);
            case FigmaLibrarySyncActionTypes.REMOVE_FIGMA_SYNC_DATA:
                return this.removeFigmaSyncData(state, action);
            case FigmaLibrarySyncActionTypes.SYNC_STYLEGUIDE_LIBRARY:
                return this.getFigmaSyncStarted(state, action);
            case FigmaLibrarySyncActionTypes.UPDATE_SYNC_PROGRESS:
                return this.reducer.updateFigmaSyncProgress(state, action);
            case FigmaLibrarySyncActionTypes.UPDATE_STYLEGUIDE_SYNC_FILE:
                return this.onUpdateStyleguideSyncFile(state, action);
            case FigmaLibrarySyncActionTypes.SET_FIGMA_SYNC_DATA:
                return this.onUpdateStyleguideSyncData(state, action);

            // Component actions
            case ComponentActionTypes.SET_COMPONENT_VERSIONS:
                return this.reducer.setComponentVersions(state, action);
            case ComponentActionTypes.UPDATE_COMMIT_MESSAGE:
                return this.reducer.updateCommitMessage(state, action);
            case ComponentActionTypes.REMOVE_VERSION:
                return this.reducer.removeVersion(state, action);
            case ComponentActionTypes.SET_VERSION_DIFFS:
                return this.reducer.setVersionDiffs(state, action);

            case StyleguideActionTypes.CREATE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.addComponentVariantProperty(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.updateComponentVariantProperty(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_NAME:
                return this.reducer.updateComponentVariantPropertyName(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_VALUE:
                return this.reducer.updateComponentVariantPropertyValue(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTY_VALUES:
                return this.reducer.updateComponentVariantPropertyValues(state, action);
            case StyleguideActionTypes.UPDATE_COMPONENT_VARIANT_PROPERTIES_ORDER:
                return this.reducer.updateComponentVariantPropertiesOrder(state, action);
            case StyleguideActionTypes.SET_COMPONENT_VARIANT_DATA:
                return this.reducer.setComponentVariantData(state, action);
            case StyleguideActionTypes.REMOVE_COMPONENT_VARIANT_PROPERTY:
                return this.reducer.removeComponentVariantProperty(state, action);
            case StyleguideActionTypes.REMOVE_COMPONENT_VARIANT_DATA:
                return this.reducer.removeComponentVariantData(state, action);
            case StyleguideActionTypes.GROUP_COMPONENTS_BY_VARIANT_PROPERTY:
                return this.reducer.groupComponentsByVariantProperty(state, action);
            case BarrelActionTypes.UPDATE_JIRA_ISSUE:
                return this.reducer.updateJiraIssue(state, action);
            case AppActionTypes.LOAD_WORKSPACE_DATA:
                return this.reducer.getBarrelsSuccess(state, action);
            case AppActionTypes.RESET:
                return this.reset();
            case AppActionTypes.HYDRATE_EMBEDDED_DATA:
                return this.hydrateEmbeddedData(state, action);

            default:
                return state;
        }
    }
}

export default StyleguidesDataStore;
export { State as StyleguidesDataStoreState };
