enum ActionTypes {
    SET_VERSION_ASSETS = "assets/setVersionAssets",
    SET_COMPONENT_ASSETS = "assets/setComponentAssets",
    UPDATE_ASSETS = "assets/updateAssets",
    SET_INCOMPLETE_ASYNC_ASSETS = "assets/setIncompleteAsyncAssets",
    SET_ASYNC_ASSETS_OPTIMIZING = "assets/setAsyncAssetsOptimizing",
    SET_ASYNC_ASSETS_PROGRESS = "assets/setAsyncAssetsProgress",
    UPDATE_VERSION_ASSET_OPTIMIZED_CONTENTS = "assets/updateVersionAssetOptimizedContents",
    PROCESS_VERSION_ASSET_CONTENT_REQUEST = "assets/processVersionAssetContentRequest",
    PROCESS_VERSION_ASSET_CONTENT_FAILURE = "assets/processVersionAssetContentFailure",
    UPDATE_COMPONENT_VERSION_ASSET_OPTIMIZED_CONTENTS = "assets/updateComponentVersionAssetOptimizedContents",
    PROCESS_COMPONENT_VERSION_ASSET_CONTENT_REQUEST = "assets/processComponentVersionAssetContentRequest",
    PROCESS_COMPONENT_VERSION_ASSET_CONTENT_FAILURE = "assets/processComponentVersionAssetContentFailure",
    GET_ASSET_STATS = "assets/getAssetStats"
}

export default ActionTypes;
