/* eslint-disable class-methods-use-this */

import Dispatcher from "flux/lib/Dispatcher";
import ReduceStore from "flux/lib/FluxReduceStore";

import { ApprovalPendingDotData } from "../../../foundation/api/model/dots/ApprovalPendingDot";
import * as BarrelActionPayloads from "../barrel/BarrelActionPayloads";
import BarrelActionTypes from "../barrel/BarrelActionTypes";

import { AllPayloads } from "../payloads";

import ScreenActionTypes from "./ScreenActionTypes";

interface ApprovalPendingDotsRecord {
    [pid: string]: {
        [sid: string]: ApprovalPendingDotData[];
    };
}

type State = {
    approvalPendingDots: ApprovalPendingDotsRecord;
};

// This store is used to transfer saved approval dots from preferences to `DotsDataStore`.
// Once the data is transferred to `DotsDataStore`, this store is cleared (see `clearSavedApprovalPendingDots`).
// Do not rely on its data and do not consume it in mappers.
class ApprovalDotsDataStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            approvalPendingDots: {}
        };
    }

    setApprovalPendingDots(
        state: State,
        action: BarrelActionPayloads.Load
    ) {
        const { approvalPendingDots } = action.dots!;
        const pid = action.barrel.barrelId;

        if (!pid || !approvalPendingDots) {
            return state;
        }

        return {
            ...state,
            approvalPendingDots: {
                ...state.approvalPendingDots[pid],
                [pid]: {
                    ...approvalPendingDots
                }
            }
        };
    }

    updateApprovalPendingDots(
        state: State,
        action: { pid: string; sid: string; newDots: ApprovalPendingDotData[]; approvalsId: string; }
    ) {
        const {
            pid,
            sid,
            newDots
        } = action;

        if (!pid || !sid) {
            return state;
        }

        return {
            ...state,
            approvalPendingDots: {
                ...state.approvalPendingDots,
                [pid]: {
                    ...state.approvalPendingDots[pid],
                    [sid]: newDots
                }
            }
        };
    }

    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case BarrelActionTypes.LOAD:
                if (action?.dots?.approvalPendingDots) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return this.setApprovalPendingDots(state, action);
                }

                return state;

            case ScreenActionTypes.UPDATE_APPROVAL_PENDING_DOTS:
                return this.updateApprovalPendingDots(state, action);

            default:
                return state;
        }
    }
}

export default ApprovalDotsDataStore;
export { State as ApprovalDotsDataStoreState, ApprovalPendingDotsRecord };
