enum ActionTypes {
    SET_FIGMA_SYNC_REDIRECT_DATA = "figmaLibrarySync/setFigmaSyncRedirectData",
    SET_FIGMA_SYNC_ERROR_DATA = "figmaLibrarySync/setFigmaSyncErrorData",
    REMOVE_FIGMA_SYNC_DATA = "figmaLibrarySync/removeFigmaSyncData",
    RESET_FIGMA_SYNC_DATA = "figmaLibrarySync/resetFigmaSyncData",
    RESET_FIGMA_SYNC_ERROR_DATA = "figmaLibrarySync/resetFigmaSyncErrorData",
    UPDATE_STYLEGUIDE_SYNC_DATA = "figmaLibrarySync/updateStyleguideSyncData",
    OPEN_SYNC_PROGRESS_DIALOG = "figmaLibrarySync/openSyncProgressDialog",
    CLOSE_SYNC_PROGRESS_DIALOG = "figmaLibrarySync/closeSyncProgressDialog",
    OPEN_IMPORT_STYLEGUIDE_LIBRARY_DIALOG = "figmaLibrarySync/openImportStyleguideLibraryDialog",
    REDIRECT_FROM_FIGMA_LIBRARY_SYNC_PROGRESS_DIALOG = "figmaLibrarySync/redirectFromFigmaLibrarySyncProgressDialog",
    IMPORT_STYLEGUIDE_LIBRARY = "figmaLibrarySync/importStyleguideLibrary",
    SYNC_STYLEGUIDE_LIBRARY = "figmaLibrarySync/syncStyleguideLib",
    UPDATE_SYNC_PROGRESS = "figmaLibrarySync/updateSyncProgress",
    UPDATE_STYLEGUIDE_SYNC_FILE = "figmaLibrarySync/updateStyleguideSyncFile",
    START_IMPORT_STYLEGUIDE_LIBRARY_PROGRESS = "figmaLibrarySync/startImportStyleguideLibraryProgress",
    RETRY_STYLEGUIDE_LIBRARY_IMPORT = "figmaLibrarySync/retryStyleguideLibImport",
    IMPORT_LIBRARY_TO_CREATED_STYLEGUIDE = "figmaLibrarySync/importLibraryToCreatedStyleguide",
    GET_FIGMA_SYNC_DATA = "figmaLibrarySync/getFigmaSyncData",
    SET_FIGMA_SYNC_DATA = "figmaLibrarySync/setFigmaSyncData"
}

export default ActionTypes;
