/* eslint-disable class-methods-use-this */
import ReduceStore from "flux/lib/FluxReduceStore";

import uniquifyArrayOfObjects from "../../../foundation/utils/uniquify";

import AppActionTypes from "../app/AppActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { AllPayloads } from "../payloads";

import * as Payloads from "./ApprovalsActionPayloads";
import ApprovalsActionTypes from "./ApprovalsActionTypes";
import { ApprovalsRecord, ProjectApprovalsRecord, WorkspaceApprovalsRecord } from "./types";

interface State {
    isGetApprovalsPending: boolean;
    isCreateApprovalPending: boolean;
    isUpdateApprovalPending: boolean;
    isUpdateApprovalReviewPending: boolean;
    isDeleteApprovalReviewerPending: boolean;
    projectApprovalsRecord: ProjectApprovalsRecord;
    workspaceApprovalsRecord: WorkspaceApprovalsRecord;
}

class ApprovalsDataStore extends ReduceStore<State, AllPayloads> {
    getInitialState(): State {
        return {
            isGetApprovalsPending: true,
            isCreateApprovalPending: false,
            isUpdateApprovalPending: false,
            isUpdateApprovalReviewPending: false,
            isDeleteApprovalReviewerPending: false,
            projectApprovalsRecord: {},
            workspaceApprovalsRecord: {}
        };
    }

    #getProjectApprovals(state: State, pid: string): ApprovalsRecord | undefined {
        return state.projectApprovalsRecord[pid];
    }

    #getWorkspaceApprovals(state: State, oid: string): ApprovalsRecord | undefined {
        return state.workspaceApprovalsRecord[oid];
    }

    reset(state: State): State {
        return {
            ...this.getInitialState(),
            projectApprovalsRecord: state.projectApprovalsRecord,
            workspaceApprovalsRecord: state.workspaceApprovalsRecord
        };
    }

    getApprovalsSuccess(state: State, {
        pid,
        approvals
    }: Payloads.GetProjectApprovalsSuccess): State {
        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: Object.fromEntries(approvals.map(approval => [approval._id, approval]))
            },
            isGetApprovalsPending: false
        };
    }

    getApprovalsFinish(state: State): State {
        return {
            ...state,
            isGetApprovalsPending: false
        };
    }

    getWorkspaceApprovalsSuccess(state: State, {
        oid,
        approvals
    }: Payloads.GetWorkspaceApprovalsSuccess): State {
        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: Object.fromEntries(approvals.map(approval => [approval._id, approval]))
            }
        };
    }

    createApproval(state: State): State {
        return {
            ...state,
            isCreateApprovalPending: true
        };
    }

    createApprovalSuccess(state: State, {
        pid,
        approval
    }: Payloads.CreateApprovalSuccess): State {
        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: {
                    ...(this.#getProjectApprovals(state, pid) || {}),
                    [approval._id]: approval
                }
            },
            isCreateApprovalPending: false
        };
    }

    createApprovalFailure(state: State): State {
        return {
            ...state,
            isCreateApprovalPending: false
        };
    }

    addApproval(state: State, {
        pid,
        approval
    }: Payloads.AddApproval): State {
        const projectApprovals = this.#getProjectApprovals(state, pid);

        if (!projectApprovals) {
            return state;
        }

        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: {
                    ...projectApprovals,
                    [approval._id]: approval
                }
            }
        };
    }

    updateApproval(state: State): State {
        return {
            ...state,
            isUpdateApprovalPending: true
        };
    }

    updateApprovalSuccess(state: State, {
        pid,
        apid,
        payload,
        dots
    }: Payloads.UpdateApprovalSuccess): State {
        const projectApprovals = this.#getProjectApprovals(state, pid);
        const approval = projectApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: {
                    ...projectApprovals,
                    [apid]: {
                        ...approval,
                        name: payload.name || approval.name,
                        updatedAt: new Date().toISOString(),
                        note: payload.note || undefined,
                        dots: uniquifyArrayOfObjects([...(approval.dots || []), ...(dots || [])], "did")
                    }
                }
            },
            isUpdateApprovalPending: false
        };
    }

    updateApprovalFailure(state: State): State {
        return {
            ...state,
            isUpdateApprovalPending: false
        };
    }

    updateApprovalReview(state: State): State {
        return {
            ...state,
            isUpdateApprovalReviewPending: true
        };
    }

    updateApprovalReviewSuccess(state: State, {
        pid,
        apid,
        reviewerId,
        payload,
        dots
    }: Payloads.UpdateApprovalReviewSuccess): State {
        const projectApprovals = this.#getProjectApprovals(state, pid);
        const approval = projectApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: {
                    ...projectApprovals,
                    [apid]: {
                        ...approval,
                        dots: uniquifyArrayOfObjects([...(approval.dots || []), ...(dots || [])], "did"),
                        reviews: approval.reviews.map(review => {
                            if (review.reviewedBy?._id === reviewerId) {
                                return {
                                    ...review,
                                    ...payload,
                                    reviewedAt: new Date().toISOString()
                                };
                            }

                            return review;
                        })
                    }
                }
            },
            isUpdateApprovalReviewPending: false
        };
    }

    updateApprovalReviewFailure(state: State): State {
        return {
            ...state,
            isUpdateApprovalReviewPending: false
        };
    }

    deleteApprovalReviewer(state: State): State {
        return {
            ...state,
            isDeleteApprovalReviewerPending: true
        };
    }

    deleteApprovalReviewerSuccess(state: State, {
        pid,
        apid,
        aprid
    }: Payloads.DeleteApprovalReviewerSuccess): State {
        const projectApprovals = this.#getProjectApprovals(state, pid);
        const approval = projectApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: {
                    ...projectApprovals,
                    [apid]: {
                        ...approval,
                        reviews: approval.reviews.filter(review => review.reviewedBy?._id !== aprid)
                    }
                }
            },
            isDeleteApprovalReviewerPending: false
        };
    }

    deleteApprovalReviewerFailure(state: State): State {
        return {
            ...state,
            isDeleteApprovalReviewerPending: false
        };
    }

    deleteApproval(state: State, {
        pid,
        apid
    }: Payloads.DeleteApproval): State {
        const projectApprovals = this.#getProjectApprovals(state, pid);

        if (!projectApprovals) {
            return state;
        }

        const updatedProjectApprovals = { ...projectApprovals };

        delete updatedProjectApprovals[apid];

        return {
            ...state,
            projectApprovalsRecord: {
                ...state.projectApprovalsRecord,
                [pid]: updatedProjectApprovals
            }
        };
    }

    addWorkspaceApproval(state: State, {
        oid,
        approval
    }: Payloads.AddWorkspaceApproval): State {
        const workspaceApprovals = this.#getWorkspaceApprovals(state, oid);

        if (!workspaceApprovals) {
            return state;
        }

        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: {
                    ...workspaceApprovals,
                    [approval._id]: approval
                }
            }
        };
    }

    updateWorkspaceApproval(state: State, {
        oid,
        apid,
        payload
    }: Payloads.UpdateWorkspaceApproval): State {
        const workspaceApprovals = this.#getWorkspaceApprovals(state, oid);
        const approval = workspaceApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: {
                    ...workspaceApprovals,
                    [apid]: {
                        ...approval,
                        name: payload.name || approval.name,
                        updatedAt: new Date().toISOString(),
                        note: payload.note || undefined
                    }
                }
            }
        };
    }

    updateWorkspaceApprovalReview(state: State, {
        oid,
        apid,
        reviewerId,
        payload
    }: Payloads.UpdateWorkspaceApprovalReview): State {
        const workspaceApprovals = this.#getWorkspaceApprovals(state, oid);
        const approval = workspaceApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: {
                    ...workspaceApprovals,
                    [apid]: {
                        ...approval,
                        reviews: approval.reviews.map(review => {
                            if (review.reviewedBy?._id === reviewerId) {
                                return {
                                    ...review,
                                    ...payload,
                                    reviewedAt: new Date().toISOString()
                                };
                            }

                            return review;
                        })
                    }
                }
            }
        };
    }

    deleteWorkspaceApprovalReviewer(state: State, {
        oid,
        apid,
        aprid
    }: Payloads.DeleteWorkspaceApprovalReviewer): State {
        const workspaceApprovals = this.#getWorkspaceApprovals(state, oid);
        const approval = workspaceApprovals?.[apid];

        if (!approval) {
            return state;
        }

        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: {
                    ...workspaceApprovals,
                    [apid]: {
                        ...approval,
                        reviews: approval.reviews.filter(review => review.reviewedBy?._id !== aprid)
                    }
                }
            }
        };
    }

    deleteWorkspaceApproval(state: State, {
        oid,
        apid
    }: Payloads.DeleteWorkspaceApproval): State {
        const workspaceApprovals = this.#getWorkspaceApprovals(state, oid);

        if (!workspaceApprovals) {
            return state;
        }

        const updatedWorkspaceApprovals = { ...workspaceApprovals };

        delete updatedWorkspaceApprovals[apid];

        return {
            ...state,
            workspaceApprovalsRecord: {
                ...state.workspaceApprovalsRecord,
                [oid]: updatedWorkspaceApprovals
            }
        };
    }

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads): State {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset(state);

            case ApprovalsActionTypes.GET_PROJECT_APPROVALS_SUCCESS:
                return this.getApprovalsSuccess(state, action);
            case ApprovalsActionTypes.GET_PROJECT_APPROVALS_FINISH:
                return this.getApprovalsFinish(state);

            case ApprovalsActionTypes.GET_WORKSPACE_APPROVALS_SUCCESS:
                return this.getWorkspaceApprovalsSuccess(state, action);

            case ApprovalsActionTypes.CREATE_APPROVAL:
                return this.createApproval(state);
            case ApprovalsActionTypes.CREATE_APPROVAL_SUCCESS:
                return this.createApprovalSuccess(state, action);
            case ApprovalsActionTypes.CREATE_APPROVAL_FAILURE:
                return this.createApprovalFailure(state);
            case ApprovalsActionTypes.ADD_APPROVAL:
                return this.addApproval(state, action);

            case ApprovalsActionTypes.UPDATE_APPROVAL:
                return this.updateApproval(state);
            case ApprovalsActionTypes.UPDATE_APPROVAL_SUCCESS:
                return this.updateApprovalSuccess(state, action);
            case ApprovalsActionTypes.UPDATE_APPROVAL_FAILURE:
                return this.updateApprovalFailure(state);

            case ApprovalsActionTypes.UPDATE_APPROVAL_REVIEW:
                return this.updateApprovalReview(state);
            case ApprovalsActionTypes.UPDATE_APPROVAL_REVIEW_SUCCESS:
                return this.updateApprovalReviewSuccess(state, action);
            case ApprovalsActionTypes.UPDATE_APPROVAL_REVIEW_FAILURE:
                return this.updateApprovalReviewFailure(state);

            case ApprovalsActionTypes.DELETE_APPROVAL_REVIEWER:
                return this.deleteApprovalReviewer(state);
            case ApprovalsActionTypes.DELETE_APPROVAL_REVIEWER_SUCCESS:
                return this.deleteApprovalReviewerSuccess(state, action);
            case ApprovalsActionTypes.DELETE_APPROVAL_REVIEWER_FAILURE:
                return this.deleteApprovalReviewerFailure(state);

            case ApprovalsActionTypes.DELETE_APPROVAL:
                return this.deleteApproval(state, action);

            case ApprovalsActionTypes.ADD_WORKSPACE_APPROVAL:
                return this.addWorkspaceApproval(state, action);
            case ApprovalsActionTypes.UPDATE_WORKSPACE_APPROVAL:
                return this.updateWorkspaceApproval(state, action);
            case ApprovalsActionTypes.UPDATE_WORKSPACE_APPROVAL_REVIEW:
                return this.updateWorkspaceApprovalReview(state, action);
            case ApprovalsActionTypes.DELETE_WORKSPACE_APPROVAL_REVIEWER:
                return this.deleteWorkspaceApprovalReviewer(state, action);
            case ApprovalsActionTypes.DELETE_WORKSPACE_APPROVAL:
                return this.deleteWorkspaceApproval(state, action);

            default:
                return state;
        }
    }
}

export default ApprovalsDataStore;
export {
    State as ApprovalsDataStoreState
};
