import * as Sentry from "@sentry/browser";

import { SENTRY_DSN, SENTRY_ENVIRONMENT, APP_URL } from "../foundation/constants";
import { SENTRY_RELEASE } from "../foundation/mwConstants";
import { getStaticPathname } from "../foundation/utils/url";

const SAMPLE_RATE_DEV = 1.0;
const SAMPLE_RATE_PROD = 0.05;

const sampleRate = ["local", "development"].includes(SENTRY_ENVIRONMENT) ? SAMPLE_RATE_DEV : SAMPLE_RATE_PROD;
const traceTargets = ["app.zeplin.localhost", "app.omlet.io", "app.zeplin.io"];
const appUrlTargets = ["http://app.zeplin.localhost:8080", "https://app.omlet.io", "https://app.zeplin.io"];
const integrations = [
    Sentry.browserTracingIntegration({
        enableInp: true,
        beforeStartSpan: context => {
            const name = getStaticPathname(location.pathname);

            return {
                ...context,
                name
            };
        }
    }),
    // TODO: Profiling is not fully active. We need to update response headers
    // See: https://docs.sentry.io/platforms/javascript/profiling/browser-profiling/
    Sentry.browserProfilingIntegration()
];

// Ignore URLs
// https://docs.getsentry.com/hosted/clients/javascript/tips/#decluttering-sentry
Sentry.init({
    dsn: SENTRY_DSN,
    release: SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    blacklistUrls: [
        // Stripe
        /\.stripe\.com/i,
        // Intercom
        /widget\.intercom\.io/i,
        /js\.intercomcdn\.com/i,
        // Pusher
        /js\.pusher\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Ignore errors of vendor scripts.
        /vendor-[\w.-]+\.js/i
    ],
    ignoreErrors: [
        // Ignore fetch errors. Requests cancelled by browsers cause fetch to reject.
        // Since it is not possible to tell if it is a cancelled request or a failure we have to ignore them all :(
        /^Failed to fetch/i,
        /^NetworkError when attempting to fetch resource/i,
        /^Network request failed/i,
        /docs-homescreen-gb-container/i,
        /^QuotaExceededError/i,
        /^Can't find variable: (?:loadHomepageTiles|FSOpen|require|_Box_|\$UCBrowser|AlipayJSBridge|auto)/i,
        /__firefox__\.searchQueryForField/i,
        /TypeError: Type error/i,
        /^ResizeObserver loop completed with undelivered notifications/i
    ],
    integrations: appUrlTargets.some(urlTarget => urlTarget === APP_URL) ? integrations : undefined,
    beforeSend(event) {
        // Remove personal data from the event before sending, even if they are set somewhere else.
        if (event.user) {
            delete event.user.ip_address;
            delete event.user.email;
            delete event.user.username;
        }

        return event;
    },
    tracesSampleRate: sampleRate,
    tracePropagationTargets: traceTargets,
    // Total number of profile samples are calculated as tracesSampleRate * profilesSampleRate. So, it's pretty low. We can increase it if needed.
    profilesSampleRate: sampleRate
});

if (Zeplin.user) {
    Sentry.setUser({
        id: Zeplin.user._id
    });
} else {
    // Invalidate user context if there is no logged-in user
    Sentry.setUser(null);
}
