import Barrel from "../../../foundation/model/Barrel";
import fluxRuntime from "../fluxRuntime";

function getOrganizationStyleguides(oid: string): Barrel[] | undefined {
    const { organizationStyleguides } = fluxRuntime.OrganizationStore.getState();
    const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();

    const organizationStyleguideIds = organizationStyleguides[oid];
    return organizationStyleguideIds?.map(id => styleguides[id]);
}

export default {
    getOrganizationStyleguides
};
