
import { Approval } from "../../../foundation/api/model";
import { ApprovalPendingDotData } from "../../../foundation/api/model/dots/ApprovalPendingDot";
import Screen from "../../../foundation/model/Screen";
import VariantGroup from "../../../foundation/model/VariantGroup";
import { barrelPrefs } from "../../../foundation/prefs";
import uniquifyArrayOfObjects from "../../../foundation/utils/uniquify";
import BarrelHelpers from "../barrel/BarrelHelpers";
import fluxRuntime from "../fluxRuntime";

interface ScreenWithVariants extends Screen {
    variantGroup?: VariantGroup;
    variantsCount?: number;
    firstVariant?: Screen;
}

function getApproval({ pid, apid }: { pid: string | null; apid: string | null; }): Approval | undefined {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;

    if (!projectId || !apid) {
        return undefined;
    }

    const { projectApprovalsRecord } = fluxRuntime.ApprovalsDataStore.getState();

    return projectApprovalsRecord[projectId]?.[apid];
}

function getSortedApprovalScreens(apid: string | null): ScreenWithVariants[] {
    const pid = fluxRuntime.BarrelStore.getState().barrelId;
    const sids = getApproval({ pid, apid })?.screens ?? [];
    const screensToRender: ScreenWithVariants[] = [];

    if (!sids?.length) {
        return [];
    }

    const { screens = [] } = BarrelHelpers.getProject({ pid }) ?? {};
    const projectScreens = new Map((screens as Screen[]).map(projectScreen => (
        [projectScreen._id, projectScreen]
    )));
    const screensMap = new Map<string, Screen[]>();

    sids?.forEach(id => {
        const correspondingScreen = projectScreens.get(id)!;
        const variantGroup = BarrelHelpers.getVariantGroupOfScreen(id);

        if (!variantGroup) {
            const nonVariants = screensMap.get("nonVariants") ?? [];

            nonVariants.push(correspondingScreen);
            screensMap.set("nonVariants", nonVariants);

            // screensToRender.push(correspondingScreen!);
        } else {
            const correspondingVariants = screensMap.get(variantGroup._id) ?? [];

            correspondingVariants.push(correspondingScreen);
            screensMap.set(variantGroup._id, correspondingVariants);
        }
    });

    sids?.forEach(id => {
        const correspondingScreen = projectScreens.get(id)!;
        const variantGroup = BarrelHelpers.getVariantGroupOfScreen(id);

        // if it's already pushed, go to next iteration.
        if (screensToRender.find(({ _id }) => _id === id)) {
            return;
        }

        if (!variantGroup) {
            screensToRender.push(correspondingScreen!);
        } else {
            const correspondingVariantScreens = screensMap.get(variantGroup._id)!;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            screensToRender.push({
                name: variantGroup.name,
                _id: variantGroup._id,
                variantsCount: correspondingVariantScreens.length,
                firstVariant: correspondingScreen
            });

            correspondingVariantScreens.forEach(variantScreen => {
                screensToRender.push({
                    ...variantScreen,
                    variantGroup
                });
            });
        }
    });

    return uniquifyArrayOfObjects(screensToRender, "_id");
}

function getScreenApprovals({ pid, sid }: { pid: string | null; sid?: string; }): Approval[] {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;
    const screenId = sid ?? fluxRuntime.InspectableViewStore.getState().inspectableId;

    if (!projectId || !screenId) {
        return [];
    }

    const { projectApprovalsRecord } = fluxRuntime.ApprovalsDataStore.getState();

    return Object.values(projectApprovalsRecord[projectId] || {})
        .filter(approval => approval.screens.includes(screenId));
}

async function getApprovalPendingDots(
    { pid, sid, apid }: { pid?: string; sid?: string; apid: string; }
): Promise<ApprovalPendingDotData[]> {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId;

    if (!projectId || !apid) {
        return [];
    }

    const approvalSids = getApproval({ pid: projectId, apid })!.screens;
    const projectPrefs = await barrelPrefs.getAll(pid);
    let approvalPendingDotsInProject: ApprovalPendingDotData[] = [];

    approvalSids?.forEach(apSid => {
        const screenDots = (projectPrefs[apSid]?.approvalPendingDots as ApprovalPendingDotData[] | undefined);
        const approvalPendingDotsInScreen = screenDots?.filter(apDot => apDot.approvalsId === apid);

        if (!approvalPendingDotsInScreen?.length) {
            return;
        }

        approvalPendingDotsInProject.push(...approvalPendingDotsInScreen);
    });

    // if sid is provided, return only relative screens' approvalPending dots.
    if (sid) {
        approvalPendingDotsInProject = approvalPendingDotsInProject
            .filter(({ screenId }) => screenId === sid);
    }

    return uniquifyArrayOfObjects(approvalPendingDotsInProject, "_id");
}

function getApprovalPendingDotCount({ pid, apid }: {
    pid?: string; apid: string;
}): number {
    const projectId = pid ?? fluxRuntime.BarrelStore.getState().barrelId!;
    const { approvalPendingDots } = fluxRuntime.ApprovalDotsDataStore.getState();

    return (Object.values(approvalPendingDots?.[projectId] || {}).flat() as ApprovalPendingDotData[])
        .filter(({ approvalsId }) => approvalsId === apid).length;
}

export default {
    getApproval,
    getSortedApprovalScreens,
    getScreenApprovals,
    getApprovalPendingDots,
    getApprovalPendingDotCount
};
