import { SectionComponents, CreateConnectedComponentItemParameters } from "../../../foundation/api/model";
import ApiBarrel from "../../../foundation/api/model/barrels/Barrel";
import ApiBarrelUser from "../../../foundation/api/model/barrels/BarrelUser";
import { Project, CompleteProject } from "../../../foundation/api/model/barrels/Project";
import { Styleguide, CompleteStyleguide } from "../../../foundation/api/model/barrels/Styleguide";
import ApiColor from "../../../foundation/api/model/colors/Color";
import ComponentVersion from "../../../foundation/api/model/components/ComponentVersion";
import ConnectedComponent from "../../../foundation/api/model/connectedComponents/ConnectedComponent";
import Snapshot from "../../../foundation/api/model/snapshots/Snapshot";
import SpacingSection from "../../../foundation/api/model/spacings/SpacingSection";
import SpacingToken from "../../../foundation/api/model/spacings/SpacingToken";
import { VariableCollection } from "../../../foundation/api/model/variables/Variables";
import { DialogParams } from "../../../foundation/dialogParams";
import { DialogTypes, ProjectViewTypes, PLATFORM } from "../../../foundation/enums";

import { DownloadableAsset } from "../../../foundation/model/Asset";

import AssetDownloadTarget from "../../../foundation/model/AssetDownloadTarget";
import Barrel from "../../../foundation/model/Barrel";
import BarrelType from "../../../foundation/model/BarrelType";
import FoundationBarrelUser from "../../../foundation/model/BarrelUser";
import FoundationColor from "../../../foundation/model/color";
import ColorDefinition from "../../../foundation/model/ColorDefinition";
import ColorFormatPreference from "../../../foundation/model/ColorFormatPreference";
import Component from "../../../foundation/model/Component";
import ComponentSection from "../../../foundation/model/ComponentSection";
import Font from "../../../foundation/model/font";
import JiraAttachment from "../../../foundation/model/JiraAttachment";
import JiraIntegration from "../../../foundation/model/JiraIntegration";
import NamingConvention from "../../../foundation/model/NamingConvention";
import Organization from "../../../foundation/model/Organization";
import RemPreferences from "../../../foundation/model/RemPreferences";
import SidebarTabType from "../../../foundation/model/SidebarTab";
import SlackIntegration from "../../../foundation/model/SlackIntegration";
import TextStyleDefinition from "../../../foundation/model/TextStyleDefinition";
import TrackerEventData from "../../../foundation/model/TrackerEventData";
import User, { UserData } from "../../../foundation/model/User";
import WorkflowStatus from "../../../foundation/model/WorkflowStatus";

import BasicRecord from "../../../foundation/utils/BasicRecord";
import { getRandomString } from "../../../foundation/utils/string";
import { getStyleguideAndItsDescendants } from "../../../foundation/utils/styleguide";
import { PartialOnly } from "../../../foundation/utils/UtilityTypes";
import FlowHelpers from "../flow/FlowHelpers";
import OrganizationHelpers from "../organization/OrganizationHelpers";
import OpenScreenHelpers from "../screen/OpenScreenHelpers";

import fluxRuntime from "../fluxRuntime";

import * as Payloads from "./BarrelActionPayloads";

import AutoFillProps, {
    BarrelActionsSyncableAutoFillProps as SyncableAutoFillProps
} from "./BarrelActionsAutoFillProps";
import BarrelActionTypes from "./BarrelActionTypes";
import BarrelHelpers from "./BarrelHelpers";
import { InviteMemberErrorDialogInfo } from "./BarrelInviteMemberErrorDialogInfo";
import BarrelJoinActionSource from "./BarrelJoinActionSource";
import ComponentSnapshotPayload from "./ComponentSnapshotPayload";
import ConnectedComponentsEditor from "./ConnectedComponentsEditor";
import ConnectedComponentsEditorInfo from "./ConnectedComponentsEditorInfo";

const ID_LENGTH = 24;

function fillPayloadIfEmpty<T extends AutoFillProps>(action: T = {} as T):
    T & Required<AutoFillProps> {
    const bid = action.bid ?? fluxRuntime.BarrelStore.getState().barrelId!;
    const barrelType = action.barrelType ?? fluxRuntime.BarrelStore.getState().barrelType;

    return {
        ...action,
        bid,
        barrelType
    };
}

function shouldFetchSnapshot(coid: string): boolean {
    const barrel = BarrelHelpers.getBarrel();
    if (!barrel) {
        return false;
    }

    const { selectedView } = fluxRuntime.BarrelStore.getState();
    const { inspectableId } = fluxRuntime.InspectableViewStore.getState();

    return !BarrelHelpers.isUserRestricted() &&
        !OrganizationHelpers.isOrganizationFrozen() &&
        selectedView === ProjectViewTypes.COMPONENT &&
        inspectableId === coid;
}

const BarrelActions = {
    reset(params: Omit<Payloads.Reset, "type"> = {}) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.RESET,
            ...params
        });
    },
    load(params: Omit<Payloads.Load, "type">) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.LOAD,
            ...params
        });
    },
    loadIntermediate(params: Omit<Payloads.LoadIntermediate, "type">) {
        const {
            sid, vid, did, cmid, atid
        } = params.screenData;

        const openScreenData = OpenScreenHelpers
            .getOpenScreenData({
                sid, vid, did, cmid, atid, stageMode: false, project: params?.barrelData?.data?.project
            });
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.LOAD_INTERMEDIATE,
            ...params,
            openScreenData
        } as Payloads.LoadIntermediate);
    },
    preloadProjectDashboard(params: Omit<Payloads.PreloadDashboard, "type">) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.PRELOAD_PROJECT_DASHBOARD,
            ...params
        });
    },
    // MARK: Setters
    setOrganizationId({ oid }: {
        oid: string;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_ORGANIZATION_ID,
            oid
        });
    },
    addToSlack(action?: AutoFillProps) {
        const { bid, barrelType } = fillPayloadIfEmpty(action);
        const { name } = BarrelHelpers.getBarrel({ bid })!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_TO_SLACK,
            bid,
            barrelType,
            name
        });
    },
    authenticateSlackIntegration(bid: string) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.AUTHENTICATE_SLACK_INTEGRATION,
            bid
        });
    },
    authenticatingSlackIntegrationFailed(bid: string) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.AUTHENTICATE_SLACK_INTEGRATION_FAILED,
            bid
        });
    },
    authenticatedSlackIntegration({ bid, barrelType, integration, events }: {
        bid: string;
        barrelType: BarrelType;
        integration: SlackIntegration;
        events: string[];
    }) {
        const { barrelId } = fluxRuntime.BarrelStore.getState();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.AUTHENTICATED_SLACK_INTEGRATION,
            bid,
            barrelType,
            integration,
            events,
            openPreferences: barrelId === bid
        });
    },

    // MARK: Data
    add({
        oid, oseid, index, barrel, barrelType, shouldHighlight = false, syncWithApi = false
    }: {
        oid: string | null;
        oseid?: string | null;
        index?: number;
        barrel: CompleteProject | CompleteStyleguide | Project | Styleguide | Barrel;
        barrelType: BarrelType;
        shouldHighlight?: boolean;
        syncWithApi?: boolean;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD,
            oid,
            oseid,
            index,
            barrel,
            barrelType,
            shouldHighlight,
            syncWithApi
        });
    },
    activate(action: SyncableAutoFillProps) {
        const {
            bid,
            barrelType,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let affectedIds = [bid];
        if (barrelType === BarrelType.STYLEGUIDE) {
            const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();
            affectedIds = getStyleguideAndItsDescendants(styleguides, bid);
        }

        const oid = fluxRuntime.SpaceStore.getState().selectedOrganizationId;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ACTIVATE,
            oid,
            bid,
            barrelType,
            affectedIds,
            syncWithApi
        });
    },
    archive(action: SyncableAutoFillProps & {
        uid?: string;
    }) {
        const {
            bid,
            barrelType,
            uid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const { barrelId: currentBid, organizationId } = fluxRuntime.BarrelStore.getState();
        const { name } = BarrelHelpers.getBarrel({ bid })!;

        let affectedIds = [bid];
        if (barrelType === BarrelType.STYLEGUIDE) {
            const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();
            affectedIds = getStyleguideAndItsDescendants(styleguides, bid);
        }

        const currentUserId = fluxRuntime.UserStore.getState().userId!;
        const user = BarrelHelpers.findUser({ bid, uid });

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ARCHIVE,
            bid,
            barrelType,
            currentBid,
            affectedIds,
            name,
            uid,
            currentUserId,
            user,
            organizationId,
            syncWithApi
        });
    },
    remove(action: SyncableAutoFillProps & {
        uid?: string;
    }) {
        const {
            bid,
            barrelType,
            uid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const { barrelId: currentBid } = fluxRuntime.BarrelStore.getState();
        const oldBarrel = BarrelHelpers.getBarrel({ bid })!;
        const oid = BarrelHelpers.findBarrelOrganizationId(bid);

        const oseid = barrelType === BarrelType.PROJECT
            ? OrganizationHelpers.findProjectOrganizationSectionId(bid)
            : null;

        const currentUserId = fluxRuntime.UserStore.getState().userId!;
        const user = BarrelHelpers.findUser({ bid, uid });

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE,
            oid,
            oseid,
            bid,
            barrelType,
            currentBid,
            oldBarrel,
            uid,
            currentUserId,
            user,
            syncWithApi
        });
    },
    copy(action: SyncableAutoFillProps & {
        barrelName: string;
        options: {
            users: boolean;
            dots?: boolean;
            versions?: boolean;
            flows?: boolean;
        };
        shouldNavigateToBarrel?: boolean;
        handleOpenDialog: (dialogType: DialogTypes, dialogParams?: DialogParams) => void;
        handleCloseDialog: () => void;
    }) {
        const {
            bid,
            barrelName,
            barrelType,
            options,
            shouldNavigateToBarrel = false,
            handleOpenDialog,
            handleCloseDialog,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const oid = BarrelHelpers.findBarrelOrganizationId(bid)!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.COPY,
            bid,
            oid,
            barrelName,
            barrelType,
            options,
            shouldNavigateToBarrel,
            handleOpenDialog,
            handleCloseDialog,
            syncWithApi
        });
    },
    updateName(action: SyncableAutoFillProps & {
        name: string;
    }) {
        const {
            bid,
            barrelType,
            name,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldName: string | undefined;
        if (syncWithApi) {
            ({ name: oldName } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_NAME,
            bid,
            barrelType,
            name,
            oldName,
            syncWithApi
        });
    },
    updateDescription(action: SyncableAutoFillProps & {
        description: string;
    }) {
        const {
            bid,
            barrelType,
            description,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldDescription: string | undefined;
        if (syncWithApi) {
            ({ description: oldDescription } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_DESCRIPTION,
            bid,
            barrelType,
            description,
            oldDescription,
            syncWithApi
        });
    },
    setEditingDescription(isEditing: boolean) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_EDITING_DESCRIPTION,
            isEditing
        });
    },
    updateDensityScale(action: SyncableAutoFillProps & {
        densityScale: number;
    }) {
        const {
            bid,
            barrelType,
            densityScale,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldDensityScale: number | undefined;
        if (syncWithApi) {
            ({ densityScale: oldDensityScale } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_DENSITY_SCALE,
            bid,
            barrelType,
            densityScale,
            oldDensityScale,
            syncWithApi
        });
    },
    updateThumbnail(action: SyncableAutoFillProps & {
        thumbnail: string;
    }) {
        const {
            bid,
            barrelType,
            thumbnail,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldThumbnail: string | undefined;
        if (syncWithApi) {
            ({ thumbnail: oldThumbnail } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_THUMBNAIL,
            bid,
            barrelType,
            thumbnail,
            oldThumbnail,
            syncWithApi
        });
    },
    addIntegration(action: SyncableAutoFillProps & {
        intype: "slacks";
        integration: SlackIntegration;
    }) {
        const {
            bid,
            intype,
            integration,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_INTEGRATION,
            bid,
            intype,
            integration,
            syncWithApi
        });
    },
    removeIntegration(action: SyncableAutoFillProps & {
        intype: "slacks";
        inid: string;
    }) {
        const {
            bid,
            barrelType,
            intype,
            inid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const barrel = BarrelHelpers.getBarrel({ bid })!;

        let removedIntegration: SlackIntegration | undefined;
        if (syncWithApi) {
            removedIntegration = barrel.integration![intype].find(({ _id }) => _id === inid);
        }

        const isLastSlackIntegration = barrel.integration!.slacks.length === 1;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_INTEGRATION,
            bid,
            barrelType,
            intype,
            inid,
            removedIntegration,
            syncWithApi,
            isLastSlackIntegration
        });
    },
    toggleScene(action: SyncableAutoFillProps & {
        accessibility: boolean;
    }) {
        const {
            bid,
            barrelType,
            accessibility,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldAccessibility: boolean | undefined;
        if (syncWithApi) {
            ({ public: oldAccessibility } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.TOGGLE_SCENE,
            bid,
            barrelType,
            accessibility,
            oldAccessibility,
            syncWithApi
        });
    },
    enableScene() {
        BarrelActions.toggleScene({ accessibility: true, syncWithApi: true });
    },
    unshareScene() {
        BarrelActions.toggleScene({ accessibility: false, syncWithApi: true });
    },
    addMembers(action: SyncableAutoFillProps & {
        members: ApiBarrelUser[];
        withInvite?: boolean;
    }) {
        const {
            bid,
            barrelType,
            members,
            withInvite = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_MEMBERS,
            bid,
            barrelType,
            members,
            withInvite
        });
    },
    updateUserRole(action: SyncableAutoFillProps & {
        uid: string;
        role: string;
    }) {
        const {
            bid,
            barrelType,
            uid,
            role,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldRole: string | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ role: oldRole } = barrel.findUser(uid)!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_USER_ROLE,
            bid,
            barrelType,
            uid,
            role,
            oldRole,
            syncWithApi
        });
    },
    updateUserData(action: SyncableAutoFillProps & {
        uid: string;
        userData: Partial<UserData>;
    }) {
        const {
            bid,
            uid,
            userData,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldUserData: User | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ user: oldUserData } = barrel.findUser(uid)!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_USER_DATA,
            bid,
            uid,
            userData,
            oldUserData,
            syncWithApi
        });
    },
    transferOwnership(action: SyncableAutoFillProps & {
        uid: string;
    }) {
        const {
            bid,
            barrelType,
            uid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.TRANSFER_OWNERSHIP,
            syncWithApi,
            bid,
            barrelType,
            uid
        });
    },
    ownershipTransferred(action: AutoFillProps & {
        uid: string;
    }) {
        const {
            bid,
            barrelType,
            uid: targetId
        } = fillPayloadIfEmpty(action);

        const { organizationId: oid } = fluxRuntime.BarrelStore.getState();
        const fromUserId = fluxRuntime.UserStore.getState().userId!;

        let affectedIds: string[] | undefined;
        if (barrelType === BarrelType.STYLEGUIDE) {
            const { styleguides } = fluxRuntime.StyleguidesDataStore.getState();
            affectedIds = getStyleguideAndItsDescendants(styleguides, bid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.OWNERSHIP_TRANSFERRED,
            toOrganization: false,
            fromUserId,
            targetId,
            oid: oid!,
            bid,
            affectedIds
        });
    },
    removeMember(action: SyncableAutoFillProps & {
        uid?: string;
        mid: string;
    }) {
        const {
            bid,
            uid,
            mid,
            barrelType,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const oid = BarrelHelpers.findBarrelOrganizationId(bid)!;
        const { restricted } = OrganizationHelpers.getOrganizationProfile(oid) ?? {};
        const oseid = oid !== "user" ? OrganizationHelpers.findProjectOrganizationSectionId(bid) : null;
        const { barrelId: currentBid } = fluxRuntime.BarrelStore.getState();
        const currentUserId = fluxRuntime.UserStore.getState().userId!;
        const shouldRemoveBarrel = (restricted || oid === "user") && mid === currentUserId;
        const removingUser = BarrelHelpers.findUser({ bid, uid });

        let removedBarrel: Barrel | undefined;
        let removedMember: ApiBarrelUser | FoundationBarrelUser | undefined;
        if (shouldRemoveBarrel) {
            removedBarrel = BarrelHelpers.getBarrel({ bid })!;
        } else {
            removedMember = BarrelHelpers.findUser({ bid, uid: mid })!;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_MEMBER,
            oid,
            bid,
            uid,
            mid,
            oseid,
            barrelType,
            currentBid,
            currentUserId,
            removingUser,
            shouldRemoveBarrel,
            removedBarrel,
            removedMember,
            syncWithApi
        });
    },
    addColor(action: SyncableAutoFillProps & {
        color: ColorDefinition;
    }) {
        const {
            bid,
            barrelType,
            color: data,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let color = data;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            const {
                id: _id = `newColor${getRandomString(ID_LENGTH)}`,
                name = barrel.getColorName(new FoundationColor(data))
            } = data;

            color = {
                ...data,
                name,
                _id
            };
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_COLOR,
            bid,
            barrelType,
            color,
            syncWithApi
        });
    },
    addMultipleColors(action: SyncableAutoFillProps & {
        colors: ColorDefinition[];
    }) {
        const actionWithIdsFilled = fillPayloadIfEmpty(action);
        const { bid, barrelType, syncWithApi = false } = actionWithIdsFilled;
        let { colors } = actionWithIdsFilled;

        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            colors = colors.map(color => {
                const name = color.name ? color.name : barrel.getColorName(new FoundationColor(color));
                const _id = color.id ?? `newColor${getRandomString()}`;

                return {
                    ...color,
                    name,
                    _id
                };
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_MULTIPLE_COLORS,
            bid,
            barrelType,
            colors,
            syncWithApi
        });
    },
    updateColor(action: SyncableAutoFillProps & {
        cid: string;
        colorData: Partial<ColorDefinition>;
        trackerEvent?: TrackerEventData;
    }) {
        const {
            bid,
            barrelType,
            cid,
            colorData,
            trackerEvent,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldColorData: ColorDefinition | undefined;
        // We only add colors to current barrel
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ ...oldColorData } = barrel.findColorById(cid) as FoundationColor);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COLOR,
            bid,
            barrelType,
            cid,
            colorData,
            oldColorData,
            trackerEvent,
            syncWithApi
        });
    },
    updateColorsName(action: SyncableAutoFillProps & {
        colorsData: Partial<ColorDefinition>[];
        trackerEvent?: TrackerEventData;
    }) {
        const {
            bid,
            barrelType,
            colorsData,
            trackerEvent,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const oldColorsData: ColorDefinition[] = [];
        // We only add colors to current barrel
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            colorsData.forEach(colorData => {
                if (colorData._id) {
                    const { ...oldColorData } = barrel.findColorById(colorData._id) as FoundationColor;
                    oldColorsData.push(oldColorData);
                }
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COLORS_NAME,
            bid,
            barrelType,
            colorsData,
            oldColorsData,
            trackerEvent,
            syncWithApi
        });
    },
    removeColor(action: SyncableAutoFillProps & {
        cid: string;
    }) {
        const {
            bid,
            barrelType,
            cid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let removedColor: FoundationColor | undefined;
        // We only remove colors from current barrel
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            removedColor = barrel.findColorById(cid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COLOR,
            bid,
            barrelType,
            cid,
            removedColor,
            syncWithApi
        });
    },
    removeColors(action: SyncableAutoFillProps & {
        cids: string[];
        trackerEvent?: TrackerEventData;
    }) {
        const {
            bid,
            cids,
            barrelType,
            trackerEvent,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const barrel = BarrelHelpers.getBarrel({ bid })!;
        const colorsToRemove = cids.map(id => barrel.findColorById(id)!);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COLORS,
            bid,
            barrelType,
            colorsToRemove,
            trackerEvent,
            syncWithApi
        });
    },
    removeAllColors(action: SyncableAutoFillProps) {
        const { bid, barrelType, syncWithApi = false } = fillPayloadIfEmpty(action);

        let colorsToRemove: FoundationColor[] | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            colorsToRemove = barrel.colors;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COLORS,
            bid,
            barrelType,
            colorsToRemove,
            syncWithApi
        });
    },
    migrateColors(action: {
        bid: string;
        barrelType: BarrelType;
        colorsToUpdate: BasicRecord<ApiColor>;
        colorsToAdd: FoundationColor[];
        hasLinkedStyleguideColor: boolean;
        syncWithApi?: boolean;
    }) {
        const {
            bid,
            barrelType,
            colorsToUpdate,
            colorsToAdd,
            hasLinkedStyleguideColor,
            syncWithApi = false
        } = action;

        const oldDataOfColorsToUpdate: BasicRecord<FoundationColor> = {};

        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            Object.keys(colorsToUpdate).forEach(cid => {
                const oldColorData = barrel.findColorById(cid)!;
                oldDataOfColorsToUpdate[cid] = oldColorData;
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MIGRATE_COLORS,
            bid,
            barrelType,
            colorsToUpdate,
            oldDataOfColorsToUpdate,
            colorsToAdd,
            hasLinkedStyleguideColor,
            syncWithApi
        });
    },
    updateColorsOrder(action: SyncableAutoFillProps & {
        colors: {
            _id: string;
            to: number;
            revertTo?: number;
        }[];
        trackEvent?: boolean;
    }) {
        const {
            bid,
            barrelType,
            colors,
            trackEvent,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COLORS_ORDER,
            bid,
            barrelType,
            colors,
            trackEvent,
            syncWithApi
        });
    },
    addTextStyle(action: SyncableAutoFillProps & {
        textStyle: PartialOnly<TextStyleDefinition, "name">;
    }) {
        const {
            bid,
            barrelType,
            textStyle: data,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let textStyle = data;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            const {
                id: _id = `newTextStyle${getRandomString(ID_LENGTH)}`,
                name = barrel.getTextStyleName()
            } = data;

            textStyle = {
                ...data,
                name,
                _id
            };
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_TEXT_STYLE,
            bid,
            barrelType,
            textStyle: textStyle as TextStyleDefinition,
            syncWithApi
        });
    },
    addMultipleTextStyles(action: SyncableAutoFillProps & {
        textStyles: PartialOnly<TextStyleDefinition, "name">[];
    }) {
        const actionWithIdsFilled = fillPayloadIfEmpty(action);
        const { bid, barrelType, syncWithApi = false } = actionWithIdsFilled;
        let { textStyles } = actionWithIdsFilled;

        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            textStyles = textStyles.map(textStyle => {
                const name = textStyle.name ? textStyle.name : barrel.getTextStyleName();
                const _id = textStyle.id ?? `newTextStyle${getRandomString(ID_LENGTH)}`;

                return {
                    ...textStyle,
                    name,
                    _id
                };
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_MULTIPLE_TEXT_STYLES,
            bid,
            barrelType,
            textStyles: textStyles as TextStyleDefinition[],
            syncWithApi
        });
    },
    updateTextStyle(action: SyncableAutoFillProps & {
        tsid: string;
        textStyleData: Partial<TextStyleDefinition>;
    }) {
        const {
            bid,
            barrelType,
            tsid,
            textStyleData,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldTextStyleData: TextStyleDefinition | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ ...oldTextStyleData } = barrel.findTextStyleById(tsid) as Font);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_TEXT_STYLE,
            bid,
            barrelType,
            tsid,
            textStyleData,
            oldTextStyleData,
            syncWithApi
        });
    },
    removeTextStyle(action: SyncableAutoFillProps & {
        tsid: string;
    }) {
        const {
            bid,
            barrelType,
            tsid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let removedTextStyle: Font | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            removedTextStyle = barrel.findTextStyleById(tsid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_TEXT_STYLE,
            bid,
            barrelType,
            tsid,
            removedTextStyle,
            syncWithApi
        });
    },
    removeAllTextStyles(action: SyncableAutoFillProps) {
        const { bid, barrelType, syncWithApi = false } = fillPayloadIfEmpty(action);

        let textStylesToRemove: Font[] | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            textStylesToRemove = barrel.textStyles;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_ALL_TEXT_STYLES,
            bid,
            barrelType,
            textStylesToRemove,
            syncWithApi
        });
    },
    addSpacingSection(action: SyncableAutoFillProps & {
        spacingSection: PartialOnly<SpacingSection, "_id" | "spacingTokens">;
    }) {
        const {
            bid,
            barrelType,
            spacingSection: data,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let spacingSection = data;
        if (syncWithApi) {
            const { _id = getRandomString(ID_LENGTH) } = data;

            spacingSection = { ...data, _id };
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_SPACING_SECTION,
            bid,
            barrelType,
            spacingSection: spacingSection as PartialOnly<SpacingSection, "spacingTokens">,
            syncWithApi
        });
    },
    updateSpacingSection(action: SyncableAutoFillProps & {
        ssid: string;
        updatedSpacingSection: Partial<SpacingSection>;
    }) {
        const {
            bid,
            barrelType,
            ssid,
            updatedSpacingSection,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldSpacingSection: Partial<SpacingSection> = {};
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;

            oldSpacingSection = barrel.findSpacingSectionById(ssid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_SPACING_SECTION,
            bid,
            barrelType,
            ssid,
            updatedSpacingSection,
            oldSpacingSection,
            syncWithApi
        });
    },
    removeSpacingSection(action: SyncableAutoFillProps & {
        ssid: string;
    }) {
        const {
            bid,
            barrelType,
            ssid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let removedSpacingSection: SpacingSection | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;

            removedSpacingSection = barrel.findSpacingSectionById(ssid) as SpacingSection;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_SPACING_SECTION,
            bid,
            barrelType,
            ssid,
            removedSpacingSection,
            syncWithApi
        });
    },
    addSpacingToken(action: SyncableAutoFillProps & {
        ssid: string;
        spacingToken: PartialOnly<SpacingToken, "_id">;
        isBaseToken?: boolean;
    }) {
        const {
            bid,
            barrelType,
            ssid,
            spacingToken: data,
            isBaseToken = false,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let spacingToken = data;
        if (syncWithApi) {
            const { _id = getRandomString(ID_LENGTH) } = data;

            spacingToken = { ...data, _id };
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_SPACING_TOKEN,
            bid,
            barrelType,
            ssid,
            isBaseToken,
            spacingToken,
            syncWithApi
        });
    },
    updateSpacingToken(action: SyncableAutoFillProps & {
        ssid: string;
        sptid: string;
        updatedSpacingToken: Partial<SpacingToken>;
    }) {
        const {
            bid,
            barrelType,
            ssid,
            sptid,
            updatedSpacingToken,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);
        const barrel = BarrelHelpers.getBarrel({ bid })!;
        const { baseTokenId } = barrel.findSpacingSectionById(ssid) as SpacingSection;
        const isBaseToken = baseTokenId === sptid;

        let oldSpacingToken: Partial<SpacingToken> = {};
        if (syncWithApi) {
            oldSpacingToken = barrel.findSpacingTokenInsideSectionById(ssid, sptid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_SPACING_TOKEN,
            bid,
            barrelType,
            ssid,
            sptid,
            isBaseToken,
            updatedSpacingToken,
            oldSpacingToken,
            syncWithApi
        });
    },
    removeSpacingToken(action: SyncableAutoFillProps & {
        ssid: string;
        sptid: string;
    }) {
        const {
            bid,
            barrelType,
            ssid,
            sptid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const barrel = BarrelHelpers.getBarrel({ bid })!;
        const { baseTokenId } = barrel.findSpacingSectionById(ssid) as SpacingSection;
        const isBaseToken = baseTokenId === sptid;

        let removedSpacingToken: SpacingToken | undefined;
        if (syncWithApi) {
            removedSpacingToken = barrel.findSpacingTokenInsideSectionById(ssid, sptid) as SpacingToken;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_SPACING_TOKEN,
            bid,
            barrelType,
            ssid,
            sptid,
            removedSpacingToken,
            isBaseToken,
            syncWithApi
        });
    },
    updateRemPreferences(action: SyncableAutoFillProps & {
        updatedRemPreferences: Partial<RemPreferences>;
        isRevert?: boolean;
    }) {
        const {
            bid,
            barrelType,
            updatedRemPreferences,
            isRevert = false,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldRemPreferences: Partial<RemPreferences> = {};
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;

            oldRemPreferences = barrel.remPreferences;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_REM_PREFERENCES,
            bid,
            barrelType,
            updatedRemPreferences,
            oldRemPreferences,
            isRevert,
            syncWithApi
        });
    },
    addComponent(action: AutoFillProps & {
        csids: string[];
        component: Component & {
            requestId?: string;
            index?: number;
        };
    }) {
        const {
            bid,
            csids,
            component
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_COMPONENT,
            bid,
            csids,
            component
        });
    },
    updateComponent(action: SyncableAutoFillProps & {
        csids: string[];
        coid: string;
        componentData: Partial<Component>;
    }) {
        const {
            bid,
            barrelType,
            csids,
            coid,
            componentData,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldComponentData: Component | undefined;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ ...oldComponentData } = barrel.findComponentById(coid) as Component);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COMPONENT,
            bid,
            barrelType,
            csids,
            coid,
            shouldFetchSnapshot: shouldFetchSnapshot(coid),
            componentData,
            oldComponentData,
            syncWithApi
        });
    },
    setComponentSnapshot({
        bid, csids, coid, vid, snapshot
    }: {
        bid: string;
        csids: string[];
        coid: string;
        vid: string;
        snapshot?: Partial<Snapshot>;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_COMPONENT_SNAPSHOT,
            bid,
            csids,
            coid,
            vid,
            snapshot
        });
    },
    setMultipleComponentSnapshots(action: AutoFillProps & {
        bid: string;
        componentSnapshotPayloads: ComponentSnapshotPayload[];
    }) {
        const {
            bid,
            componentSnapshotPayloads
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_MULTIPLE_COMPONENT_SNAPSHOTS,
            bid,
            componentSnapshotPayloads
        });
    },
    removeComponents(action: SyncableAutoFillProps & {
        sectionComponents: SectionComponents[];
    }) {
        const {
            bid,
            barrelType,
            sectionComponents,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const oldComponents: Component[] = [];
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            sectionComponents.forEach(({ coids }) => {
                coids.forEach(coid => {
                    const component = barrel.findComponentById(coid);
                    if (component) {
                        oldComponents.push(component);
                    }
                });
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COMPONENTS,
            bid,
            barrelType,
            sectionComponents,
            oldComponents,
            syncWithApi
        });
    },
    removeComponent(action: SyncableAutoFillProps & {
        csids: string[];
        coid: string;
    }) {
        const {
            bid,
            barrelType,
            csids,
            coid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldComponent: Component | undefined;
        const { inspectableId } = fluxRuntime.InspectableViewStore.getState();
        const removingCurrentComponent = coid === inspectableId;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            oldComponent = barrel.findComponentById(coid)!;
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COMPONENT,
            bid,
            barrelType,
            csids,
            coid,
            oldComponent,
            removingCurrentComponent,
            syncWithApi
        });
    },
    addComponentVersion(action: AutoFillProps & {
        csids: string[];
        coid: string;
        componentVersion: ComponentVersion;
    }) {
        const {
            bid, barrelType, csids, coid, componentVersion
        } = fillPayloadIfEmpty(action);
        const currentBid = fluxRuntime.BarrelStore.getState().barrelId;
        const isCurrentBid = bid === currentBid;
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_COMPONENT_VERSION,
            bid,
            barrelType,
            csids,
            coid,
            isCurrentBid,
            shouldFetchSnapshot: shouldFetchSnapshot(coid),
            componentVersion
        });
    },
    addComponentSection(action: SyncableAutoFillProps & {
        csids: string[];
        componentSection: Payloads.AddComponentSection["componentSection"];
        isMovingComponents?: boolean;
        isCreateGroup?: boolean;
    }) {
        const {
            bid,
            barrelType,
            csids,
            componentSection,
            isMovingComponents = true,
            isCreateGroup = false,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_COMPONENT_SECTION,
            bid,
            barrelType,
            csids,
            componentSection,
            isMovingComponents,
            isCreateGroup,
            syncWithApi
        });
    },
    updateComponentSection(action: SyncableAutoFillProps & {
        csids: string[];
        componentSectionData: Partial<ComponentSection>;
        isComponentGroup?: boolean;
    }) {
        const {
            bid,
            barrelType,
            csids,
            componentSectionData,
            isComponentGroup = false,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let oldComponentSectionData: ComponentSection | undefined | null;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            ({ ...oldComponentSectionData } =
                barrel.findComponentSection(csids) as ComponentSection | undefined | null);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COMPONENT_SECTION,
            bid,
            barrelType,
            csids,
            componentSectionData,
            oldComponentSectionData,
            isComponentGroup,
            syncWithApi
        });
    },
    mergeComponentSection(action: SyncableAutoFillProps & {
        index: number;
        csids: string[];
        tcsids?: string[];
        flatten?: boolean;
    }) {
        const {
            bid,
            barrelType,
            csids,
            tcsids: targetCsids,
            flatten: shouldFlatten,
            index,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        let tcsids = targetCsids ?? [];
        let flatten = !!shouldFlatten;
        let oldComponentSection: ComponentSection | undefined | null;
        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;
            oldComponentSection = barrel.findComponentSection(csids) as ComponentSection | undefined | null;

            if (csids.length === 1) {
                // Merge removed sections into default section
                flatten = true;
                tcsids = [barrel.componentSections![0]._id];
            } else {
                // Merge groups into parent section
                const parentSectionPath = csids.slice(0, csids.length - 1);
                const parentComponentSection = barrel.findComponentSection(parentSectionPath) as ComponentSection;
                const indexOfOldComponentSection =
                    parentComponentSection.componentSections.indexOf(oldComponentSection!);

                let previousComponentSection =
                    parentComponentSection.componentSections[indexOfOldComponentSection - 1];
                if (!previousComponentSection) {
                    previousComponentSection = parentComponentSection;
                }

                flatten = previousComponentSection.name === "default";
                tcsids = previousComponentSection.idPath!;
            }
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MERGE_COMPONENT_SECTION,
            bid,
            barrelType,
            csids,
            tcsids,
            flatten,
            index,
            oldComponentSection,
            syncWithApi
        });
    },
    removeComponentSection(action: AutoFillProps & {
        csids: string[];
        syncWithApi?: boolean;
    }) {
        const {
            bid, barrelType, csids, syncWithApi = false
        } = fillPayloadIfEmpty(action);

        const parentComponentSection = syncWithApi
            ? BarrelHelpers.getBarrel({ bid })?.findComponentSection(csids.slice(0, -1))
            : null;

        const csIndex = parentComponentSection?.componentSections?.findIndex(
            cs => cs._id === csids[csids.length - 1]
        );

        const oldComponentSection = (csIndex !== undefined && csIndex >= 0)
            ? { ...parentComponentSection!.componentSections![csIndex], index: csIndex }
            : null;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COMPONENT_SECTION,
            bid,
            barrelType,
            csids,
            oldComponentSection,
            syncWithApi
        });
    },
    removeComponentSections(action: AutoFillProps & {
        componentSections: string[];
    }) {
        const {
            bid,
            componentSections
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_COMPONENT_SECTIONS,
            bid,
            componentSections
        });
    },
    updateComponentSectionsOrder(action: SyncableAutoFillProps & {
        componentSections: {
            csid: string[];
            tcsid?: string[];
            sourceIndex?: number;
            to: number;
        }[];
    }) {
        const {
            bid,
            barrelType,
            componentSections,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COMPONENT_SECTIONS_ORDER,
            bid,
            barrelType,
            componentSections,
            syncWithApi
        });
    },
    updateComponentsOrder(action: SyncableAutoFillProps & {
        components: {
            coid: string;
            csid: string[];
            tcsid: string[];
            sourceIndex?: number;
            to?: number;
        }[];
    }) {
        const {
            bid,
            barrelType,
            components,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        if (syncWithApi) {
            const barrel = BarrelHelpers.getBarrel({ bid })!;

            components.forEach(component => {
                component.to ??=
                    (barrel.findComponentSection(component.tcsid) as ComponentSection).components.length;
            });
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_COMPONENTS_ORDER,
            bid,
            barrelType,
            components,
            syncWithApi
        });
    },
    changeSelectedView(
        selectedView: "dashboard" | "styleguide" | "screen" | "storybook",
        viewUpdate: Payloads.ChangeSelectedView["viewUpdate"] = {}
    ) {
        const { barrelType } = fillPayloadIfEmpty({});

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CHANGE_SELECTED_VIEW,
            selectedView,
            barrelType,
            viewUpdate
        });
    },
    updateWorkflowStatus(action: SyncableAutoFillProps & {
        workflowStatus: WorkflowStatus;
    }) {
        const { barrelType, bid, workflowStatus, syncWithApi = false } = fillPayloadIfEmpty(action);
        const oldWorkflowStatusData: Partial<WorkflowStatus> =
            syncWithApi ? BarrelHelpers.getBarrel({ bid })!.workflowStatus : {};

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_WORKFLOW_STATUS,
            barrelType,
            bid,
            workflowStatus,
            oldWorkflowStatusData,
            syncWithApi
        });
    },
    updateWorkflowStatuses({
        bids,
        workflowStatus,
        syncWithApi = false
    }: SyncableAutoFillProps & {
        bids: string[];
        workflowStatus: WorkflowStatus;
    }) {
        const statusUpdatedBarrels = bids.map(barrelId => {
            const { barrelType, bid } = fillPayloadIfEmpty({ bid: barrelId });
            const oldWorkflowStatusData: Partial<WorkflowStatus> =
                syncWithApi ? BarrelHelpers.getBarrel({ bid })!.workflowStatus : {};

            return {
                bid,
                barrelType,
                oldWorkflowStatusData
            };
        });

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_WORKFLOW_STATUSES,
            statusUpdatedBarrels,
            workflowStatus,
            syncWithApi
        });
    },
    deleteWorkflowStatus(action: SyncableAutoFillProps) {
        const { barrelType, bid, syncWithApi = false } = fillPayloadIfEmpty(action);
        const oldWorkflowStatusData: Partial<WorkflowStatus> =
            syncWithApi ? BarrelHelpers.getBarrel({ bid })!.workflowStatus : {};

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_WORKFLOW_STATUS,
            barrelType,
            bid,
            oldWorkflowStatusData,
            syncWithApi
        });
    },
    deleteWorkflowStatuses({
        bids,
        syncWithApi = false
    }: SyncableAutoFillProps & {
        bids: string[];
    }) {
        const statusDeletedBarrels = bids.map(barrelId => {
            const { barrelType, bid } = fillPayloadIfEmpty({ bid: barrelId });
            const oldWorkflowStatusData: Partial<WorkflowStatus> =
                syncWithApi ? BarrelHelpers.getBarrel({ bid })!.workflowStatus : {};

            return {
                bid,
                barrelType,
                oldWorkflowStatusData
            };
        });

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_WORKFLOW_STATUSES,
            statusDeletedBarrels,
            syncWithApi
        });
    },

    // MARK: Preferences
    changeAssetName(action: AutoFillProps & {
        bid: string;
        component?: Component;
        screenId?: string;
        containerId: string;
        versionId?: string;
        assetId: string;
        name: string;
        source?: string;
    }) {
        const {
            bid,
            component,
            screenId,
            containerId,
            versionId,
            assetId,
            name,
            source
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CHANGE_ASSET_NAME,
            bid,
            component,
            screenId,
            containerId,
            versionId,
            assetId,
            name,
            source
        });
    },
    changeAssetDensityScalePref(format: string, assetDensityScalePrefsOfFormat: number[]) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CHANGE_ASSET_DENSITY_SCALE_PREF,
            bid,
            format,
            assetDensityScalePrefsOfFormat
        });
    },
    changeAssetNamingConvention(assetNamingConvention: NamingConvention) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CHANGE_ASSET_NAMING_CONVENTION,
            bid,
            assetNamingConvention
        });
    },
    toggleSVGOptimization(showOptimizedSVG: boolean) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.TOGGLE_SVG_OPTIMIZATION,
            bid,
            showOptimizedSVG
        });
    },

    togglePNGOptimization(showOptimizedPNG: boolean) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.TOGGLE_PNG_OPTIMIZATION,
            bid,
            showOptimizedPNG
        });
    },

    toggleJPGOptimization(showOptimizedJPG: boolean) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.TOGGLE_JPG_OPTIMIZATION,
            bid,
            showOptimizedJPG
        });
    },

    changeColorFormatPreference(colorFormatPreference: ColorFormatPreference) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CHANGE_COLOR_FORMAT_PREFERENCE,
            bid,
            colorFormatPreference
        });
    },

    // MARK: Miscellaneous
    goToDashboard() {
        const { selectedView } = fluxRuntime.BarrelStore.getState();
        if (selectedView === ProjectViewTypes.DASHBOARD) {
            return;
        }

        const { bid } = fillPayloadIfEmpty();
        const { tagFilter } = fluxRuntime.DashboardStore.getState();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.GO_TO_DASHBOARD,
            bid,
            tagFilter
        });
    },
    goToStyleguide() {
        const { selectedView } = fluxRuntime.BarrelStore.getState();
        if (selectedView === ProjectViewTypes.STYLEGUIDE) {
            return;
        }

        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.GO_TO_STYLEGUIDE,
            bid
        });
    },
    goToFlow({ pid, flid }: { pid?: string; flid?: string; } = {}) {
        const { bid } = fillPayloadIfEmpty({ bid: pid });

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.GO_TO_FLOW,
            pid: bid,
            flid: flid ?? FlowHelpers.getFirstFlowId(bid)
        });
    },
    fetchAssetDownloadTargets({ platform }: { platform?: PLATFORM; } = {}) {
        const { type: barrelPlatform } = BarrelHelpers.getBarrel()!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.FETCH_ASSET_DOWNLOAD_TARGETS,
            platform: platform ?? barrelPlatform as PLATFORM
        });
    },
    setAssetDownloadTargets(assetDownloadTargets: AssetDownloadTarget[]) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_ASSET_DOWNLOAD_TARGETS,
            assetDownloadTargets
        });
    },
    openAssetDownloadTarget(downloadTarget: AssetDownloadTarget) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.OPEN_ASSET_DOWNLOAD_TARGET,
            downloadTarget
        });
    },
    deleteAssetDownloadTarget(downloadTarget: AssetDownloadTarget) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_ASSET_DOWNLOAD_TARGET,
            downloadTarget
        });
    },
    downloadAssets({
        containerName,
        assets,
        selectedAssets,
        format,
        toastContainer,
        source,
        location,
        downloadTarget,
        shouldBrowseNewFolder
    }: {
        containerName: string;
        assets: DownloadableAsset[];
        selectedAssets: string[] | "all";
        format: string;
        toastContainer: HTMLElement;
        source: string;
        location?: string;
        downloadTarget?: AssetDownloadTarget;
        shouldBrowseNewFolder?: boolean;
    }) {
        const {
            barrelId,
            assetDensityScalePrefs,
            assetNamingConvention,
            showOptimizedSVG,
            showOptimizedPNG,
            showOptimizedJPG
        } = fluxRuntime.BarrelStore.getState();

        const { type: platform } = BarrelHelpers.getBarrel({ bid: barrelId })!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DOWNLOAD_ASSETS,
            barrelId: barrelId!,
            platform: platform as PLATFORM,
            containerName,
            assets,
            selectedAssets,
            format,
            assetDensityScalePrefs: assetDensityScalePrefs!,
            assetNamingConvention,
            showOptimizedSVG,
            showOptimizedPNG,
            showOptimizedJPG,
            toastContainer,
            source,
            location,
            downloadTarget,
            shouldBrowseNewFolder
        });
    },
    exportCode(action: AutoFillProps & {
        exportObjects: {
            code: string;
            filename: string;
            language: string;
        };
        extensionId: string;
        resourceType: string;
        source: string;
    }) {
        const {
            bid,
            resourceType,
            source,
            exportObjects,
            extensionId
        } = fillPayloadIfEmpty(action);
        const { name: barrelName, type: platform } = BarrelHelpers.getBarrel({ bid })!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.EXPORT_CODE,
            barrelName,
            platform,
            resourceType,
            source,
            exportObjects,
            extensionId
        });
    },
    copyCode({
        code, toastContainer, platform, extensionId, source
    }: {
        code: string;
        toastContainer: HTMLElement;
        platform: string;
        extensionId: string;
        source: string;
    }) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.COPY_CODE,
            code,
            toastContainer,
            platform,
            extensionId,
            source
        });
    },
    hideNoVectorAssetInfo() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.HIDE_NO_VECTOR_ASSET_INFO
        });
    },
    hideDensityMismatchWarning() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.HIDE_DENSITY_MISMATCH_WARNING
        });
    },
    hideSourceFileInfo() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.HIDE_SOURCE_FILE_INFO
        });
    },
    dismissFlowLinkInfo() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DISMISS_FLOW_LINK_INFO
        });
    },
    dismissNoResourceInfo() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DISMISS_NO_RESOURCE_INFO,
            bid
        });
    },
    hideSlackReconnectionAuntie() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.HIDE_SLACK_RECONNECTION_AUNTIE,
            bid
        });
    },
    copyComponentSectionWebLink(action: AutoFillProps & {
        seid: string;
    }) {
        const { bid, barrelType, seid } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.COPY_COMPONENT_SECTION_WEB_LINK,
            bid,
            barrelType,
            seid
        });
    },
    copyComponentSectionAppURI(action: AutoFillProps & {
        cseid: string;
    }) {
        const { bid, barrelType, cseid } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.COPY_COMPONENT_SECTION_APP_URI,
            bid,
            barrelType,
            cseid
        });
    },
    fetchComponentSectionShortLink(action: AutoFillProps & {
        seid: string;
    }) {
        const { bid, barrelType, seid } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.FETCH_COMPONENT_SECTION_SHORT_LINK,
            bid,
            barrelType,
            seid
        });
    },
    inviteUser(action: AutoFillProps & {
        bid?: string;
        handle?: string;
        role?: string;
        restricted?: boolean;
        barrelType?: BarrelType;
        owner?: FoundationBarrelUser | ApiBarrelUser | null;
        organizationData?: Organization | null;
        onError?: (data: InviteMemberErrorDialogInfo) => void;
        onSuccess?: (user: User, role?: string) => void;
    }) {
        const {
            bid,
            barrelType,
            handle,
            role,
            restricted,
            owner,
            organizationData,
            onError,
            onSuccess
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.INVITE_USER,
            bid,
            handle,
            role,
            restricted,
            barrelType,
            owner,
            organizationData,
            onError,
            onSuccess,
            syncWithApi: true
        });
    },
    inviteUserByTag(action: AutoFillProps & {
        oid: string;
        tag: string;
        onError?: () => void;
        onSuccess?: () => void;
    }) {
        const {
            bid,
            barrelType,
            oid,
            tag,
            onError,
            onSuccess
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.INVITE_USER_BY_TAG,
            bid,
            oid,
            tag,
            barrelType,
            onError,
            onSuccess,
            syncWithApi: true
        });
    },
    reinviteUser(action: AutoFillProps & {
        uid: string;
        email: string;
        toastContainer: HTMLElement;
    }) {
        const {
            bid,
            uid,
            email,
            toastContainer
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REINVITE_USER,
            bid,
            uid,
            email,
            toastContainer,
            syncWithApi: true
        });
    },
    join(action: AutoFillProps & {
        actionSource: BarrelJoinActionSource;
        mixpanelSource?: string;
        toastContainer?: Element;
    }) {
        const { isEmailNotificationEnabled } = fluxRuntime.UserStore.getState();

        const {
            bid,
            barrelType,
            actionSource,
            mixpanelSource,
            toastContainer
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.JOIN,
            bid,
            barrelType,
            actionSource,
            mixpanelSource,
            toastContainer,
            isEmailNotificationEnabled: isEmailNotificationEnabled!,
            syncWithApi: true
        });
    },
    stopLoadingJoin(action: AutoFillProps) {
        const { barrelType } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.STOP_JOIN_LOADING,
            barrelType
        });
    },
    enableDevMode() {
        const { bid, barrelType } = fillPayloadIfEmpty();
        const { styleguideLink, ancestors } = BarrelHelpers.getBarrel({ bid })!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ENABLE_DEV_MODE,
            bid,
            barrelType,
            styleguideLink,
            ancestors
        });
    },
    disableDevMode() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DISABLE_DEV_MODE
        });
    },
    reloadLocalConnectedComponents() {
        const { bid, barrelType } = fillPayloadIfEmpty();
        const { styleguideLink, ancestors } = BarrelHelpers.getBarrel({ bid })!;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.RELOAD_LOCAL_CONNECTED_COMPONENTS,
            bid,
            barrelType,
            styleguideLink,
            ancestors
        });
    },
    setLocalConnectedComponents(action: AutoFillProps & {
        localConnectedComponents: ConnectedComponent[] | null;
    }) {
        const { bid, localConnectedComponents } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_LOCAL_CONNECTED_COMPONENTS,
            bid,
            localConnectedComponents
        });
    },
    setConnectedComponentsLocalWorkspace(action: AutoFillProps & {
        workspace: string;
    }) {
        const { bid, workspace } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_CONNECTED_COMPONENTS_LOCAL_WORKSPACE,
            bid,
            workspace
        });
    },
    getConnectedComponentsEditorInfo() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.GET_CONNECTED_COMPONENTS_EDITOR_INFO,
            bid
        });
    },
    setConnectedComponentsEditorInfo(editorInfo: ConnectedComponentsEditorInfo) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_CONNECTED_COMPONENTS_EDITOR_INFO,
            editorInfo
        });
    },
    setConnectedComponentsEditorPreference(extension: string, editor: ConnectedComponentsEditor) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_CONNECTED_COMPONENTS_EDITOR_PREFERENCE,
            extension,
            editor
        });
    },
    setConnectedComponentsWorkspacePath(workspacePath: boolean) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_CONNECTED_COMPONENTS_WORKSPACE_PATH,
            workspacePath
        });
    },
    selectConnectedComponentsWorkspaceFolder() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SELECT_CONNECTED_COMPONENTS_WORKSPACE_FOLDER,
            bid
        });
    },
    openComponentFileInEditor(filePath: string, editor: ConnectedComponentsEditor) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.OPEN_COMPONENT_FILE_IN_EDITOR,
            bid,
            filePath,
            editor
        });
    },
    moveResourcesRequest(action: AutoFillProps & {
        resourceType: "colors" | "components" | "textStyles";
        resources: FoundationColor[] | Component[] | Font[];
        targetStyleguideId: string;
    }) {
        const {
            bid,
            barrelType,
            resourceType,
            resources,
            targetStyleguideId
        } = fillPayloadIfEmpty(action);

        let densityScale: number | undefined;
        const targetStyleguide = BarrelHelpers.getBarrel({ bid: targetStyleguideId })!;
        if (!targetStyleguide.densityScale) {
            ({ densityScale } = BarrelHelpers.getBarrel({ bid })!);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MOVE_RESOURCES_REQUEST,
            bid,
            barrelType,
            densityScale,
            targetStyleguideId,
            resourceType,
            resources,
            syncWithApi: true
        });
    },
    moveResourcesSuccess(action: AutoFillProps & {
        resourceType: "colors" | "components" | "textStyles";
        resources: FoundationColor[] | Font[] | Component[];
        targetStyleguideId: string;
    }) {
        const {
            bid,
            barrelType,
            resourceType,
            resources,
            targetStyleguideId
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MOVE_RESOURCES_SUCCESS,
            bid,
            barrelType,
            resourceType,
            resources,
            targetStyleguideId
        });
    },
    moveResourcesFail() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MOVE_RESOURCES_FAIL
        });
    },
    attachJiraIssue(action: AutoFillProps & {
        jira: JiraIntegration;
    }) {
        const { bid, jira } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ATTACH_JIRA_ISSUE,
            bid,
            jira
        });
    },
    removeJiraIssueAttachment(action: AutoFillProps & {
        aid: string;
    }) {
        const { bid, aid } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.REMOVE_JIRA_ISSUE_ATTACHMENT,
            bid,
            aid
        });
    },
    updateJiraIssue(action: {
        bid: string;
        attachments: JiraAttachment[];
    }) {
        const { bid, attachments } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_JIRA_ISSUE,
            bid,
            attachments
        });
    },
    updateConnectedComponents(action: AutoFillProps & {
        connectedComponents: ConnectedComponent[];
    }) {
        const { bid, connectedComponents } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_CONNECTED_COMPONENTS,
            bid,
            connectedComponents
        });
    },
    deleteConnectedComponents(action: SyncableAutoFillProps & { oldStorybookUrl: string; }) {
        const { bid, barrelType, oldStorybookUrl, syncWithApi = false } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_CONNECTED_COMPONENTS,
            bid,
            barrelType,
            oldStorybookUrl,
            syncWithApi
        });
    },
    createConnectedComponentItem(action: SyncableAutoFillProps & {
        connectedComponentItem: CreateConnectedComponentItemParameters & { _id?: string; };
    }) {
        const {
            bid,
            barrelType,
            connectedComponentItem: data,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);
        const barrel = BarrelHelpers.getBarrel({ bid }) as ApiBarrel;

        let connectedComponentItem = data;

        if (syncWithApi) {
            const { _id = getRandomString(ID_LENGTH) } = data;

            connectedComponentItem = { ...data, _id };
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CREATE_CONNECTED_COMPONENT_ITEM,
            bid,
            barrelType,
            barrel,
            connectedComponentItem,
            syncWithApi
        });
    },
    updateConnectedComponentItem(action: SyncableAutoFillProps & {
        itemId: string;
        payload: Partial<ConnectedComponent>;
    }) {
        const {
            bid,
            barrelType,
            itemId,
            payload,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_CONNECTED_COMPONENT_ITEM,
            bid,
            barrelType,
            itemId,
            payload,
            syncWithApi
        });
    },
    deleteConnectedComponentItem(action: SyncableAutoFillProps & {
        itemId: string;
    }) {
        const {
            bid,
            barrelType,
            itemId,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);
        const barrel = BarrelHelpers.getBarrel({ bid }) as ApiBarrel;
        let oldConnectedComponentItem: ConnectedComponent | undefined;

        if (syncWithApi) {
            oldConnectedComponentItem = barrel.connectedComponents!.find(({ _id }) => _id === itemId);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_CONNECTED_COMPONENT_ITEM,
            bid,
            barrelType,
            barrel,
            itemId,
            oldConnectedComponentItem,
            syncWithApi
        });
    },
    markReviewerHintboxAsSeen(oid: string) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MARK_REVIEWER_HINTBOX_AS_SEEN,
            oid
        });
    },
    setSelectedComponents(selectedComponentIds: string[], options: {
        componentToScroll?: string;
    } = {}) {
        const { componentToScroll } = options;

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_SELECTED_COMPONENTS,
            selectedComponentIds,
            componentToScroll
        });
    },
    clearSelectedComponents() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CLEAR_COMPONENT_SELECTION
        });
    },
    scrolledToComponent() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SCROLLED_TO_COMPONENT
        });
    },
    setSelectedColors(selectedColorIds: string[]) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_SELECTED_COLORS,
            selectedColorIds
        });
    },
    clearSelectedColors() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CLEAR_COLOR_SELECTION
        });
    },
    markComponentVersionsNewBadgeMenuAsSeen() {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MARK_COMPONENT_VERSIONS_NEW_BADGE_MENU_AS_SEEN
        });
    },
    markAndroidResFolderReminderAsSeen() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MARK_ANDROID_RES_FOLDER_REMINDER_AS_SEEN,
            bid
        });
    },
    clearRecentlyLoadedBarrels({ barrelType }: { barrelType: BarrelType; }) {
        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.CLEAR_RECENTLY_LOADED_BARRELS,
            barrelType
        });
    },
    updateVariableCollectionDescription(action: SyncableAutoFillProps & {
        vcid: string;
        description: string;
        oldDescription?: string;
    }) {
        const {
            bid,
            barrelType,
            vcid,
            description,
            oldDescription,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_DESCRIPTION,
            bid,
            barrelType,
            vcid,
            description,
            oldDescription,
            syncWithApi
        });
    },
    addVariableCollection(action: SyncableAutoFillProps & { variableCollection: VariableCollection; }) {
        const {
            bid,
            barrelType,
            variableCollection,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.ADD_VARIABLE_COLLECTION,
            bid,
            barrelType,
            variableCollection,
            syncWithApi
        });
    },
    deleteVariableCollection(action: SyncableAutoFillProps & { vcid: string; }) {
        const {
            bid,
            barrelType,
            vcid,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);
        const barrel = BarrelHelpers.getBarrel({ bid }) as Barrel;
        let oldVariableCollection: VariableCollection | undefined;

        if (syncWithApi) {
            oldVariableCollection = barrel.variableCollections?.find(({ _id }) => _id === vcid);
        }

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.DELETE_VARIABLE_COLLECTION,
            bid,
            barrelType,
            vcid,
            oldVariableCollection,
            syncWithApi
        });
    },
    updateVariableCollectionGroupDescription(action: SyncableAutoFillProps & {
        vcid: string;
        gid: string;
        description: string;
        oldDescription?: string;
    }) {
        const {
            bid,
            barrelType,
            vcid,
            gid,
            description,
            oldDescription,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_VARIABLE_COLLECTION_GROUP_DESCRIPTION,
            bid,
            barrelType,
            vcid,
            gid,
            description,
            oldDescription,
            syncWithApi
        });
    },
    updateVariableOrder(action: SyncableAutoFillProps & {
        vcid: string;
        gid: string;
        variableId: string;
        to: number;
        oldIndex?: number;
    }) {
        const {
            bid,
            barrelType,
            vcid,
            gid,
            variableId,
            to,
            oldIndex,
            syncWithApi = false
        } = fillPayloadIfEmpty(action);

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.UPDATE_VARIABLE_ORDER,
            bid,
            barrelType,
            vcid,
            gid,
            variableId,
            to,
            oldIndex,
            syncWithApi
        });
    },
    setSelectedSidebarTab(selectedSidebarTab: SidebarTabType) {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.SET_SELECTED_SIDEBAR_TAB,
            bid,
            selectedSidebarTab
        });
    },
    markSuggestedStatusTagsAsAutoCreated() {
        const { bid } = fillPayloadIfEmpty();

        fluxRuntime.dispatcher.dispatch({
            type: BarrelActionTypes.MARK_SUGGESTED_STATUS_TAGS_AS_AUTO_CREATED,
            bid
        });
    }
};

export default BarrelActions;
