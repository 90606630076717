enum ActionTypes {
    GET_PROJECT_FLOWS = "flow/getProjectFlows",
    GET_PROJECT_FLOWS_SUCCESS = "flow/getProjectFlowsSuccess",
    GET_PROJECT_FLOWS_FINISH = "flow/getProjectFlowsFinish",
    LOAD_PREFS = "flow/loadPrefs",
    LOAD_FLOW = "flow/loadFlow",
    LOAD_FLOWS = "flow/loadFlows",
    SET_CURRENT_FLOW = "flow/setCurrentFlow",
    CREATE_FLOW_REQUEST = "flow/createFlowRequest",
    ADD_TEXT_LABEL_NODE = "flow/addTextLabelNode",
    ADD_NODES_TO_FLOW_REQUEST = "flow/addNodesToFlowRequest",
    ADD_NODES_TO_FLOW_SUCCESS = "flow/addNodesToFlowSuccess",
    CREATE_CONNECTOR = "flow/createConnector",
    CREATE_CONNECTORS = "flow/createConnectors",
    UPDATE_CONNECTOR = "flow/updateConnector",
    UPDATE_CONNECTOR_LINE_TYPE = "flow/updateConnectorLineType",
    UPDATE_CONNECTOR_END_STYLE = "flow/updateConnectorEndStyle",
    UPDATE_CONNECTOR_COLOR = "flow/updateConnectorColor",
    UPDATE_CONNECTOR_POINTS = "flow/updateConnectorPoints",
    DELETE_CONNECTORS = "flow/deleteConnectors",
    NORMALIZE_PROTOTYPE_CONNECTORS = "flow/normalizePrototypeConnectors",
    DELETE_FLOW_NODES = "flow/deleteFlowNodes",
    UPDATE_FLOW_NODES_POSITION = "flow/updateFlowNodesPosition",
    ADD_CONNECTOR_LABEL = "flow/addConnectorLabel",
    UPDATE_CONNECTOR_LABEL = "flow/updateConnectorLabel",
    UPDATE_CONNECTOR_LABEL_TEXT = "flow/updateConnectorLabelText",
    UPDATE_CONNECTOR_LABEL_WIDTH = "flow/updateConnectorLabelWidth",
    UPDATE_CONNECTOR_LABEL_POSITION = "flow/updateConnectorLabelPosition",
    DELETE_CONNECTOR_LABEL = "flow/deleteConnectorLabel",
    UPDATE_FLOW_NODE = "flow/updateFlowNode",
    UPDATE_VARIANT_GROUP_NODE = "flow/updateVariantGroupNode",
    UPDATE_NODE_TEXT = "flow/updateNodeText",
    UPDATE_NODE_WIDTH = "flow/updateNodeWidth",
    UPDATE_NODE_HEIGHT = "flow/updateNodeHeight",
    SET_DIMENSIONS = "flow/setDimensions",
    CHANGE_POSITION = "flow/changePosition",
    CHANGE_ZOOM_LEVEL = "flow/changeZoomLevel",
    SET_ZOOM_LEVEL = "flow/setZoomLevel",
    EXPORT_AS_PDF = "flow/exportAsPDF",
    PDF_EXPORT_FINISHED = "flow/pdfExportFinished",
    ENABLE_ADD_SHAPE_MODE = "flow/enableAddShapeMode",
    DISABLE_ADD_SHAPE_MODE = "flow/disableAddShapeMode",
    NAVIGATE_TO_SCREEN = "flow/navigateToScreen",
    UPDATE_FLOW_NODE_NAME = "flow/updateFlowNodeName",
    UPDATE_FLOW_NODE_SCREEN_VERSION = "flow/updateFlowNodeScreenVersion",
    UPDATE_FLOW_NODE_TYPE = "flow/updateFlowNodeType",
    UPDATE_FLOW_NODE_VARIANTS = "flow/updateFlowNodeVariants",
    UPDATE_LAST_CONNECTOR_END_STYLE_USED = "flow/updateLastConnectorEndStyleUsed",
    UPDATE_LAST_SHAPE_COLOR_USED = "flow/updateLastShapeColorUsed",
    INPUT_GROUP_NAME = "flow/inputGroupName",
    UPDATE_GROUP_NAME = "flow/updateGroupName",
    UPDATE_GROUP_COLOR = "flow/updateGroupColor",
    REMOVE_NODES_FROM_GROUP = "flow/removeNodesFromGroup",
    ADD_NODES_TO_GROUP = "flow/addNodesToGroup",
    UPDATE_GROUP_ORDER = "flow/updateGroupOrder",
    DELETE_GROUPS = "flow/deleteGroups",
    SET_SELECTED_NODES = "flow/setSelectedNodes",
    CREATE_GROUPS_START = "flow/createGroupsStart",
    CREATE_GROUPS_REQUEST = "flow/createGroupsRequest",
    CREATE_GROUPS_SUCCESS = "flow/createGroupsSuccess",
    CENTER_GROUP = "flow/centerGroup",
    CREATE_BOARD_START = "flow/createBoardStart",
    CREATE_BOARD_REQUEST = "flow/createBoardRequest",
    CREATE_BOARD_SUCCESS = "flow/createBoardSuccess",
    DELETE_BOARD = "flow/deleteBoard",
    UPDATE_BOARD_NAME = "flow/updateBoardName",
    UPDATE_BOARD_ORDER = "flow/updateBoardOrder",
    TRANSFER_GROUPS_TO_ANOTHER_FLOW = "flow/transferGroupsToAnotherFlow",
    UPDATE_TRANSFERRED_DATA = "flow/updateTransferredData",
    TRANSFER_NODES_TO_ANOTHER_FLOW = "flow/transferNodesToAnotherFlow",
    COPY_GROUP_LINK = "flow/copyGroupLink",
    COPY_BOARD_LINK = "flow/copyBoardLink",
    ADD_SHAPE_NODE = "flow/addShapeNode",
    UPDATE_SHAPE_COLOR = "flow/updateShapeColor",
    UPDATE_SHAPE_TYPE = "flow/updateShapeType",
    UPDATE_PLACEHOLDER_TYPE = "flow/updatePlaceholderType",
    UPDATE_PLACEHOLDER_TEMPLATE = "flow/updatePlaceholderTemplate",
    SWAP_PLACEHOLDER_WITH_SCREEN = "flow/swapPlaceholderWithScreen",
    UPDATE_FLOW = "flow/updateFlow",
    DISABLE_CREATING_FROM_EMPTY_VIEW = "flow/disableCreatingFromEmptyView",
    UPDATE_CONNECTOR_LINE_TRAJECTORY = "flow/updateConnectorLineTrajectory",
    ENABLE_NOTE_MODE = "flow/enableNoteMode",
    DISABLE_NOTE_MODE = "flow/disableNoteMode",
    SHOW_NOTES = "flow/showNotes",
    HIDE_NOTES = "flow/hideNotes",
    CREATE_NOTE = "flow/createNote",
    SELECT_DOT = "flow/selectDot",
    CREATE_COMMENT = "flow/createComment",
    ADD_COMMENT = "flow/addComment",
    UPDATE_COMMENT = "flow/updateComment",
    REMOVE_COMMENT = "flow/removeComment",
    ADD_DOT = "flow/addDot",
    REMOVE_DOT = "flow/removeDot",
    UPDATE_DOT = "flow/updateDot",
    UPDATE_DOT_COLOR = "flow/updateDotColor",
    UPDATE_DOT_STATUS = "flow/updateDotStatus",
    SET_DOTS = "flow/setDots",
    SAVE_NOTE = "flow/saveNote",
    CLOSE_DOT_POPUP = "flow/closeDotPopup",
    UNHIGHLIGHT_COMMENT = "flow/unhighlightComment",
    CHANGE_DOT_FILTER = "flow/changeDotFilter",
    OPEN_DOT = "flow/openDot",
    ADD_PLACEHOLDER_NODE = "flow/addPlaceholderNode",
    DUPLICATE_NODES = "flow/duplicateNodes"
}

export default ActionTypes;
