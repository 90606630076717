/* eslint-disable class-methods-use-this */
import { Dispatcher } from "flux";
import ReduceStore from "flux/lib/FluxReduceStore";

import { Flow, FlowGroup, Position } from "../../../foundation/api/model";
import AddShapeMode from "../../../foundation/api/model/flow/AddShapeMode";
import FlowNode, { FlowShapeNode, FlowTextLabelNode } from "../../../foundation/api/model/flow/FlowNode";
import FlowNodeType from "../../../foundation/api/model/flow/FlowNodeType";
import FlowPlaceholderType from "../../../foundation/api/model/flow/FlowPlaceholderType";
import TipStyle from "../../../foundation/api/model/flow/TipStyle";
import CommentStatusFilter from "../../../foundation/model/RemarkStatusFilters";
import { union, difference, arrayRemove } from "../../../foundation/utils/array";
import BasicRecord from "../../../foundation/utils/BasicRecord";
import { getMarkerForApiColor, Marker } from "../../../foundation/utils/color";
import { isDraftDot } from "../../../foundation/utils/dot";
import { clamp } from "../../../foundation/utils/math";
import ColorName from "../../../library/ColorDot/ColorName";
import {
    ZOOM_LEVEL_DEFAULT,
    ZOOM_LEVEL_MIN,
    ZOOM_LEVEL_MAX,
    HEADER_HEIGHT
} from "../../containers/AppContainer/BarrelContainer/FlowContainer/Konva/constants";
import {
    DESKTOP_HEIGHT,
    DESKTOP_WIDTH,
    MOBILE_HEIGHT,
    MOBILE_WIDTH
} from "../../containers/AppContainer/BarrelContainer/FlowContainer/Konva/placeholder/constants";
import AppActionTypes from "../app/AppActionTypes";
import BarrelActionTypes from "../barrel/BarrelActionTypes";
import { DeleteVariantGroup, DetachVariantGroup, UpdateVariantGroup, DeleteScreens } from "../dashboard/DashboardActionPayloads";
import DashboardActionTypes from "../dashboard/DashboardActionTypes";
import { RemoveScreen, RemoveScreenVariant, RenameScreenVariant, UpdateName } from "../screen/ScreenActionPayloads";
import ScreenActionTypes from "../screen/ScreenActionTypes";
import { AllPayloads } from "../payloads";

import { tempFlowId } from "./constants";
import * as Payloads from "./FlowActionPayloads";
import FlowActionTypes from "./FlowActionTypes";
import FlowsRecord from "./FlowsRecord";

interface PositionObject {
    [flid: string]: Position;
}

interface ZoomLevelObject {
    [flid: string]: number;
}

interface State {
    currentFlowId: string | null;
    flows: FlowsRecord;
    width: number;
    height: number;
    positions: PositionObject;
    zoomLevels: ZoomLevelObject;
    fetchedFlowIds: Set<string>;
    addShapeMode: AddShapeMode | null;
    lastConnectorEndStyleUsed: TipStyle;
    lastNoteColorUsed: string;
    lastShapeColorUsed: string;
    selectedNodes: string[];
    creatingBoard: boolean;
    creatingFromEmptyView: boolean;
    notesVisible: boolean;
    noteMode: boolean;
    loadingDots: boolean;
    isGetProjectFlowsPending: boolean;
    dotPosition: { x: number; y: number; } | null;
    clientPosition: { x: number; y: number; } | null;
    dotId: string | null;
    statusFilter: CommentStatusFilter;
    colorFilter: ColorName | null;
    highlightedComments: string[];
    highlightedDots: string[];
    savedNotes: {
        [flid: string]: {
            [dotId: string]: string;
        };
    };
}

class FlowStore extends ReduceStore<State, AllPayloads> {
    constructor(dispatcher: Dispatcher<AllPayloads>) {
        super(dispatcher);
    }

    getInitialState(): State {
        return {
            currentFlowId: null,
            flows: {},
            fetchedFlowIds: new Set(),
            width: window.innerWidth,
            height: window.innerHeight - HEADER_HEIGHT,
            positions: {},
            zoomLevels: {},
            addShapeMode: null,
            lastConnectorEndStyleUsed: TipStyle.Arrow,
            lastNoteColorUsed: "yellow",
            lastShapeColorUsed: "teflon",
            selectedNodes: [],
            creatingBoard: false,
            creatingFromEmptyView: false,
            notesVisible: true,
            noteMode: false,
            dotPosition: null,
            clientPosition: null,
            dotId: null,
            statusFilter: CommentStatusFilter.Open,
            colorFilter: null,
            savedNotes: {},
            highlightedDots: [],
            highlightedComments: [],
            loadingDots: true,
            isGetProjectFlowsPending: true
        };
    }

    getProjectFlowsSuccess(state: State, {
        pid,
        flows
    }: Payloads.GetProjectFlowsSuccess): State {
        return {
            ...state,
            flows: {
                ...state.flows,
                [pid]: Object.fromEntries(flows.map(flow => [flow._id, flow]))
            },
            zoomLevels: {
                ...state.zoomLevels,
                ...Object.fromEntries(flows.map(({ _id }) => [_id, ZOOM_LEVEL_DEFAULT]))
            },
            isGetProjectFlowsPending: false
        };
    }

    getProjectFlowsFinish(state: State): State {
        return {
            ...state,
            isGetProjectFlowsPending: false
        };
    }

    loadPrefs(state: State, {
        lastConnectorEndStyleUsed,
        lastShapeColorUsed,
        lastNoteColorUsed
    }: Payloads.LoadPrefs): State {
        return {
            ...state,
            lastConnectorEndStyleUsed: lastConnectorEndStyleUsed ?? state.lastConnectorEndStyleUsed,
            lastShapeColorUsed: lastShapeColorUsed ?? state.lastShapeColorUsed,
            lastNoteColorUsed: lastNoteColorUsed ?? state.lastNoteColorUsed
        };
    }

    reset(state: State): State {
        return {
            ...this.getInitialState(),
            flows: state.flows,
            zoomLevels: state.zoomLevels,
            fetchedFlowIds: state.fetchedFlowIds
        };
    }

    loadFlow(state: State, { pid, flow, complete }: Omit<Payloads.LoadFlow, "type">): State {
        const existingPrototypeLinks = state.flows[pid]?.[flow._id]?.connectors
            ?.filter(({ fromPrototypeLink }) => fromPrototypeLink) ?? [];

        let newFlow = flow;

        if (existingPrototypeLinks.length) {
            newFlow = {
                ...flow,
                connectors: flow?.connectors?.map(flowConn => (
                    { ...flowConn, fromPrototypeLink: existingPrototypeLinks.some(({ _id }) => _id === flowConn._id) }
                ))
            };
        }

        return {
            ...state,
            flows: {
                ...state.flows,
                [pid]: {
                    ...state.flows[pid],
                    [flow._id]: newFlow
                }
            },
            zoomLevels: {
                ...state.zoomLevels,
                [flow._id]: ZOOM_LEVEL_DEFAULT
            },
            fetchedFlowIds: complete ? state.fetchedFlowIds.add(flow._id) : state.fetchedFlowIds
        };
    }

    loadFlows(state: State, { pid, flows }: Payloads.LoadFlows): State {
        return flows.reduce((reducedState, flow) =>
            this.loadFlow(reducedState, { pid, flow, complete: true })
        , state);
    }

    updateFlowValue(state: State, { pid, flid, key, value }:
        { pid: string; flid: string; key: string; value: (flow: Flow) => unknown; }
    ) {
        const projectFlows = state.flows[pid];
        if (!projectFlows) {
            return state;
        }

        const flow = projectFlows[flid];
        if (!flow) {
            return state;
        }

        return {
            ...state,
            flows: {
                ...state.flows,
                [pid]: {
                    ...state.flows[pid],
                    [flid]: {
                        ...flow,
                        [key]: typeof value === "function" ? value(flow) : value
                    }
                }
            }
        };
    }

    updateFlow(state: State, { pid, flid, flow: updatedFlow }: Payloads.UpdateFlow) {
        const projectFlows = state.flows[pid];
        if (!projectFlows) {
            return state;
        }

        const flow = projectFlows[flid];
        if (!flow) {
            return state;
        }

        const newFlows = {
            ...state.flows,
            [pid]: {
                ...state.flows[pid],
                [updatedFlow._id]: {
                    ...flow,
                    ...updatedFlow
                }
            }
        };

        return {
            ...state,
            flows: newFlows,
            zoomLevels: {
                ...state.zoomLevels,
                [updatedFlow._id]: ZOOM_LEVEL_DEFAULT
            }
        };
    }

    addNodeToFlow(
        state: State,
        { pid, flid, node }:
            Payloads.AddTextLabelNode |
            Payloads.AddShapeNode|
            Payloads.AddPlaceholderNode
    ): State {
        let updatedState = this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!node._id) {
                    return nodes;
                }

                return nodes.concat(node as FlowNode);
            }
        });

        if (node.type === FlowNodeType.Shape || node.text === FlowNodeType.Placeholder) {
            updatedState = {
                ...updatedState,
                creatingFromEmptyView: false
            };

            if (updatedState.flows[pid!][tempFlowId]) {
                delete updatedState.flows[pid!][tempFlowId];
            }
        }

        return updatedState;
    }

    updateShapeColor(
        state: State,
        { pid, flid, noid, color }: Payloads.UpdateShapeColor
    ): State {
        const newState = {
            ...state,
            lastShapeColorUsed: getMarkerForApiColor(color) ?? Marker.Teflon
        };

        return this.updateFlowValue(newState, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        color
                    };
                });
            }
        });
    }

    updateShapeType(
        state: State,
        { pid, flid, noid, shapeType }: Payloads.UpdateShapeType
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        shapeType
                    };
                });
            }
        });
    }

    updatePlaceholderType(
        state: State,
        { pid, flid, noid, placeholderType }: Payloads.UpdatePlaceholderType
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    let width = MOBILE_WIDTH;
                    let height = MOBILE_HEIGHT;
                    if (placeholderType === FlowPlaceholderType.DESKTOP) {
                        width = DESKTOP_WIDTH;
                        height = DESKTOP_HEIGHT;
                    }

                    return {
                        ...node,
                        width,
                        height,
                        placeholderType
                    };
                });
            }
        });
    }

    updatePlaceholderTemplate(
        state: State,
        { pid, flid, noid, template }: Payloads.UpdatePlaceholderTemplate
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        template
                    };
                });
            }
        });
    }

    swapPlaceholderWithScreen(
        state: State,
        { pid, flid, noid, node: newNode }: Payloads.SwapPlaceholderWithScreen
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return newNode;
                });
            }
        });
    }

    addNodesToFlow(state: State, {
        pid, flid, nodes: newNodes, connectors: addedConnectors
    }: Payloads.AddNodesToFlowSuccess): State {
        const updatedState = this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                return nodes.concat(newNodes);
            }
        });

        if (!addedConnectors) {
            return updatedState;
        }

        const newConnectors = addedConnectors.map(connector => ({ ...connector, fromPrototypeLink: true }));

        return this.updateFlowValue(updatedState, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                return (connectors ?? []).concat(newConnectors);
            }
        });
    }

    updateFlowNodesPosition(state: State, { pid, flid, nodes: newNodes }: Payloads.UpdateFlowNodesPosition): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                return nodes.map(node => {
                    const updatedNode = newNodes.find(newNode => newNode._id === node._id);
                    if (updatedNode) {
                        return {
                            ...node,
                            ...updatedNode
                        };
                    }

                    return node;
                });
            }
        });
    }

    deleteFlowNodes(
        state: State,
        { pid, flid, nodeIds, deletedConnectors }: Payloads.DeleteFlowNodes
    ): State {
        let removeNodesState = this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                return nodes.filter(node => !nodeIds.includes(node._id));
            }
        });

        removeNodesState = this.updateFlowValue(removeNodesState, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(group => ({
                    ...group,
                    nodes: difference(group.nodes, nodeIds)
                })).filter(group => group.nodes.length);
            }
        });

        const connectorIds = deletedConnectors?.map(({ _id }) => _id);
        if (connectorIds?.length) {
            return this.deleteConnectors(removeNodesState, { pid, flid, connectorIds });
        }

        return removeNodesState;
    }

    createConnector(
        state: State,
        { pid, flid, connector: newConnector }: Omit<Payloads.CreateConnector, "type">
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [newConnector];
                }

                return connectors.concat(newConnector);
            }
        });
    }

    updateConnector(
        state: State,
        { pid, flid, conid, connector: updatedConnector }: Payloads.UpdateConnector
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        ...updatedConnector
                    };
                });
            }
        });
    }

    updateConnectorLineType(
        state: State,
        { pid, flid, conid, lineType }: Payloads.UpdateConnectorLineType
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        type: lineType
                    };
                });
            }
        });
    }

    updateConnectorEndStyle(
        state: State,
        { pid, flid, conid, endStyle }: Payloads.UpdateConnectorEndStyle
    ): State {
        const newState = {
            ...state,
            lastConnectorEndStyleUsed: endStyle
        };

        return this.updateFlowValue(newState, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        end: {
                            ...connector.end,
                            style: endStyle
                        }
                    };
                });
            }
        });
    }

    updateConnectorColor(
        state: State,
        { pid, flid, conid, color }: Payloads.UpdateConnectorColor
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        color
                    };
                });
            }
        });
    }

    updateConnectorPoints(
        state: State,
        {
            pid, flid, conid, start, end
        }: Payloads.UpdateConnectorPoints
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    if (start && end) {
                        return { ...connector, start, end };
                    }

                    if (start) {
                        return { ...connector, start };
                    }

                    if (end) {
                        return { ...connector, end };
                    }

                    return connector;
                });
            }
        });
    }

    addConnectorLabel(
        state: State,
        { pid, flid, conid, label }: Payloads.AddConnectorLabel
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        label
                    };
                });
            }
        });
    }

    updateConnectorLabel(
        state: State,
        { pid, flid, conid, label }: Payloads.UpdateConnectorLabel
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        label: {
                            ...connector.label,
                            ...label
                        }
                    };
                });
            }
        });
    }

    updateConnectorLabelText(
        state: State,
        { pid, flid, conid, text }: Payloads.UpdateConnectorLabelText
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        label: {
                            ...connector.label,
                            text
                        }
                    };
                });
            }
        });
    }

    updateConnectorLabelWidth(
        state: State,
        { pid, flid, conid, width }: Payloads.UpdateConnectorLabelWidth
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        label: {
                            ...connector.label,
                            width
                        }
                    };
                });
            }
        });
    }

    updateConnectorLineTrajectory(
        state: State, { pid, flid, conid, points }: Payloads.UpdateConnectorLineTrajectory
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                // TODO Update trajectory
                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        lineTrajectory: points
                    };
                });
            }
        });
    }

    updateGroupName(
        state: State,
        { pid, flid, fgid, name }: Payloads.UpdateGroupName
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(group => {
                    if (group._id !== fgid) {
                        return group;
                    }

                    return {
                        ...group,
                        name
                    };
                });
            }
        });
    }

    updateGroupColor(
        state: State,
        { pid, flid, fgid, color }: Payloads.UpdateGroupColor
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(group => {
                    if (group._id !== fgid) {
                        return group;
                    }

                    return {
                        ...group,
                        color
                    };
                });
            }
        });
    }

    removeNodesFromGroup(
        state: State,
        { pid, flid, fgid, nodeIds }: Omit<Payloads.RemoveNodesFromGroup, "type">
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(group => {
                    if (group._id !== fgid) {
                        return group;
                    }

                    return {
                        ...group,
                        nodes: difference(group.nodes, nodeIds)
                    };
                }).filter(group => group.nodes.length);
            }
        });
    }

    addNodesToGroup(
        state: State,
        {
            pid, flid, fgid, nodeIds, fromGroup
        }: Payloads.AddNodesToGroup
    ): State {
        let updatedState = state;

        if (fromGroup) {
            updatedState = this.removeNodesFromGroup(
                state,
                { pid, flid, fgid: fromGroup, nodeIds }
            );
        }

        return this.updateFlowValue(updatedState, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(group => {
                    if (group._id !== fgid) {
                        return group;
                    }

                    return {
                        ...group,
                        nodes: union(group.nodes, nodeIds)
                    };
                });
            }
        });
    }

    updateGroupOrder(
        state: State,
        {
            pid, flid, fgids, index
        }: Payloads.UpdateGroupOrder
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                const group = arrayRemove(groups, (sec: FlowGroup) => fgids.includes(sec._id));

                if (!group) {
                    return groups;
                }

                groups.splice(index, 0, group);

                return groups;
            }
        });
    }

    deleteGroups(state: State, { pid, flid, fgids }: Payloads.DeleteGroups) {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.filter(group => !fgids.includes(group._id));
            }
        });
    }

    setSelectedNodes(state: State, { nodeIds }: Payloads.SetSelectedNodes): State {
        return {
            ...state,
            selectedNodes: nodeIds
        };
    }

    createGroupsStart(state: State, { pid, flid, groups: createdGroups }: Payloads.CreateGroupsStart): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return [...groups, ...createdGroups];
            }
        });
    }

    createGroupsRequest(state: State, {
        pid,
        flid,
        groups: groupsToCreate,
        currentGroupIds
    }: Payloads.CreateGroupsRequest): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(gr => {
                    const currentGroupIdIndex = currentGroupIds.indexOf(gr._id);

                    if (currentGroupIdIndex === -1) {
                        return gr;
                    }

                    return {
                        ...gr,
                        name: groupsToCreate[currentGroupIdIndex].name
                    };
                });
            }
        });
    }

    createGroupsSuccess(state: State, {
        pid,
        flid,
        createdGroupIds,
        currentGroupIds
    }: Payloads.CreateGroupsSuccess): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "groups",
            value({ groups }) {
                if (!groups) {
                    return [];
                }

                return groups.map(gr => {
                    const currentGroupIdIndex = currentGroupIds.indexOf(gr._id);

                    if (currentGroupIdIndex === -1) {
                        return gr;
                    }

                    return {
                        ...gr,
                        _id: createdGroupIds[currentGroupIdIndex]
                    };
                });
            }
        });
    }

    createBoardStart(state: State, { pid, flid, name }: Payloads.CreateBoardStart): State {
        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: {
                    ...state.flows[pid!],
                    [flid]: {
                        _id: flid,
                        name,
                        nodes: []
                    }
                }
            },
            creatingBoard: true
        };
    }

    createBoardSuccess(state: State, {
        pid,
        flow,
        currentFlowId
    }: Payloads.CreateBoardSuccess): State {
        const { flows: { [pid!]: { [currentFlowId]: _currentFlow, ...otherFlows } } } = state;
        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: {
                    ...otherFlows,
                    [flow._id]: flow
                }
            },
            creatingBoard: false
        };
    }

    deleteBoard(state: State, { pid, flid }: Payloads.DeleteBoard) {
        const { flows: { [pid!]: { [flid!]: _deletedFlow, ...otherFlows } } } = state;

        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: otherFlows
            },
            creatingBoard: false
        };
    }

    updateBoardName(
        state: State,
        { pid, flid, name }: Payloads.UpdateBoardName | Payloads.CreateBoardRequest
    ): State {
        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: {
                    ...state.flows[pid!],
                    [flid!]: {
                        ...state.flows[pid!][flid!],
                        name
                    }
                }
            }
        };
    }

    updateBoardOrder(
        state: State,
        { pid, flows: flids, index }: Payloads.UpdateBoardOrder
    ): State {
        const { flows } = state;
        const flowEntries = Object.entries(flows[pid!]);
        const board = arrayRemove(flowEntries, ([flid]) => flids.includes(flid));

        if (!board) {
            return state;
        }

        flowEntries.splice(index, 0, board);

        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: Object.fromEntries(flowEntries)
            }
        };
    }

    updateConnectorLabelPosition(
        state: State,
        { pid, flid, conid, position }: Payloads.UpdateConnectorLabelPosition
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    return {
                        ...connector,
                        label: {
                            ...connector.label,
                            position
                        }
                    };
                });
            }
        });
    }

    deleteConnectors(
        state: State,
        { pid, flid, connectorIds }: Omit<Payloads.DeleteConnectors, "type">
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.filter(connector => !connectorIds.includes(connector._id));
            }
        });
    }

    normalizePrototypeConnectors(state: State, { pid, flid }: Payloads.NormalizePrototypeConnectors): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(({ fromPrototypeLink: _toRemoved, ...rest }) => ({ ...rest }));
            }
        });
    }

    deleteConnectorLabel(
        state: State,
        { pid, flid, conid }: Payloads.DeleteConnectorLabel
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                return connectors.map(connector => {
                    if (connector._id !== conid) {
                        return connector;
                    }

                    const {
                        label: _label,
                        ...updatedConnector
                    } = connector;

                    return updatedConnector;
                });
            }
        });
    }

    updateFlowNode(
        state: State,
        { pid, flid, noid, node: updatedNode }: Omit<Payloads.UpdateFlowNode, "type">
    ): State {
        const updatedState = this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        ...updatedNode
                    };
                });
            }
        });

        if (!updatedNode.defaultVariant) {
            return updatedState;
        }

        // Remove connectors of the node
        const connectorIds = state.flows[pid!][flid!].connectors
            ?.filter(({ start, end }) => start.node === noid || end.node === noid)
            .map(({ _id }) => _id);

        if (connectorIds?.length) {
            return this.deleteConnectors(updatedState, { pid, flid, connectorIds });
        }

        return updatedState;
    }

    updateVariantGroupNode(state: State, {
        pid,
        flid,
        noid,
        defaultVariant
    }: Payloads.UpdateVariantGroupNode): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        defaultVariant
                    };
                });
            }
        });
    }

    updateConnectorIds(state: State, {
        pid,
        flid,
        connectorIdMap
    }: Payloads.CreateConnectors): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "connectors",
            value({ connectors }) {
                if (!connectors) {
                    return [];
                }

                if (!connectorIdMap) {
                    return connectors;
                }

                return connectors.map(connector => {
                    const conid = connector._id;
                    if (conid in connectorIdMap) {
                        return {
                            ...connector,
                            _id: connectorIdMap[conid]
                        };
                    }

                    return connector;
                });
            }
        });
    }

    updateNodeText(
        state: State,
        { pid, flid, noid, text }: Payloads.UpdateNodeText
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    return {
                        ...node,
                        text
                    };
                });
            }
        });
    }

    updateNodeWidth(
        state: State,
        { pid, flid, noid, width }: Payloads.UpdateNodeWidth
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    if (width === null) {
                        const { width: _width, ...updatedNode } = node as FlowTextLabelNode | FlowShapeNode;

                        return updatedNode;
                    }

                    return {
                        ...node,
                        width
                    };
                });
            }
        });
    }

    updateNodeHeight(
        state: State,
        { pid, flid, noid, height }: Payloads.UpdateNodeHeight
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    if (height === null) {
                        const { height: _height, ...updatedNode } = node as FlowShapeNode;

                        return updatedNode;
                    }

                    return {
                        ...node,
                        height
                    };
                });
            }
        });
    }

    updateFlowNodeName(
        state: State,
        { pid, flid, noid, node: updatedNode }: Payloads.UpdateFlowNodeName
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    if (node.type === FlowNodeType.Screen) {
                        return {
                            ...node,
                            screen: {
                                ...node.screen,
                                ...updatedNode
                            }
                        };
                    } else if (node.type === FlowNodeType.VariantGroup) {
                        const screenVariantsToUpdate = updatedNode.screens;

                        const updatedVariantGroupScreens = node.variantGroup.screens.map(screenVariant => {
                            const { variantName } = screenVariantsToUpdate.find(
                                screenVariantToUpdate => screenVariantToUpdate._id === screenVariant._id
                            ) || {};

                            if (variantName) {
                                return {
                                    ...screenVariant,
                                    variantName
                                };
                            }

                            return screenVariant;
                        });

                        return {
                            ...node,
                            variantGroup: {
                                ...node.variantGroup,
                                screens: updatedVariantGroupScreens
                            }
                        };
                    }

                    return node;
                });
            }
        });
    }

    updateFlowNodeScreenVersion(
        state: State,
        { pid, flid, noid, node: updatedNode }: Payloads.UpdateFlowNodeScreenVersion
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id !== noid) {
                        return node;
                    }

                    if (node.type === FlowNodeType.Screen) {
                        return {
                            ...node,
                            screen: {
                                ...node.screen,
                                latestVersion: updatedNode.screen!.latestVersion
                            }
                        };
                    } else if (node.type === FlowNodeType.VariantGroup) {
                        return {
                            ...node,
                            variantGroup: {
                                ...node.variantGroup,
                                screens: node.variantGroup.screens.map(screenVariant => {
                                    const screenToUpdate = updatedNode.variantGroup?.screens.find(
                                        updatedScreen => screenVariant._id === updatedScreen._id
                                    );

                                    if (screenToUpdate) {
                                        return {
                                            ...screenVariant,
                                            latestVersion: screenToUpdate.latestVersion
                                        };
                                    }

                                    return screenVariant;
                                })
                            }
                        };
                    }

                    return node;
                });
            }
        });
    }

    updateFlowNodeType(
        state: State,
        { pid, flid, noid, node: newNode }: Payloads.UpdateFlowNodeType
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node._id === noid) {
                        return newNode;
                    }

                    return node;
                });
            }
        });
    }

    updateFlowNodeVariants(
        state: State,
        { pid, flid, nodeIds, variantGroup }: Payloads.UpdateFlowNodeVariants
    ): State {
        return this.updateFlowValue(state, {
            pid: pid!,
            flid: flid!,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (nodeIds.some(noid => noid === node._id)) {
                        return ({
                            ...node,
                            variantGroup
                        });
                    }

                    return node;
                });
            }
        });
    }

    updateScreenName(
        state: State,
        { pid, sid, name, syncWithApi }: UpdateName
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node.type === FlowNodeType.Screen && node.screen._id === sid) {
                        return {
                            ...node,
                            screen: {
                                ...node.screen,
                                name
                            }
                        };
                    } else if (node.type === FlowNodeType.VariantGroup) {
                        const updatedVariantGroupScreens = node.variantGroup.screens.map(screenVariant => {
                            if (screenVariant._id === sid) {
                                return {
                                    ...screenVariant,
                                    name
                                };
                            }

                            return screenVariant;
                        });

                        return {
                            ...node,
                            variantGroup: {
                                ...node.variantGroup,
                                screens: updatedVariantGroupScreens
                            }
                        };
                    }

                    return node;
                });
            }
        });
    }

    updateScreenVariantName(
        state:State,
        {
            pid, vgid, svid, name, syncWithApi
        }: RenameScreenVariant
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node.type === FlowNodeType.VariantGroup && node.variantGroup._id === vgid) {
                        const updatedVariantGroupScreens = node.variantGroup.screens.map(screenVariant => {
                            if (screenVariant._id === svid) {
                                return {
                                    ...screenVariant,
                                    variantName: name
                                };
                            }

                            return screenVariant;
                        });

                        return {
                            ...node,
                            variantGroup: {
                                ...node.variantGroup,
                                screens: updatedVariantGroupScreens
                            }
                        };
                    }

                    return node;
                });
            }
        });
    }

    deleteScreens(
        state: State,
        { pid, items, syncWithApi }: Pick<DeleteScreens, "pid" | "items" | "syncWithApi">
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        let newState = state;
        items.forEach(item => {
            if (item.type === "screen") {
                newState = this.removeScreen(newState, { pid, sid: item._id, syncWithApi });
            } else if (item.type === "variantGroup") {
                newState = this.deleteVariantGroupNode(newState, { pid, vgid: item._id, syncWithApi });
            }
        });
        return newState;
    }

    removeScreen(
        state: State,
        { pid, sid, syncWithApi }: Pick<RemoveScreen, "pid" | "sid" | "syncWithApi">
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                let newNodes = nodes;

                const { _id: foundNodeId } = nodes.find(node =>
                    (node.type === FlowNodeType.Screen && node.screen._id === sid) ||
                    (node.type === FlowNodeType.VariantGroup && node.defaultVariant === sid)
                ) || {};

                if (foundNodeId) {
                    newNodes = nodes.filter(node => node._id !== foundNodeId);
                }

                return newNodes.map(node => {
                    if (node.type !== FlowNodeType.VariantGroup) {
                        return node;
                    }

                    const updatedFlowScreens = node.variantGroup.screens.filter(({ _id }) => _id !== sid);
                    if (updatedFlowScreens.length === 1) {
                        // No more variant group node
                        return {
                            _id: node._id,
                            type: FlowNodeType.Screen,
                            screen: updatedFlowScreens[0],
                            position: node.position
                        };
                    }

                    return {
                        ...node,
                        variantGroup: {
                            ...node.variantGroup,
                            screens: updatedFlowScreens
                        }
                    };
                });
            }
        });
    }

    removeScreenVariant(
        state: State,
        { pid, vgid, svid, syncWithApi }: RemoveScreenVariant
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node.type !== FlowNodeType.VariantGroup) {
                        return node;
                    }

                    if (node.variantGroup._id === vgid) {
                        if (node.defaultVariant === svid) {
                            return {
                                _id: node._id,
                                type: FlowNodeType.Screen,
                                screen: node.variantGroup.screens.find(({ _id }) => _id === svid),
                                position: node.position
                            };
                        }

                        const updatedVariantGroupScreens = node.variantGroup.screens.filter(({ _id }) => _id !== svid);
                        if (updatedVariantGroupScreens.length === 1) {
                            // No more variant group node
                            return {
                                _id: node._id,
                                type: FlowNodeType.Screen,
                                screen: updatedVariantGroupScreens[0],
                                position: node.position
                            };
                        }

                        return {
                            ...node,
                            variantGroup: {
                                ...node.variantGroup,
                                screens: updatedVariantGroupScreens
                            }
                        };
                    }

                    return node;
                });
            }
        });
    }

    createVariantGroupNode(
        state: State,
        {
            pid,
            variantGroupData,
            variantScreensData
        }: UpdateVariantGroup
    ) {
        const { currentFlowId } = state;
        if (!currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node.type === FlowNodeType.Screen && variantScreensData[node.screen._id]) {
                        return {
                            _id: node._id,
                            type: FlowNodeType.VariantGroup,
                            variantGroup: {
                                _id: variantGroupData._id,
                                name: variantGroupData.name,
                                screens: variantGroupData.screens.map(({ id, variantName }) => ({
                                    _id: id,
                                    name: variantScreensData[id].name,
                                    variantName,
                                    latestVersion: variantScreensData[id].latestVersion
                                }))
                            },
                            defaultVariant: node.screen._id,
                            position: node.position
                        };
                    } else if (node.type === FlowNodeType.VariantGroup) {
                        const variantMapping = variantGroupData.screens.reduce((acc, { id, variantName }) => {
                            acc[id] = variantName;

                            return acc;
                        }, {} as BasicRecord<string>);
                        const variantScreenIds = Object.keys(variantMapping);
                        const { variantGroup, defaultVariant } = node;

                        if (variantScreenIds.includes(defaultVariant)) {
                            const flowVariantIds = variantGroup.screens.map(({ _id }) => _id);
                            const variantIdsToBeAdded = variantScreenIds.filter(
                                variantScreenId => !flowVariantIds.includes(variantScreenId)
                            );
                            const newVariantScreens = variantIdsToBeAdded.map(variantId => {
                                const { name: variantScreenName, latestVersion } = variantScreensData[variantId];
                                return {
                                    _id: variantId,
                                    name: variantScreenName,
                                    variantName: variantMapping[variantId],
                                    latestVersion
                                };
                            });

                            return {
                                ...node,
                                variantGroup: {
                                    ...node.variantGroup,
                                    screens: node.variantGroup.screens.concat(newVariantScreens)
                                }
                            };
                        }
                    }

                    return node;
                });
            }
        });
    }

    detachVariantGroupNode(state: State, { pid, vgid }: DetachVariantGroup) {
        const { currentFlowId } = state;
        if (!currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.map(node => {
                    if (node.type !== FlowNodeType.VariantGroup || node.variantGroup._id !== vgid) {
                        return node;
                    }

                    const defaultScreen = node.variantGroup.screens.find(({ _id }) => _id === node.defaultVariant)!;

                    return {
                        _id: node._id,
                        type: FlowNodeType.Screen,
                        position: node.position,
                        screen: {
                            _id: defaultScreen._id,
                            name: defaultScreen.name,
                            latestVersion: defaultScreen.latestVersion
                        }
                    };
                });
            }
        });
    }

    deleteVariantGroupNode(
        state: State,
        { pid, vgid, syncWithApi }: Pick<DeleteVariantGroup, "pid" | "vgid" | "syncWithApi">
    ) {
        const { currentFlowId } = state;
        if (!syncWithApi || !currentFlowId) {
            return state;
        }

        return this.updateFlowValue(state, {
            pid,
            flid: currentFlowId,
            key: "nodes",
            value({ nodes }) {
                if (!nodes) {
                    return [];
                }

                return nodes.filter(node =>
                    !(node.type === FlowNodeType.VariantGroup && node.variantGroup._id === vgid)
                );
            }
        });
    }

    setCurrentFlowId(state: State, { flid }: Payloads.SetCurrentFlow) {
        return {
            ...state,
            currentFlowId: flid,
            selectedNodes: []
        };
    }

    setDimensions(state: State, { width, height }: Payloads.SetDimensions): State {
        return {
            ...state,
            width,
            height
        };
    }

    changePosition(state: State, { flid, position }: Omit<Payloads.ChangePosition, "type">): State {
        return {
            ...state,
            positions: {
                ...state.positions,
                [flid!]: position
            }
        };
    }

    setZoomLevel(state: State, { flid, zoomLevel: newZoomLevel, position }: Payloads.SetZoomLevel): State {
        const zoomLevel = clamp(newZoomLevel, ZOOM_LEVEL_MIN, ZOOM_LEVEL_MAX);

        const updatedState = this.changePosition(state, { flid, position });

        return {
            ...updatedState,
            zoomLevels: {
                ...updatedState.zoomLevels,
                [flid!]: zoomLevel
            }
        };
    }

    enableAddShapeMode(state: State, { addShapeMode, creatingFromEmptyView }: Payloads.EnableAddShapeMode): State {
        return {
            ...state,
            addShapeMode,
            creatingFromEmptyView
        };
    }

    disableAddShapeMode(state: State): State {
        return {
            ...state,
            addShapeMode: null
        };
    }

    disableCreatingFromEmptyView(state: State): State {
        return {
            ...state,
            creatingFromEmptyView: false
        };
    }

    transferToAnotherFlow(
        state: State,
        action: Payloads.TransferGroupsToAnotherFlow | Payloads.TransferNodesToAnotherFlow
    ) {
        const {
            pid, flid, targetFlid, transferredConnectors, transferredGroups, transferredNodes
        } = action;
        const { flows } = state;
        const projectFlows = flows[pid!];
        const sourceFlow = projectFlows[flid!];
        const targetFlow = projectFlows[targetFlid];

        if (!sourceFlow || !targetFlow || !sourceFlow.groups) {
            return state;
        }

        const transferredNodeIds = transferredNodes!.map(({ _id }) => _id);
        const transferredConnectorIds = transferredConnectors!.map(({ _id }) => _id);
        const transferredGroupIds = transferredGroups!.map(({ _id }) => _id);
        const updatedSourceFlow: Flow = {
            ...sourceFlow,
            nodes: sourceFlow.nodes.filter(({ _id }) => !transferredNodeIds.includes(_id)),
            connectors: sourceFlow.connectors?.filter(({ _id }) => !transferredConnectorIds.includes(_id)),
            groups: sourceFlow.groups?.filter(({ _id }) => !transferredGroupIds.includes(_id))
        };

        const updatedTargetFlow: Flow = {
            ...targetFlow,
            nodes: targetFlow.nodes.concat(transferredNodes!),
            connectors: (targetFlow.connectors || []).concat(transferredConnectors || []),
            groups: (targetFlow.groups || []).concat(transferredGroups!)
        };

        return {
            ...state,
            flows: {
                ...state.flows,
                [pid!]: {
                    ...state.flows[pid!],
                    [flid!]: updatedSourceFlow,
                    [targetFlid]: updatedTargetFlow
                }
            }
        };
    }

    updateTransferredGroupData(state: State, { pid, flid, nodes }: Payloads.UpdateTransferredData) {
        let updatedState = state;
        for (const node of nodes) {
            updatedState = this.updateFlowNode(updatedState, { pid, flid, noid: node._id, node });
        }

        return updatedState;
    }

    // #region Notes
    enableNoteMode(state: State): State {
        return {
            ...state,
            notesVisible: true,
            noteMode: true
        };
    }

    disableNoteMode(state: State): State {
        return {
            ...state,
            noteMode: false
        };
    }

    showNotes(state: State): State {
        return {
            ...state,
            notesVisible: true,
            noteMode: false
        };
    }

    hideNotes(state: State): State {
        return {
            ...state,
            notesVisible: false,
            noteMode: false,
            dotId: null
        };
    }

    createNote(state: State, {
        dot: {
            _id: dotId
        },
        dotPosition,
        clientPosition
    }: Payloads.CreateNote): State {
        const newState = this.showNotes(state);

        return {
            ...newState,
            dotPosition,
            clientPosition,
            dotId,
            statusFilter: CommentStatusFilter.Open,
            colorFilter: null
        };
    }

    selectDot(state: State, { dotId, commentId, position }: Payloads.SelectDot | Payloads.OpenDot): State {
        if (state.dotId === dotId) {
            return state;
        }

        const newState = this.showNotes(state);

        let highlightedComments: string[] = [];
        if (Array.isArray(commentId)) {
            highlightedComments = commentId;
        } else if (commentId) {
            highlightedComments = [commentId];
        }

        return {
            ...newState,
            dotId,
            clientPosition: position,
            highlightedComments,
            highlightedDots: state.highlightedDots.filter(id => id !== dotId)
        };
    }

    saveNote(state: State, flid: string, did: string, note: string): State {
        const { savedNotes } = state;
        const dotId = isDraftDot(did) ? "newDot" : did;

        return {
            ...state,
            savedNotes: {
                ...savedNotes,
                [flid]: {
                    ...savedNotes[flid],
                    [dotId]: note
                }
            }
        };
    }

    deselectDot(state: State): State {
        return {
            ...state,
            dotId: null
        };
    }

    setDots(state: State, {
        dotId,
        statusFilter,
        colorFilter
    }: Payloads.SetDots): State {
        return {
            ...state,
            loadingDots: false,
            dotId,
            statusFilter,
            colorFilter
        };
    }

    unhighlightComment(state: State, { cmid }: Payloads.UnhighlightComment): State {
        return {
            ...state,
            highlightedComments: state.highlightedComments.filter(id => id !== cmid)
        };
    }

    updateDot(state: State, {
        did: oldDid,
        dotData: { _id: newDid }
    }: Payloads.UpdateDot): State {
        if (state.dotId !== oldDid && oldDid === newDid) {
            return state;
        }

        return {
            ...state,
            dotId: newDid!
        };
    }

    updateDotStatus(state: State, {
        did: dotId,
        status,
        statusFilter: newStatusFilter
    }: Payloads.UpdateDotStatus): State {
        const statusFilter = newStatusFilter || state.statusFilter;

        if (state.dotId === dotId && statusFilter as string !== status) {
            // If status of currently opened dot is being changed
            // and status filter (current or next) doesn't match with current status filter
            // unselect the dot
            return {
                ...state,
                statusFilter,
                dotId: null
            };
        }

        if (newStatusFilter) {
            return {
                ...state,
                statusFilter
            };
        }

        return state;
    }

    updateLastNoteColorUsed(state: State, {
        colorName: lastNoteColorUsed
    }: Payloads.UpdateDotColor): State {
        return {
            ...state,
            lastNoteColorUsed
        };
    }

    changeDotFilter(state: State, {
        status: statusFilter,
        color: colorFilter,
        dotId
    }: Payloads.ChangeDotFilter): State {
        return {
            ...state,
            colorFilter,
            statusFilter,
            dotId
        };
    }

    removeDot(state: State, {
        flid,
        did,
        note
    }: Payloads.RemoveDot): State {
        let { savedNotes } = state;
        if (isDraftDot(did)) {
            savedNotes = {
                ...savedNotes,
                [flid]: {
                    ...savedNotes[flid],
                    newDot: note!
                }
            };
        }

        if (state.dotId !== did) {
            if (!isDraftDot(did)) {
                return state;
            }

            return {
                ...state,
                savedNotes
            };
        }

        return {
            ...state,
            savedNotes,
            dotId: null
        };
    }
    // #endregion

    // eslint-disable-next-line complexity
    reduce(state: State, action: AllPayloads) {
        switch (action.type) {
            case AppActionTypes.RESET:
            case BarrelActionTypes.RESET:
                return this.reset(state);

            case FlowActionTypes.GET_PROJECT_FLOWS_SUCCESS:
                return this.getProjectFlowsSuccess(state, action);
            case FlowActionTypes.GET_PROJECT_FLOWS_FINISH:
                return this.getProjectFlowsFinish(state);

            case FlowActionTypes.LOAD_PREFS:
                return this.loadPrefs(state, action);
            case FlowActionTypes.LOAD_FLOW:
                return this.loadFlow(state, action);
            case FlowActionTypes.LOAD_FLOWS:
                return this.loadFlows(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODES_POSITION:
                return this.updateFlowNodesPosition(state, action);
            case FlowActionTypes.DELETE_FLOW_NODES:
                return this.deleteFlowNodes(state, action);
            case FlowActionTypes.CREATE_CONNECTOR:
                return this.createConnector(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR:
                return this.updateConnector(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LINE_TYPE:
                return this.updateConnectorLineType(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_END_STYLE:
                return this.updateConnectorEndStyle(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_COLOR:
                return this.updateConnectorColor(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_POINTS:
                return this.updateConnectorPoints(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LINE_TRAJECTORY:
                return this.updateConnectorLineTrajectory(state, action);
            case FlowActionTypes.ADD_CONNECTOR_LABEL:
                return this.addConnectorLabel(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LABEL:
                return this.updateConnectorLabel(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LABEL_TEXT:
                return this.updateConnectorLabelText(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LABEL_WIDTH:
                return this.updateConnectorLabelWidth(state, action);
            case FlowActionTypes.UPDATE_CONNECTOR_LABEL_POSITION:
                return this.updateConnectorLabelPosition(state, action);
            case FlowActionTypes.DELETE_CONNECTOR_LABEL:
                return this.deleteConnectorLabel(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODE:
                return this.updateFlowNode(state, action);
            case FlowActionTypes.UPDATE_VARIANT_GROUP_NODE:
                return this.updateVariantGroupNode(state, action);
            case FlowActionTypes.CREATE_CONNECTORS:
                return this.updateConnectorIds(state, action);
            case FlowActionTypes.UPDATE_NODE_TEXT:
                return this.updateNodeText(state, action);
            case FlowActionTypes.UPDATE_NODE_WIDTH:
                return this.updateNodeWidth(state, action);
            case FlowActionTypes.UPDATE_NODE_HEIGHT:
                return this.updateNodeHeight(state, action);
            case FlowActionTypes.ADD_TEXT_LABEL_NODE:
            case FlowActionTypes.ADD_SHAPE_NODE:
            case FlowActionTypes.ADD_PLACEHOLDER_NODE:
                return this.addNodeToFlow(state, action);
            case FlowActionTypes.UPDATE_SHAPE_COLOR:
                return this.updateShapeColor(state, action);
            case FlowActionTypes.UPDATE_SHAPE_TYPE:
                return this.updateShapeType(state, action);
            case FlowActionTypes.UPDATE_PLACEHOLDER_TYPE:
                return this.updatePlaceholderType(state, action);
            case FlowActionTypes.UPDATE_PLACEHOLDER_TEMPLATE:
                return this.updatePlaceholderTemplate(state, action);
            case FlowActionTypes.SWAP_PLACEHOLDER_WITH_SCREEN:
                return this.swapPlaceholderWithScreen(state, action);
            case FlowActionTypes.ADD_NODES_TO_FLOW_SUCCESS:
                return this.addNodesToFlow(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODE_NAME:
                return this.updateFlowNodeName(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODE_SCREEN_VERSION:
                return this.updateFlowNodeScreenVersion(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODE_TYPE:
                return this.updateFlowNodeType(state, action);
            case FlowActionTypes.UPDATE_FLOW_NODE_VARIANTS:
                return this.updateFlowNodeVariants(state, action);
            case FlowActionTypes.SET_CURRENT_FLOW:
                return this.setCurrentFlowId(state, action);
            case FlowActionTypes.SET_DIMENSIONS:
                return this.setDimensions(state, action);
            case FlowActionTypes.CHANGE_POSITION:
                return this.changePosition(state, action);
            case FlowActionTypes.SET_ZOOM_LEVEL:
                return this.setZoomLevel(state, action);
            case FlowActionTypes.ENABLE_ADD_SHAPE_MODE:
                return this.enableAddShapeMode(state, action);
            case FlowActionTypes.DISABLE_ADD_SHAPE_MODE:
                return this.disableAddShapeMode(state);
            case FlowActionTypes.DISABLE_CREATING_FROM_EMPTY_VIEW:
                return this.disableCreatingFromEmptyView(state);
            case FlowActionTypes.UPDATE_FLOW:
                return this.updateFlow(state, action);
            case FlowActionTypes.DELETE_CONNECTORS:
                return this.deleteConnectors(state, action);
            case FlowActionTypes.NORMALIZE_PROTOTYPE_CONNECTORS:
                return this.normalizePrototypeConnectors(state, action);
            case FlowActionTypes.UPDATE_GROUP_NAME:
                return this.updateGroupName(state, action);
            case FlowActionTypes.UPDATE_GROUP_COLOR:
                return this.updateGroupColor(state, action);
            case FlowActionTypes.REMOVE_NODES_FROM_GROUP:
                return this.removeNodesFromGroup(state, action);
            case FlowActionTypes.ADD_NODES_TO_GROUP:
                return this.addNodesToGroup(state, action);
            case FlowActionTypes.UPDATE_GROUP_ORDER:
                return this.updateGroupOrder(state, action);
            case FlowActionTypes.DELETE_GROUPS:
                return this.deleteGroups(state, action);
            case FlowActionTypes.SET_SELECTED_NODES:
                return this.setSelectedNodes(state, action);
            case FlowActionTypes.CREATE_GROUPS_START:
                return this.createGroupsStart(state, action);
            case FlowActionTypes.CREATE_GROUPS_REQUEST:
                return this.createGroupsRequest(state, action);
            case FlowActionTypes.CREATE_GROUPS_SUCCESS:
                return this.createGroupsSuccess(state, action);
            case FlowActionTypes.CREATE_BOARD_START:
                return this.createBoardStart(state, action);
            case FlowActionTypes.CREATE_BOARD_SUCCESS:
                return this.createBoardSuccess(state, action);
            case FlowActionTypes.DELETE_BOARD:
                return this.deleteBoard(state, action);
            case FlowActionTypes.UPDATE_BOARD_ORDER:
                return this.updateBoardOrder(state, action);
            case FlowActionTypes.UPDATE_BOARD_NAME:
            case FlowActionTypes.CREATE_BOARD_REQUEST:
                return this.updateBoardName(state, action);
            case FlowActionTypes.TRANSFER_GROUPS_TO_ANOTHER_FLOW:
            case FlowActionTypes.TRANSFER_NODES_TO_ANOTHER_FLOW:
                return this.transferToAnotherFlow(state, action);
            case FlowActionTypes.UPDATE_TRANSFERRED_DATA:
                return this.updateTransferredGroupData(state, action);

            case ScreenActionTypes.UPDATE_NAME:
                return this.updateScreenName(state, action);
            case ScreenActionTypes.RENAME_SCREEN_VARIANT:
                return this.updateScreenVariantName(state, action);
            case ScreenActionTypes.REMOVE_SCREEN:
                return this.removeScreen(state, action);
            case ScreenActionTypes.REMOVE_SCREEN_VARIANT:
                return this.removeScreenVariant(state, action);

            case DashboardActionTypes.UPDATE_VARIANT_GROUP:
                return this.createVariantGroupNode(state, action);
            case DashboardActionTypes.DETACH_VARIANT_GROUP:
                return this.detachVariantGroupNode(state, action);
            case DashboardActionTypes.DELETE_VARIANT_GROUP:
                return this.deleteVariantGroupNode(state, action);
            case DashboardActionTypes.DELETE_SCREENS:
            case DashboardActionTypes.REMOVE_SECTION:
                return this.deleteScreens(state, action);

            // Notes
            case FlowActionTypes.ENABLE_NOTE_MODE:
                return this.enableNoteMode(state);
            case FlowActionTypes.DISABLE_NOTE_MODE:
                return this.disableNoteMode(state);
            case FlowActionTypes.CREATE_NOTE:
                return this.createNote(state, action);
            case FlowActionTypes.SHOW_NOTES:
                return this.showNotes(state);
            case FlowActionTypes.HIDE_NOTES:
                return this.hideNotes(state);
            case FlowActionTypes.SELECT_DOT:
            case FlowActionTypes.OPEN_DOT:
                return this.selectDot(state, action);
            case FlowActionTypes.UPDATE_DOT:
                return this.updateDot(state, action);
            case FlowActionTypes.CHANGE_DOT_FILTER:
                return this.changeDotFilter(state, action);
            case FlowActionTypes.UPDATE_DOT_STATUS:
                return this.updateDotStatus(state, action);
            case FlowActionTypes.UPDATE_DOT_COLOR:
                return this.updateLastNoteColorUsed(state, action);
            case FlowActionTypes.SAVE_NOTE:
                return this.saveNote(state, action.flid!, action.did, action.note);
            case FlowActionTypes.ADD_DOT:
                return this.saveNote(state, action.flid, action.dot._id, "");
            case FlowActionTypes.CREATE_COMMENT:
                return this.saveNote(state, action.flid, action.did, "");
            case FlowActionTypes.CLOSE_DOT_POPUP:
                return this.deselectDot(state);
            case FlowActionTypes.SET_DOTS:
                return this.setDots(state, action);
            case FlowActionTypes.UNHIGHLIGHT_COMMENT:
                return this.unhighlightComment(state, action);
            case FlowActionTypes.REMOVE_DOT:
                return this.removeDot(state, action);

            default:
                return state;
        }
    }
}

export default FlowStore;
export { State as FlowStoreState };
