import { ComponentChildren, h, Fragment } from "preact";

import classname from "../../../../foundation/utils/classname";

import "./style.css";

interface Props {
    illustration: JSX.Element;
    displayIllustrationOnMobile?: boolean;
    header: ComponentChildren;
    subHeader?: ComponentChildren;
    actionButtons?: ComponentChildren;
    form: ComponentChildren;
    footer?: ComponentChildren;
}

function LoginSignupTemplate({
    illustration,
    displayIllustrationOnMobile = false,
    header,
    subHeader,
    actionButtons,
    form,
    footer
}: Props) {
    return (
        <div class="loginSignupTemplate">
            <div class={classname("illustration", { displayIllustrationOnMobile })}>
                {illustration}
            </div>
            <div class="content">
                <h1>{header}</h1>
                {subHeader && <p>{subHeader}</p>}
                {actionButtons && (
                    <>
                        <div class="actionButtons">{actionButtons}</div>
                        <div class="separatorWithORContainer">
                            <div class="separatorWithOR"/>
                            <span class="ORLabel">OR</span>
                            <div class="separatorWithOR"/>
                        </div>
                    </>
                )}
                <div class="formWrapper">{form}</div>
                {footer && (
                    <>
                        <hr/>
                        <div className="footer">{footer}</div>
                    </>
                )}
            </div>
        </div>
    );
}

export default LoginSignupTemplate;
